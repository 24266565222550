import CampaignPublicationsFilter from "./CampaignPublicationsFilter";
import React, { useEffect, useState } from "react";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import configs from "../../../../config/main.json";
import { PlatformIdMapping } from "../../../../utility/plaformIcons";
import { Avatar, Box, Grid, Tooltip, Checkbox } from "@mui/material";
import CampaignPublicationsActionButton from "./CampaignPublicationsActionButton";
import { tableHeaders } from "./CommonAssets";
import { getIdentPublicationsForCampaign, postAction } from "../../../../services/publication";
import { useLocation } from "react-router-dom";
import { InfoToolTip, InfoToolTipPreview } from "../../../common/toolTip";
import { getFormattedDate } from "../../../../utility/momentManipulations";
import moment from "moment";
import { getEngagementBreakup } from "../../../../utility/postOpertions";
import { convertToInternationalNumberSystem } from "../../../../utility/stringOperations";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "../../../../redux/features/system/systemAlert";
import CampaignPublicationTab from "./CampaignPublicationsTabs";
import { getCampaignOverview } from "../../../../services/campaignAnalytics";
import { getTimeZone } from "../../../../services/common";
import add_task from "../../../../assets/images/add_task.png";
import remove_task from "../../../../assets/images/remove_task.png";
import DummyCampaign from "../../../common/DummyCampaign";
import { stringAvatar } from "../campaignDrafts/CommonAssets";

const CampaignIdentPublicationsSnapshot = () => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const data = location.state;
  const [allPlatforms, setAllPlatforms] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [allAllowedPlatforms, setAllAllowedPlatforms] = React.useState([]);
  const [dataFound, setDataFound] = React.useState(false);
  const [selectedPlatforms, setSelectedPlatforms] = React.useState([]); //all the platforms
  const [bodyColumns, setBodyColumns] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState(10);
  const [sortCol, setSortCol] = React.useState("createdAt"); //sort by lastRefreshedOn by default
  const [sortOrder, setSortOrder] = React.useState("desc"); //sort in descending order
  const [totalRows, setTotalRows] = React.useState(10);
  const [selectedGroup, setSelectedGroup] = React.useState([]);
  const [filter, setFilter] = React.useState("");
  const [startDate, setStartDate] = React.useState(getFormattedDate(moment().subtract(6, "months").toDate(), "YYYY-MM-DD")); //select campaign duration for 7 days
  const [endDate, setEndDate] = React.useState(getFormattedDate(moment().toDate(), "YYYY-MM-DD"));
  const [headers, setHeaders] = React.useState(tableHeaders);
  const dispatch = useDispatch();
  const [campaignStartDate, setCampaignStartDate] = React.useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const cid = params.get("id");
  const timeZone = getTimeZone();
  const universal = params.get("universal");
  useEffect(() => {
    loadBasicData();
  }, [params.get("id")]);

  useEffect(() => {
    // Once selectedPlatforms has been set, perform further actions
    if (selectedPlatforms?.length > 0) {
      getIdentPublicationData(
        +params.get("id"),
        selectedPlatforms,
        selectedGroup?.length > 0 ? selectedGroup : [-1],
        page,
        rows,
        sortCol,
        sortOrder,
        0,
        filter,
        startDate,
        endDate,
        moment(startDate)
      );
      // Perform other actions here
    }
  }, [selectedPlatforms]);

  const sendSelectedFilters = (event) => {
    setStartDate(event.startDate);
    setEndDate(event.endDate);
    setSelectedGroup(event.selectedGroups);
    setFilter(event?.filterValue);
    setSelectedPlatforms(event.selectedPlatforms ? event.selectedPlatforms : selectedPlatforms);
    getIdentPublicationData(
      +params.get("id"),
      event.selectedPlatforms ? event.selectedPlatforms : selectedPlatforms,
      event.selectedGroups?.length > 0 ? event.selectedGroups : [-1],
      0,
      rows,
      sortCol,
      sortOrder,
      0,
      event?.filterValue,
      event.startDate,
      event.endDate,
      campaignStartDate,
      timeZone
    );
  };

  const loadBasicData = async () => {
    setLoading(true);
    setDataFound(false);
    const campaignDetails = await getCampaignOverview(cid, user, dispatch);
    if (campaignDetails.response) {
      if (campaignDetails.response.overallSummary === null) {
        setLoading(false);
      }
      const { platforms, addedPlatforms, campaignStart } = campaignDetails.response.overallSummary[0];
      const selectedPlatformId = PlatformIdMapping[addedPlatforms[0]];
      setDataFound(true);
      setLoading(false);
      setCampaignStartDate(moment(campaignStart));
      setAllAllowedPlatforms(platforms);
      setAllPlatforms(addedPlatforms);
      setSelectedPlatforms([selectedPlatformId]);

      const groups = [];
      if (data !== null) {
        groups.push(data?.data?.id);
        const groupObject = {
          id: data?.data?.id,
          name: data?.data?.groupName,
          platform: data?.data?.platform,
        };
        sendSelectedFilters({
          selectedGroups: [groupObject],
          selectedPlatforms: [PlatformIdMapping[data?.data?.platform]],
          startDate,
          endDate,
          filterValue: filter,
        });
      }
    }
  };
  const getIdentPublicationData = async (
    id,
    selectedPlatforms,
    selectedGroup,
    page,
    rows,
    col,
    order,
    exportValue,
    filter,
    startDate,
    endDate,
    fileName,
    campaignDate,
    timeZone
  ) => {
    let groupIds = [-1];
    if (selectedGroup[0] !== -1) {
      groupIds = selectedGroup?.map((group) => group.id);
    }
    setLoading(true);
    const publications = await getIdentPublicationsForCampaign(
      id,
      selectedPlatforms,
      groupIds,
      page + 1,
      rows,
      "a." + col,
      order,
      exportValue,
      filter,
      startDate,
      endDate,
      "",
      fileName,
      timeZone,
      user,
      dispatch
    );
    if (publications.response.records && publications.response.records?.length > 0) {
      setTotalRows(publications.response.total_records);
      createData(publications.response.records, campaignDate);
      setDataFound(true);
      setLoading(false);
    } else {
      setLoading(false);
      setDataFound(false);
    }
  };

  const setDataRefreshed = () => {
    getIdentPublicationData(
      +params.get("id"),
      selectedPlatforms,
      selectedGroup?.length > 0 ? selectedGroup : [-1],
      0,
      rows,
      sortCol,
      sortOrder,
      0,
      filter,
      startDate,
      endDate,
      campaignStartDate,
      timeZone
    );
  };
  //prepare the data for the table
  const createData = (records, campaignDate) => {
    const columns = [];
    records.forEach((data) => {
      const {
        id,
        postedOn,
        postUrl,
        postId,
        photo_url,
        profileUrl,
        infName,
        userName,
        engagement,
        reach,
        engagementRate,
        groupName,
        lastRefreshedOn,
        platform,
      } = data;
      let object = {
        id: id,
        post: (
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <Checkbox
                sx={{
                  color: "#605bff",
                  "&.Mui-checked": {
                    color: "#605bff",
                  },
                }}
                checked={selectedRows[id]}
                onChange={() => handleCheckbox(id)}
                style={{ marginRight: "10px" }}
              />
            </Grid>
            <Grid item xs={8} sx={{ marginTop: 1 }}>
              <Grid container>
                <Tooltip title={postUrl}>
                  <Grid item xs={12} className={styles.bodycell} style={{ marginTop: 5 }}>
                    <InfoToolTipPreview postUrl={postUrl} platform={platform} postId={postId} />
                  </Grid>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        ),
        influencer: (
          <Grid container spacing={2}>
            <Grid item md={3} sm={6} tyle={{ margin: "auto" }}>
              <Avatar
                variant="circle"
                src={photo_url || ""}
                {...stringAvatar(infName)}
                onError={(e) => {
                  e.target.src = configs.PROFILE_IMG;
                }}
              />
            </Grid>
            <Grid item md={9} sm={6}>
              <Grid container>
                <Grid item xs={12}>
                  <p className={styles.bodycell} style={{ marginBottom: 5 }}>
                    {profileUrl !== "" ? (
                      <a href={profileUrl} target="_blank">
                        {infName}
                      </a>
                    ) : (
                      <p>{infName}</p>
                    )}
                  </p>
                  <p className={styles.subbodycell}>@{userName}</p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ),
        createdAt: (
          <Grid container spacing={1}>
            <Grid item xs={12} className={styles.bodycell}>
              {postedOn.includes(" - ") ? postedOn.split(" - ")[1] : postedOn.split("at")[0]}
            </Grid>
          </Grid>
        ),

        totalEngagement: (
          <Grid container spacing={1}>
            <Grid item xs={1} className={styles.bodycell} />
            <Grid item xs={10} className={styles.bodycell}>
              {convertToInternationalNumberSystem(engagement)}
              &nbsp;&nbsp;
              {engagement > 0 && <InfoToolTip text={getEngagementBreakup(data)} />}
            </Grid>
          </Grid>
        ),
        reach: (
          <Grid container spacing={1}>
            <Grid item xs={2} className={styles.bodycell}></Grid>
            <Grid item xs={3} className={styles.bodycell}>
              {convertToInternationalNumberSystem(reach)}
            </Grid>
          </Grid>
        ),
        "er%": <p className={styles.bodycell}>{engagementRate}%</p>,
        groupName: groupName,
        status: (
          <Grid container>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Tooltip title={"Approve the post"}>
                <img
                  src={add_task}
                  alt="img"
                  style={{ height: "24px", marginRight: "10px", cursor: "pointer" }}
                  onClick={() => {
                    if (window.confirm("Are you sure you want to approve this post?")) {
                      handlePostAction([id.toString()], "approve");
                    }
                  }}
                />
              </Tooltip>
              <Tooltip title={"Approve the post"}>
                <img
                  src={remove_task}
                  alt="img"
                  style={{ height: "24px", cursor: "pointer" }}
                  onClick={() => {
                    if (window.confirm("Are you sure you want to reject this post?")) {
                      handlePostAction([id.toString()], "reject");
                    }
                  }}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} className={styles.subbodycell} style={{ textAlign: "center" }}>
              {lastRefreshedOn.includes("-") ? lastRefreshedOn.split("-")[1] : lastRefreshedOn.split(" at ")[1]}
            </Grid>
          </Grid>
        ),
        action: (
          <Grid container spacing={1}>
            <Grid item>
              <CampaignPublicationsActionButton influencerAccount={data} setDataRefreshed={setDataRefreshed} />
            </Grid>
          </Grid>
        ),
      };
      columns.push(object);
    });

    setBodyColumns(columns);
  };

  const changeHeaders = (event) => {
    setHeaders(event);
  };

  //function that caters to page changes
  const sendInformation = (event) => {
    const pageNo = event.page ? event.page : page;
    const rowsPerPage = event.rowsPerPage ? event.rowsPerPage : rows;
    const order = event.order ? event.order : sortOrder;
    const orderBy = event.orderBy ? event.orderBy : sortCol;
    const exportValue = event.export ? event.export : 0;
    setPage(pageNo);
    setRows(rowsPerPage);
    setSortOrder(orderBy);
    setSortCol(sortCol);
    getIdentPublicationData(
      +params.get("id"),
      selectedPlatforms,
      selectedGroup?.length > 0 ? selectedGroup : [-1],
      pageNo,
      rowsPerPage,
      orderBy,
      order,
      exportValue,
      filter,
      startDate,
      endDate,
      campaignStartDate,
      timeZone
    );
  };

  const handleCheckbox = (index) => {
    let id = index.toString();
    let newSelectedRows = selectedRows;
    const indexInArray = newSelectedRows.indexOf(id);
    if (indexInArray === -1) {
      newSelectedRows.push(id);
    } else {
      newSelectedRows.splice(indexInArray, 1);
    }
    const count = newSelectedRows?.length;
    setSelectedRows(newSelectedRows);
    setSelectedCount(count);
  };

  const handlePostAction = async (ids, action) => {
    dispatch(
      notify({
        message: "We are processing your request. Please check again in sometime",
        type: "info",
      })
    );
    const data = {
      cid: cid,
      action: action,
      ids: ids,
    };
    const response = await postAction(data, user, dispatch);
    if (response.status === true) {
      setDataRefreshed(true);
      dispatch(
        notify({
          message: "Post " + action + " successfully",
          type: "success",
        })
      );
    }
  };

  const handleTabChange = (event) => {
    setSelectedPlatforms([event]);
    getIdentPublicationData(
      +params.get("id"),
      [event],
      selectedGroup?.length > 0 ? selectedGroup : [-1],
      0,
      rows,
      sortCol,
      sortOrder,
      0,
      filter,
      startDate,
      endDate,
      campaignStartDate,
      timeZone
    );
  };
  return (
    <Box>
      <DummyCampaign universal={universal} />
      <CampaignPublicationsFilter
        sendInformation={sendInformation}
        changeHeaders={changeHeaders}
        selectedPlatform={selectedPlatforms}
        tableHeaders={tableHeaders}
        allSelectedGroups={selectedGroup}
        filter={filter}
        selectedStartDate={startDate}
        selectedEndDate={endDate}
        sendSelectedFilters={sendSelectedFilters}
        allAllowedPlatforms={allAllowedPlatforms}
        selectedCount={selectedCount}
        setSelectedCount={setSelectedCount}
        handlePostAction={handlePostAction}
        selectedRows={selectedRows}
      />{" "}
      <div className={styles.background}>
        <CampaignPublicationTab
          allSelectedPlatforms={allPlatforms}
          selectedPlatform={selectedPlatforms}
          headers={headers}
          bodyColumns={bodyColumns}
          totalRows={totalRows}
          page={page}
          rows={rows}
          sortOrder={sortOrder}
          sortCol={sortCol}
          sendInformation={sendInformation}
          handleTabChange={handleTabChange}
          loading={loading}
          dataFound={dataFound}
        />
      </div>
    </Box>
  );
};

export default CampaignIdentPublicationsSnapshot;
