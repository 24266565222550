import { Box, Button, Grid, Typography, Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../styles/internal/Landing/Landing.module.css";
import {
  LandingTags,
  commonStyleLeftImageHeader,
  commonStyleLeftTextPara,
  commonStyleRightImageHeader,
  commonStyleRightTextPara,
  readMore,
} from "./LandingConstants";
import logo from "../../assets/images/Logo_1.png";
import discover from "../../assets/images/Discover.svg";
import graph from "../../assets/images/Graph.svg";
import campaign from "../../assets/images/Campaign.svg";
import ugc from "../../assets/images/UGC.svg";
import attribution from "../../assets/images/Attribution.svg";
import Testimonials from "./Testimonials";
import TopBrands from "./TopBrands";
import Highlights from "./Highlights";
import { RocketLaunch } from "@mui/icons-material";
import "../../styles/internal/Landing/Landing.css";

const socialData = ["facebook", "youtube", "twitter", "instagram", "tiktok", "linkedin"];

const BaseLanding = ({ width }) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    const allSections = document.querySelectorAll(".section");

    const revealSection = function (entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.remove("section--hidden");
          observer.unobserve(entry.target);
        }
      });
    };

    const sectionObserver = new IntersectionObserver(revealSection, {
      root: null, // Use the viewport as the root
      threshold: 0.2, // Adjust as needed
    });

    allSections.forEach(function (section) {
      sectionObserver.observe(section);
      section.classList.add("section--hidden");
    });
  }, []); // Empty dependency array means this effect runs once after the initial render

  const handleStarted = () =>{
   navigate("/pricing")
  }
  return (
    <Box sx={{ p: { xs: "0", md: "100px" }, "& img": { width: "100%" } }}>
      <Box
        sx={{
          background: { xs: "linear-gradient(235.16deg, #19A3C6 -14.93%, rgba(78, 162, 183, 0) 134.24%)", md: "inherit" },
          boxShadow: { xs: "0px 4px 4px rgba(0, 0, 0, 0.25)", md: "inherit" },
          p: { xs: "10px", md: 0 },
        }}
      >
        <Typography
          variant="h2"
          className={styles.header}
        >
          Drive Word-of-Mouth at Scale
        </Typography>
        <Typography
          variant="h5"
          className={styles.second}
        >
          All-In-One Influencer Marketing Platform For DATA-DRIVEN Marketers.
          <br />
          Manage the full lifecycle of your influencer marketing campaigns
        </Typography>
        <Grid container spacing={2} className={styles.sideSpace} sx={{ justifyContent: "center" }}>
          {LandingTags?.map((tag) => (
            <Grid item key={tag.name}>
              <Tooltip title={tag.tooltip} arrow>
                <Button
                  variant="outlined"
                  className={`chip`}
                  sx={{
                    backgroundColor: "#84848469",
                    color: "#FFFFFF",
                    padding: "5px 10px",
                    borderRadius: "12px",
                    height: "30px",
                    border: "none",
                    "&:hover": {
                      backgroundColor: "#726e6e",
                      color: "#FFFFFF",
                    },
                  }}
                >
                  {tag.name}
                </Button>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
        <Grid container className={styles.getStartedButton} justifyContent="center" sx={{ paddingTop: "20px" }}>
          <Button
            variant="contained"
            sx={{
              borderRadius: "56px",
              height: "60px",
              width: "185px",
              fontSize: "15px",
              color: "#48B3CD",
              backgroundColor: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#19A3C6",
                color: "#FFFFFF",
              },
            }}
            onClick={handleStarted}
          >
            <b>GET STARTED</b>
          </Button>
        </Grid>
        <br />
        <div style={{ textAlign: "center" }}>
          <Box
            component={"img"}
            src={logo}
            alt="logo"
            width="50%"
            height="50%"
            sx={{ width: { xs: "100% !important", md: "50% !important" } }}
          />
        </div>
        <Box bg="red">
          <section className="section section--hidden">
            <div style={{ textAlign: "center", marginTop: "70px" }}>
              {width > 1300 && <Highlights />}
            </div>
            <br />
            <Typography
              style={{
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "100%",
                textAlign: "center",
                letterSpacing: "-0.01em",
                color: "#211F25",
                marginTop: "70px",
              }}
              sx={{ fontSize: { xs: "22px", md: "44px" } }}
            >
              Maximize ROI from Influencer Marketing
            </Typography>
            <br />
            <Typography
              style={{
                fontStyle: "normal",
                fontWeight: 300,
                // fontSize: "20px",
                lineHeight: "80%",
                textAlign: "center",
                letterSpacing: "-0.01em",
                color: "#211F25",
              }}
              sx={{ fontSize: { xs: "16px", sm: "18px", md: "20px" } }}
            >
              Gathering Data for Insights that boost <RocketLaunch /> growth for ecommerce, drive awareness.
            </Typography>
          </section>
        </Box>
      </Box>

      <br />
      <section className="section section--hidden">
        <div style={{ background: "#f9f6fa", padding: "30px", marginTop: "0px" }}>
          <Grid container spacing={2}>
            <Grid
              item
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={10}
                  style={{
                    ...commonStyleLeftImageHeader,
                  }}
                  sx={{ fontSize: { xs: "30px !important", md: "40px !important" }, pl: { xs: 0, md: "10vw" } }}
                >
                  Discover & Analyze Influencers
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{
                    ...commonStyleLeftTextPara,
                    marginTop: "16px",
                  }}
                  sx={{ fontSize: { xs: "12px !important", md: "16px !important" }, pl: { xs: 0, md: "10vw" } }}
                >
                  Search for the most relevant and authentic influencers for your campaign strategy. Use AI powered matching that
                  uses brand affinity and filters out suspicious accounts. Engage brand fans who mention you online through Social
                  Listening tools
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{
                    paddingTop: "30px",
                  }}
                  sx={{ pl: { xs: 0, md: "10vw" } }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      ...readMore,
                    }}
                    component={Link}
                    to="/discoverAndAnalyze"
                  >
                    Read more
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} style={{ textAlign: "center" }}>
              <img src={discover} alt="logo" height="90%" />
            </Grid>
          </Grid>
        </div>
      </section>
      <section className="section section--hidden">
        <div style={{ background: "#FFFFF", padding: "30px" }}>
          <Grid container spacing={2}>
            <Grid item md={6} style={{ textAlign: "center" }}>
              <img src={graph} alt="logo" height="90%" />
            </Grid>
            <Grid
              item
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={10}
                  style={{
                    ...commonStyleRightImageHeader,
                  }}
                  sx={{
                    fontSize: { xs: "30px !important", md: "40px !important" },
                    pl: { xs: "0px !important", md: "18px !important" },
                  }}
                >
                  Contract & Activate
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{ ...commonStyleRightTextPara, marginTop: "16px" }}
                  sx={{
                    fontSize: { xs: "12px !important", md: "16px !important" },
                    pl: { xs: "0px !important", md: "18px !important" },
                  }}
                >
                  Invite, contract and on-board. Manage entire legal contracting & briefing to publications and everything in
                  between. From matching to campaign deliverables, we’ve got it covered.
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{
                    paddingTop: "30px",
                  }}
                  sx={{ pl: { xs: 0, md: "20px" } }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      ...readMore,
                    }}
                    component={Link}
                    to="/contractsAndActivate"
                  >
                    Read more
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </section>
      <section className="section section--hidden">
        <div style={{ background: "#f9f6fa", padding: "30px" }}>
          <Grid container spacing={2}>
            <Grid
              item
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={10}
                  style={{
                    ...commonStyleLeftImageHeader,
                  }}
                  sx={{ fontSize: { xs: "30px !important", md: "40px !important" }, pl: { xs: 0, md: "10vw" } }}
                >
                  Track & Manage Campaigns
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{
                    ...commonStyleLeftTextPara,
                    marginTop: "16px",
                  }}
                  sx={{
                    fontSize: { xs: "12px !important", md: "16px !important" },
                    pl: { xs: "0px !important", md: "10vw !important" },
                  }}
                >
                  Manage and monitor your influencer marketing campaigns. Understand how your campaign performs, monitor your
                  campaign effectiveness and make necessary adjustments.
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{
                    paddingTop: "30px",
                  }}
                  sx={{ pl: { xs: 0, md: "10vw" } }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      ...readMore,
                    }}
                    component={Link}
                    to="/trackAndManageCampaigns"
                  >
                    Read more
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} style={{ textAlign: "center" }}>
              <img src={campaign} alt="logo" height="90%" />
            </Grid>
          </Grid>
        </div>
      </section>
      <section className="section section--hidden">
        <div style={{ background: "#FFFFF", padding: "30px" }}>
          <Grid container spacing={2}>
            <Grid item md={6} style={{ textAlign: "center" }}>
              <img src={ugc} alt="logo" height="90%" />
            </Grid>
            <Grid
              item
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={10}
                  style={{
                    ...commonStyleRightImageHeader,
                  }}
                  sx={{
                    fontSize: { xs: "30px !important", md: "40px !important" },
                    pl: { xs: "0px !important", md: "18px !important" },
                  }}
                >
                  Monitor & Promote UGC
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{
                    ...commonStyleRightTextPara,
                    marginTop: "16px",
                  }}
                  sx={{
                    fontSize: { xs: "12px !important", md: "16px !important" },
                    pl: { xs: "0px !important", md: "18px !important" },
                  }}
                >
                  Brief, review, approve content before it goes live. Measure which content is most effective with content
                  analytics. IRM allows you to Track thousands of influencer relationships from one place. Log custom data about
                  each influencer to search and filter for later.
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{
                    paddingTop: "30px",
                  }}
                  sx={{ pl: { xs: 0, md: "20px" } }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      ...readMore,
                    }}
                    component={Link}
                    to="/monitorAndPromoteUGC"
                  >
                    Read more
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </section>
      <section className="section section--hidden">
        <div style={{ background: "#f9f6fa", padding: "30px" }}>
          <Grid container spacing={2}>
            <Grid
              item
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={10}
                  style={{
                    ...commonStyleLeftImageHeader,
                  }}
                  sx={{ fontSize: { xs: "30px !important", md: "40px !important" }, pl: { xs: 0, md: "10vw" } }}
                >
                  Influencer Attribution
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{
                    ...commonStyleLeftTextPara,
                    marginTop: "16px",
                  }}
                  sx={{
                    fontSize: { xs: "12px !important", md: "16px !important" },
                    pl: { xs: "0px !important", md: "10vw !important" },
                  }}
                >
                  Be it affiliate links, app promotion or sending traffic to e-commerce store or a website - tracking conversion
                  events. Use real-time analytics via our short URLs to measure conversions from different traffic sources.
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{
                    paddingTop: "30px",
                  }}
                  sx={{ pl: { xs: 0, md: "10vw" } }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      ...readMore,
                    }}
                    component={Link}
                    to="/attribution"
                  >
                    Read more
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} style={{ textAlign: "center" }}>
              <img src={attribution} alt="logo" height="90%" />
            </Grid>
          </Grid>
        </div>
      </section>
      <section className="section section--hidden">
        <div style={{ background: "#F5F5F5", padding: "30px" }}>
          <Box
            style={{
              background: "#F3EEF8",
              padding: "30px 50px 30px 50px",
            }}
            sx={{ m: { xs: 0, md: "45px 32px 40px 32px" } }}
          >
            <h3 style={{ fontWeight: "bold" }}>Choose the most relevant network</h3>
            <Box
              sx={{
                marginTop: "40px",
                display: "grid",
                gridTemplateColumns: { xs: "repeat(2,1fr)", sm: "repeat(3,1fr)", lg: "repeat(6,1fr)" },
                columnGap: "8px",
              }}
            >
              {socialData?.map((item, idx) => (
                <Box sx={{ m: "4px", textAlign: "center", p: "10px", bgcolor: "white", borderRadius: "10px" }}>
                  <img
                    src={`https://gazelle-email-templates.s3.amazonaws.com/images/${item}.png`}
                    alt={item}
                    style={{ width: "40px" }}
                  />
                  <Box sx={{ textTransform: "capitalize", pt: "4px", fontSize: { xs: "12px", md: "16px" } }}>{item}</Box>
                </Box>
              ))}
            </Box>
          </Box>
        </div>
      </section>
      {/* Testimonials */}
      <Testimonials />
      {/* Top Brands */}
      <TopBrands />
    </Box>
  );
};

export default BaseLanding;
