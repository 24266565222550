import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import daa_1 from "../../assets/images/DAA_1.svg";
import daa_2 from "../../assets/images/DAA_2.svg";
import daa_3 from "../../assets/images/DAA_3.svg";
import daa_4 from "../../assets/images/DAA_4.svg";
import daa_5 from "../../assets/images/DAA_5.svg";
import {
  commonStyleLeftImageHeader,
  commonStyleLeftTextPara,
  commonStyleRightImageHeader,
  commonStyleRightTextPara,
} from "./LandingConstants";
import Testimonials from "./Testimonials";
import TopBrands from "./TopBrands";
import styles from "../../styles/internal/Landing/BaseStyling.module.css";
import "../../styles/internal/Landing/Landing.css";

function BaseDiscoverAndAnalyze() {
  const navigate = useNavigate();

  React.useEffect(() => {
    const allSections = document.querySelectorAll(".section");

    const revealSection = function (entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.remove("section--hidden");
          observer.unobserve(entry.target);
        }
      });
    };

    const sectionObserver = new IntersectionObserver(revealSection, {
      root: null, // Use the viewport as the root
      threshold: 0.2, // Adjust as needed
    });

    allSections.forEach(function (section) {
      sectionObserver.observe(section);
      section.classList.add("section--hidden");
    });
  }, []); // Empty dependency array means this effect runs once after the initial render

  return (
    <div className={styles.wrapper}>
      {/* Header */}
      <div className={styles.headerBox}>
        <Box className={styles.topText}>Don't expect Right results with wrong creators</Box>
        <Box className={styles.title} sx={{ fontSize: { xs: "30px !important", md: "40px !important", lg: "60px !important" } }}>
          Discover, Analyze, On-board and Manage Creators in One Place
        </Box>
        <div className={styles.subTitle}>
          Welcome to the most complete influencer solution, built for the new age marketers.
          <br />
          Easily run your influencer marketing strategy in one place.
        </div>
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <Button
            variant="contained"
            sx={{
              borderRadius: "56px",
              height: "60px",
              width: "190px",
              marginInline: "auto",
              fontSize: "20px",
              color: "#FFFFFF",
              backgroundColor: "#464E5F",
              "&:hover": {
                backgroundColor: "#2a2f39",
                color: "#FFFFFF",
              },
            }}
            onClick={() => navigate("/pricing")}
          >
            GET STARTED
          </Button>
        </div>
      </div>
      {/* Left Text and Right Image */}
      <div className={`${styles.rightTextDiv} `}>
        <section className="section section--hidden">
          <Grid container spacing={2}>
            <Grid
              item
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={10}
                  style={{
                    ...commonStyleLeftImageHeader,
                    // paddingLeft: "10vw",
                  }}
                  sx={{ fontSize: { xs: "30px !important", md: "40px !important" }, pl: { xs: 0, md: "10vw" } }}
                >
                  Discover the Best Creators for Your Brand
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{
                    ...commonStyleLeftTextPara,
                    // paddingLeft: "10vw",
                    marginTop: "16px",
                  }}
                  sx={{ fontSize: { xs: "12px !important", md: "16px !important" }, pl: { xs: 0, md: "10vw" } }}
                >
                  Search across Nano, Micro or Mega influencers from the universe of Social Media users out there. Use industry's
                  best search tools to discover the perfect authentic influencer for your brand.
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} style={{ textAlign: "center" }}>
              <img src={daa_1} alt="logo" height="90%" />
            </Grid>
          </Grid>
        </section>
      </div>
      {/* Left Image and Right text */}
      <div className={`${styles.leftTextDiv} `}>
        <section className="section section--hidden">
          <Grid container spacing={2}>
            <Grid item md={6} style={{ textAlign: "center" }}>
              <img src={daa_2} alt="logo" height="90%" />
            </Grid>
            <Grid
              item
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={10}
                  style={{
                    ...commonStyleRightImageHeader,
                  }}
                  sx={{ fontSize: { xs: "30px !important", md: "40px !important" }, pl: { xs: "0 !important", md: "18px" } }}
                >
                  Don't Use Recycled Influencers
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{
                    ...commonStyleRightTextPara,
                    marginTop: "16px",
                  }}
                  sx={{ fontSize: { xs: "12px !important", md: "16px !important" }, pl: { xs: "0 !important", md: "20px" } }}
                >
                  Start every campaign with a clean slate. Nano influencers that are every-day users like you or your neighbour.
                  Empowr is a tech platform that indexes all public social media users and helps you find relevant influencers for
                  your campaigns.
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </section>
      </div>
      {/* Left Text and Right Image */}
      <div className={`${styles.rightTextDiv} `}>
        <section className="section section--hidden">
          <Grid container spacing={2}>
            <Grid
              item
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={10}
                  style={{
                    ...commonStyleLeftImageHeader,
                    // paddingLeft: "10vw",
                  }}
                  sx={{ fontSize: { xs: "30px !important", md: "40px !important" }, pl: { xs: 0, md: "10vw" } }}
                >
                  Analyze Influencers with 360° Enriched Data
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{
                    ...commonStyleLeftTextPara,
                    // paddingLeft: "10vw",
                    marginTop: "16px",
                  }}
                  sx={{ fontSize: { xs: "12px !important", md: "16px !important" }, pl: { xs: 0, md: "10vw" } }}
                >
                  Don't let incomplete influencer data fog your judgment. Make informed decisions with both first party and AI/ML
                  enriched data.
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} style={{ textAlign: "center" }}>
              <img src={daa_3} alt="logo" height="90%" />
            </Grid>
          </Grid>
        </section>
      </div>
      {/* Left Image and Right text */}
      <div className={`${styles.leftTextDiv} `}>
        <section className="section section--hidden">
          <Grid container spacing={2}>
            <Grid item md={6} style={{ textAlign: "center" }}>
              <img src={daa_4} alt="logo" height="90%" />
            </Grid>
            <Grid
              item
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={10}
                  style={{
                    ...commonStyleRightImageHeader,
                  }}
                  sx={{ fontSize: { xs: "30px !important", md: "40px !important" }, pl: { xs: "0 !important", md: "18px" } }}
                >
                  On-board Influencers Easily
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{
                    ...commonStyleRightTextPara,
                    marginTop: "16px",
                  }}
                  sx={{ fontSize: { xs: "12px !important", md: "16px !important" }, pl: { xs: "0 !important", md: "20px" } }}
                >
                  Streamline the process of creator outreach, on-boarding and legal contracting in one easy-to-use platform
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </section>
      </div>
      {/* Left Text and Right Image */}
      <div className={`${styles.rightTextDiv} `}>
        <section className="section section--hidden">
          <Grid container spacing={2}>
            <Grid
              item
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={10}
                  style={{
                    ...commonStyleLeftImageHeader,
                    // paddingLeft: "10vw",
                  }}
                  sx={{ fontSize: { xs: "30px !important", md: "40px !important" }, pl: { xs: 0, md: "10vw" } }}
                >
                  Track Creator Performance & Monitor Content
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{
                    ...commonStyleLeftTextPara,
                    // paddingLeft: "10vw",
                    marginTop: "16px",
                  }}
                  sx={{ fontSize: { xs: "12px !important", md: "16px !important" }, pl: { xs: 0, md: "10vw" } }}
                >
                  Brief, review, approve content before it goes live. Measure which content is most effective with content
                  analytics. IRM allows you to Track thousands of influencer relationships from one place. Log custom data about
                  each influencer to search and filter for later.
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} style={{ textAlign: "center" }}>
              <img src={daa_5} alt="logo" height="90%" />
            </Grid>
          </Grid>
        </section>
      </div>
      {/* Testimonials */}
      <Testimonials />
      {/* Top Brands */}
      <TopBrands />
    </div>
  );
}

export default BaseDiscoverAndAnalyze;
