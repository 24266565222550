import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Button,
} from "@mui/material";
import styles from "../../../../../styles/internal/Campaign/CreateNewCampaign.css";
import { useStyles } from "../../../newCampaign/staticStyling";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import {
  addContractTemplate,
  getBaseTemplateData,
  getContractBaseTemplates,
  getContractInput,
} from "../../../../../services/contracts";
import DraftEditor from "../../../../common/DraftEditor";
import { CheckBox, CheckBoxOutlineBlank, InfoOutlined } from "@mui/icons-material";
import { StyledStaticButtonRoundedGroup, StyledStaticRoundedButton } from "../../../../common/StyledComponents";
import { useLocation, useNavigate } from "react-router-dom";
import { notify } from "../../../../../redux/features/system/systemAlert";
import { Close } from "@mui/icons-material";

const CreateTemplate = (props) => {
  const classes = useStyles();
  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const tabId = +params.get("tabId");
  const [state, setState] = React.useState({
    templateName: "",
    templateDescription: "",
    templateSource: "",
    kycRequired: false,
    contractInputFields: "",
    isGlobal: false,
    kycRequired: true,
  });
  const [showEditor, setShowEditor] = React.useState(false);
  const [editor, setEditor] = React.useState(null);
  const [baseTemplateData, setBaseTemplateData] = React.useState([]);
  const [baseTemplates, setBaseTemplates] = React.useState(null);
  const [contractInputFieldsData, setContractInputFieldsData] = React.useState([]);
  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" />;
  const templateNameCount = state.templateName?.length;
  const templateDescriptionCount = state.templateName?.length;

  useEffect(() => {
    getBaseTemplates();
    getInputFields();
  }, []);

  const getBaseTemplates = async () => {
    const response = await getContractBaseTemplates(user, dispatch);
    setBaseTemplateData(response?.response);
  };

  const getInputFields = async () => {
    const contractInput = await getContractInput(user, dispatch);
    if (contractInput.response) {
      setContractInputFieldsData(contractInput.response);
      setState({ ...state, contractInputFields: contractInput.response?.map((value) => value.id).join(",") });
    }
  };
  const handleTemplateSourceChange = (e) => {
    const value = e.target.value;
    setState({ ...state, templateSource: value });
    if (value === "new") {
      setShowEditor(true);
      setEditor(null);
    } else {
      setBaseTemplates(null);
      setShowEditor(false);
    }
  };

  const handleBaseTemplatesChange = async (e) => {
    const value = e.target.value;
    setBaseTemplates(value);

    const baseTemplate = await getBaseTemplateData(value, user, dispatch);
    setShowEditor(true);
    setEditor(baseTemplate.response[0].content);
  };

  const onCreateTemplate = async () => {
    if (editor === null || editor.search('<span style="background-color') !== -1) {
      return;
    }
    const body = {};

    const { templateName, templateDescription, contractInputFields, kycRequired, isGlobal } = state;
    dispatch(
      notify({
        message: `Creating template  ${templateName}`,
        type: "info",
      })
    );
    if (isGlobal) {
      body["aid"] = 0;
    } else {
      body["aid"] = -1;
    }
    body["cid"] = +params.get("id");
    body["title"] = templateName;
    body["description"] = templateDescription;
    body["content"] = editor;
    body["inputFields"] = contractInputFields;
    body["kycRequired"] = kycRequired;
    const response = await addContractTemplate(body, user, dispatch);

    if (response?.response) {
      dispatch(
        notify({
          message: `${templateName} template added successfully!`,
          type: "success",
        })
      );

      if (props.fromSendContract) {
        props.handleCloseModal(false)
        props.getTemplates();
      }
      else {
        navigate(`/app/campaigns/influencers/contracts?id=${body["cid"]}&tabId=${tabId}`);
      }
    }
  };

  const handleBack = () => {
    const cid = +params.get("id");
    navigate(`/app/campaigns/influencers/contracts?id=${cid}&tabId=${tabId}`);
  }

  return (
    <Box m={props.fromSendContract ? 0 : 5} style={{ backgroundColor: "#FAFAFB", padding: "10px" }}>
      <Box m={3} position="relative">
        {props.fromSendContract ? (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <h4 className={styles.header} style={{ margin: 0, textAlign: 'center', flexGrow: 1 }}>Edit Template Information</h4>
            <IconButton onClick={props.handleCloseModal} style={{ position: 'absolute', right: 0 }}>
              <Close />
            </IconButton>
          </Box>
        ) : (
          <h4 className={styles.header}>Edit Template Information</h4>
        )}

        <Grid container spacing={4} style={{ paddingTop: 20 }}>
          <Grid item md={6} xs={12}>
            <TextField
              sx={{ width: "100%" }}
              variant="outlined"
              label="Template Name"
              placeholder="<Enter Template Name>"
              type="text"
              required
              helperText="Max 48 Chars"
              value={state.templateName}
              onChange={(e) => setState({ ...state, templateName: e.target.value })}
              InputProps={{
                endAdornment: <div className={classes.counter}>{templateNameCount}/48</div>,
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              sx={{ width: "100%" }}
              variant="outlined"
              label="Template Description"
              placeholder="<Enter Template Description>"
              type="text"
              required
              helperText="Max 256 Chars"
              value={state.templateDescription}
              onChange={(e) => setState({ ...state, templateDescription: e.target.value })}
              InputProps={{
                endAdornment: <div className={classes.counter}>{templateDescriptionCount}/256</div>,
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="template-source">Template Source</InputLabel>
              <Select
                required={true}
                variant="outlined"
                label="Template Source"
                id="templateSource"
                value={state.templateSource}
                onChange={handleTemplateSourceChange}
              >
                <MenuItem value="new">Start Fresh</MenuItem>
                <MenuItem value="base">Use Base Template</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {state.templateSource === "base" && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-standard-label">Base Templates</InputLabel>
                <Select
                  required={true}
                  variant="outlined"
                  label="Base Templates"
                  id="baseTemplates"
                  value={baseTemplates}
                  onChange={handleBaseTemplatesChange}
                >
                  {baseTemplateData?.map((base) => (
                    <MenuItem value={base.id}>{base.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>

        {showEditor && (
          <Grid container spacing={2} sx={{ mt: 1 }}>

            <Grid item xs={12} sm={12}>
              {state.templateSource === "new" && <DraftEditor value={editor} setValue={setEditor} readOnly={false} />}
              {state.templateSource === "base" && !(editor === null || editor === undefined || editor === "") && (
                <DraftEditor value={editor} setValue={setEditor} readOnly={false} />
              )}
            </Grid>

            {props.fromSendContract ? <></> : <><Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <FormControlLabel
                  label={
                    <>
                      <span>Make this template global for this account &nbsp;</span>
                      <IconButton>
                        <Tooltip
                          title={
                            <span style={{ whiteSpace: "pre-line" }}>
                              Selecting this will make the template available in all the campaigns of this account
                            </span>
                          }
                        >
                          <InfoOutlined fontSize="small" style={{ color: "#6b778c" }} />
                        </Tooltip>
                      </IconButton>
                    </>
                  }
                  control={
                    <Checkbox checked={state.isGlobal} onChange={(e) => setState({ ...state, isGlobal: !state.isGlobal })} />
                  }
                />
              </FormControl>
            </Grid>
              <Grid item xs={12} sm={12} >
                <FormControl fullWidth>
                  <FormControlLabel
                    label={
                      <>
                        <span>KYC is required before accepting the contract &nbsp;</span>
                        <IconButton>
                          <Tooltip
                            title={
                              <span style={{ whiteSpace: "pre-line" }}>
                                Selecting this will ensure that the influencer has uploaded a KYC document before proceeding
                              </span>
                            }
                          >
                            <InfoOutlined fontSize="small" style={{ color: "#6b778c" }} />
                          </Tooltip>
                        </IconButton>
                      </>
                    }
                    control={
                      <Checkbox
                        checked={state.kycRequired}
                        onChange={(e) => setState({ ...state, kycRequired: !state.kycRequired })}
                      />
                    }
                  />
                </FormControl>
              </Grid></>}
          </Grid>
        )}

        <Grid container sx={{ paddingTop: "10px", width: "100%", justifyContent: "space-between" }}>
          {props.fromSendContract && showEditor && (
            <Grid item xs={9} sm={9}>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <FormControlLabel
                    label={
                      <>
                        <span>Make this template global for this account &nbsp;</span>
                        <IconButton>
                          <Tooltip
                            title={
                              <span style={{ whiteSpace: "pre-line" }}>
                                Selecting this will make the template available in all the campaigns of this account
                              </span>
                            }
                          >
                            <InfoOutlined fontSize="small" style={{ color: "#6b778c" }} />
                          </Tooltip>
                        </IconButton>
                      </>
                    }
                    control={
                      <Checkbox checked={state.isGlobal} onChange={(e) => setState({ ...state, isGlobal: !state.isGlobal })} />
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <FormControlLabel
                    label={
                      <>
                        <span>KYC is required before accepting the contract &nbsp;</span>
                        <IconButton>
                          <Tooltip
                            title={
                              <span style={{ whiteSpace: "pre-line" }}>
                                Selecting this will ensure that the influencer has uploaded a KYC document before proceeding
                              </span>
                            }
                          >
                            <InfoOutlined fontSize="small" style={{ color: "#6b778c" }} />
                          </Tooltip>
                        </IconButton>
                      </>
                    }
                    control={
                      <Checkbox
                        checked={state.kycRequired}
                        onChange={(e) => setState({ ...state, kycRequired: !state.kycRequired })}
                      />
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          )}

          {!props.fromSendContract && (
            <Grid item xs={10} sm={10} sx={{ paddingTop: 8.7 }}>
              <Button
                variant="outlined"
                style={{
                  width: "180px",
                  height: "38px",
                  color: "#474747",
                  borderColor: "#474747",
                  borderRadius: "16px",
                }}
                onClick={handleBack}
              >
                Back
              </Button>
            </Grid>
          )}

          <Grid container item xs={props.fromSendContract ? 3 : 2} sm={props.fromSendContract ? 3 : 2} justifyContent="flex-end" sx={{ paddingTop: props.fromSendContract ? 4 : 8 }}>
            <StyledStaticButtonRoundedGroup
              color="primary"
              size="small"
              exclusive
              style={{ height: "30px", width: "200px" }}
              aria-label="Template"
              disabled={
                !editor ||
                !state.templateDescription ||
                !state.templateName ||
                templateDescriptionCount > 256 ||
                templateNameCount > 48
              }
            >
              <StyledStaticRoundedButton value="active" className={styles.staticButton} onClick={onCreateTemplate}>
                <b>Save</b>
              </StyledStaticRoundedButton>
            </StyledStaticButtonRoundedGroup>
          </Grid>
        </Grid>

      </Box>
    </Box>
  );
};

export default CreateTemplate;
