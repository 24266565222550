import React from "react";
import topBrands from "../../assets/images/TopBrands.png";
import styles from "../../styles/internal/Landing/Landing.module.css";

function TopBrands() {
  return (
    <div className={styles.footer}>
    <div className={styles.footerTitle}>Trusted by Top Brands</div>
    <img 
      src={topBrands} 
      alt="trusted organizations" 
      style={{ 
        marginTop: "70px", 
        marginBottom: "20px", 
        width: "90%",
        height: "auto",
      }} 
    />
  </div>
  

  );
}

export default TopBrands;
