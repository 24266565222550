import React from 'react';
import { Card, CardContent, Grid, Typography, Box } from '@mui/material';
import { Circle } from '@mui/icons-material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { snapshotHeaderIcons, textColor } from '../../utility/plaformIcons';
import { campaignStatusColors } from '../../utility/status';

const CampaignList = ({ data, title = "Campaigns" }) => {
    const commonTextStyle = { fontWeight: '500', fontSize: '12px', color: '#030229' };
    const navigate = useNavigate();

    const handleNavigation = (id, universal) => {
        navigate(`/app/campaigns/overview?id=${id}&universal=${universal}`);
    };

    return (
        <Card sx={{ borderRadius: '10px', mt: 3 }}>
            <CardContent>
                <Typography variant="h6" sx={{ ...commonTextStyle, fontWeight: 'bold', mb: 2, fontSize: '16px' }}>
                    {title}
                </Typography>
                <Grid container spacing={1}>
                    {['Name', '', 'End Date', 'Creator Accounts', 'Total Publications', 'Status'].map((header, index) => (
                        <Grid item xs={2} key={index}>
                            <Typography variant="subtitle2" sx={commonTextStyle}>
                                {header}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
                <hr style={{ border: '0.5px solid #E0E0E0', margin: '8px 0' }} />
                {data?.map((item, index) => (
                    <Grid container spacing={2} key={index} sx={{ mt: 1 }}>
                        <Grid item xs={2}>
                            <Typography
                                variant="subtitle2"
                                sx={{ ...commonTextStyle, cursor: 'pointer', textDecoration: 'none' }}
                                onClick={() => handleNavigation(item.id, item.universal)}
                            >
                                {item.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Box display="flex" flexWrap="wrap" gap={1}>
                                {item.platform?.map((plat) => (
                                    <Box
                                        key={plat.name}
                                        sx={{
                                            width: '12px',
                                            height: '12px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            fontSize: '12px',
                                            color: textColor[plat.name.toLowerCase()]
                                        }}
                                    >
                                        {snapshotHeaderIcons[plat.name.toLowerCase()]
                                            ? React.cloneElement(snapshotHeaderIcons[plat.name.toLowerCase()], { style: { fontSize: '12px', width: '12px', height: '12px' } })
                                            : null}
                                    </Box>
                                ))}
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="subtitle2" sx={commonTextStyle}>
                                {item.endDate ? moment(item.endDate).format('MMMM D, YYYY') : 'N/A'}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="subtitle2" sx={commonTextStyle}>
                                {item.influencerCount}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                            <Box
                                sx={{
                                    backgroundColor: '#E3F2FD',
                                    padding: '4px 6px',
                                    borderRadius: '4px',
                                    width: '78px',
                                    textAlign: 'center',
                                    marginRight: '32px'
                                }}
                            >
                                <Typography variant="subtitle2" sx={{ ...commonTextStyle, color: '#1976D2' }}>
                                    {item.postCount}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box display="flex" alignItems="center">
                                <Circle sx={{ color: campaignStatusColors[item.current_status], mr: 1, fontSize: '12px' }} />
                                <Typography variant="subtitle2" sx={commonTextStyle}>
                                    {item.current_status}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                ))}
            </CardContent>
        </Card>
    );
};


export default CampaignList;
