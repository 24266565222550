import { Button, Grid, Box, Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../redux/features/user/userSlice";
import { useLocation } from "react-router-dom";
import { getAllGroupsListForCampaign } from "../../../../services/group";
import { getFormattedDate } from "../../../../utility/momentManipulations";
import DateRangeCustomPicker from "../../../common/daterangepicker";
import GroupDropdown from "../../../common/groupDropdown";
import SelectColumns from "../../../common/selectColumns";
import { notify } from "../../../../redux/features/system/systemAlert";
import { IdPlatformMapping } from "../../../../utility/plaformIcons";

const CampaignPublicationsFilter = ({
  changeHeaders,
  tableHeaders,
  allSelectedGroups,
  filter,
  selectedStartDate,
  selectedEndDate,
  sendSelectedFilters,
  selectedPlatform,
  selectedCount,
  setSelectedCount,
  handlePostAction,
  selectedRows
}) => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const campaignId = params.get("id");
  const dispatch = useDispatch();
  const [platform, setPlatform] = React.useState(selectedPlatform);
  const [selectedGroups, setSelectedGroups] = React.useState(allSelectedGroups); //list of selected groups
  const [filterValue, setFilterValue] = React.useState(filter); //serached value
  const [startDate, setStartDate] = React.useState(selectedStartDate); //start date of the campaign
  const [endDate, setEndDate] = React.useState(selectedEndDate); //end date of the campaign
  const [groupList, setGroupList] = React.useState([]); //all the groups in the platforms
  const [selectedAction, setSelectedAction] = React.useState("");

  React.useEffect(() => {
    setFilterValue(filter);
    setPlatform(selectedPlatform);
    setSelectedGroups(allSelectedGroups);
    getGroupList([IdPlatformMapping[selectedPlatform[0]]]);
  }, [platform, selectedPlatform]);


  const getGroupList = async (platforms) => {
    const groups = await getAllGroupsListForCampaign(campaignId, platforms, user, dispatch);
    if (groups.status === true) {
      setGroupList(groups.response);
    } else {
      if (groups.response?.message?.includes("expired")) {
        dispatch(logout());
      }
      dispatch(
        notify({
          message: groups.response?.message,
          type: "error",
        })
      );
    }
  };

  const updateHeaders = (event) => {
    changeHeaders(event);
  };

  const sendSelectedGroups = (event) => {
    setSelectedGroups(event);
  };

  const sendSelectedDates = (event) => {
    setStartDate(getFormattedDate(event.startDate, "YYYY-MM-DD"));
    setEndDate(getFormattedDate(event.endDate, "YYYY-MM-DD"));
  };

  const applyFilters = () => {
    sendSelectedFilters({
      filterValue,
      startDate,
      endDate,
      selectedGroups,
    });
  };


  const chooseAction = (event) => {
    const { value } = event.target;  
    if (window.confirm(`Are you sure you want to ${value} this post?`)) {
      handlePostAction(selectedRows, value);
      setSelectedAction("");
      setSelectedCount(0);
    }
  
    // Assuming selectedAction is a function, adjust this line accordingly
    selectedAction(typeof value === "string" ? value.split(",") : value);
  };  

  return (
    <>
      {" "}
      <Box style={{ marginBottom: "25px" }}>
        <Grid container spacing={1}>
          <Grid item md={2} xs={12} sm={6}>
            <GroupDropdown groupList={groupList} sendSelectedGroups={sendSelectedGroups} parentSelectedGroup={selectedGroups} />
          </Grid>
          <Grid item xs={3}>
            <DateRangeCustomPicker sendSelectedDates={sendSelectedDates} parentStartDate={startDate} parentEndDate={endDate} needRange={true} />
          </Grid>
          <Grid item xs={1}>
            <Button
              variant="contained"
              style={{
                background: "linear-gradient(180deg, #51a6ff 0%, #007dff 100%)",
                borderRadius: "8px",
              }}
              onClick={applyFilters}
            >
              Go
            </Button>
          </Grid>
          {selectedCount > 0 && (

            <Grid item xs={1} style={{ marginTop: "5px" }}>

              <span style={{ fontWeight: "bold" }}>{selectedCount} Selected</span>

            </Grid>
          )}

          <Grid item xs={2}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Action</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Action"
                placeholder="Action"
                sx={{
                  minWidth: "7vw",
                  marginRight: "5px",
                  borderRadius: "10px",
                }}
                value={selectedAction}
                onChange={chooseAction}
              >
                <MenuItem key={"approve"} value={"approve"}>Accept Publication</MenuItem>
                <MenuItem key={"reject"} value={"reject"}>Reject Publication</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <SelectColumns tableHeaders={tableHeaders} updateHeaders={updateHeaders} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

CampaignPublicationsFilter.propTypes = {
  changeHeaders: PropTypes.func.isRequired,
  tableHeaders: PropTypes.array.isRequired,
  allSelectedGroups: PropTypes.array.isRequired,
  selectedStartDate: PropTypes.string.isRequired,
  selectedEndDate: PropTypes.string.isRequired,
  sendSelectedFilters: PropTypes.func.isRequired,
  getSearchValue: PropTypes.func.isRequired,
  sendInformation: PropTypes.func.isRequired,
  selectedPlatform: PropTypes.array.isRequired,
  allAllowedPlatforms: PropTypes.array.isRequired,
  disableRow: PropTypes.bool,
};

export default CampaignPublicationsFilter;
