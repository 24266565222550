import { Button, Grid } from "@mui/material";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import CampaignDynamicLinksTable from "./CampaignDynamicLinksTable";
import CampaignDynamicLinksFilter from "./CampaignDynamicLinksFilter";
import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { getDynmaicLinkSummary, getDynamicLinkFilters, sendXlsx } from "../../../../services/campaignAnalytics";
import { snapshotHeaderIcons, textColor, otherIcons } from "../../../../utility/plaformIcons";
import Loader from "../../../common/loader";
import { useLocation } from "react-router-dom";
import { getFormattedDate } from "../../../../utility/momentManipulations";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ManageDynamicLinks from "./ManageDynamicLinks";
import BlockLoginAccess from "../../../common/BlockLoginAccess";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import CampaignDynamicLinkGraphs from "./CampaignDynamicLinkGraphs";
import { notify } from "../../../../redux/features/system/systemAlert";
import CampaignDynamicLinksCreateShortUrl from "../campaignDynamicLinks/CampaignDynamicLinksCreateShortUrl";
import DummyCampaign from "../../../common/DummyCampaign";

const CampaignDynamicLinksSnapshot = () => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();

  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const [loading, setLoading] = useState(false);
  const [dataFound, setDataFound] = useState(false);
  const [shorturls, setShorturls] = useState([]);
  const [selectedShorturls, setSelectedShorturls] = useState([]);
  const [selectedPlatforms, setSelectedPlatforms] = useState(Object.keys(snapshotHeaderIcons));
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedDestinationIds, setSelectedDestinationIds] = useState([]);
  const [selectedInfluencers, setSelectedInfluencers] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(
    getFormattedDate(moment().subtract(6, "months").toDate(), "YYYY-MM-DD")
  ); //select campaign duration for 7 days
  const [selectedEndDate, setSelectedEndDate] = useState(getFormattedDate(moment().toDate(), "YYYY-MM-DD"));
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);
  const [value, setValue] = useState(0);
  const [filterStatus, setFilterStatus] = useState(false);
  const [linkClicksY, setLinkClicksY] = useState([]);
  const [linkClicksX, setLinkClicksX] = useState([]);
  const [conversionsData, setConversionsData] = useState([]);
  const [clickGraph, setClickGraph] = useState("daily");
  const [appInstallX, setAppInstallX] = useState([]);
  const [appInstallY, setAppInstallY] = useState([]);
  const [openShortUrlCreate, setOpenShortUrlCreate] = useState(false);
  const [setselectedUrl, setSetselectedUrl] = useState({});
  const universal = params.get("universal");
  const exportXlsx = async () => {
    const exportResponse = await sendXlsx(
      selectedShorturls?.map((value) => value.id),
      getFormattedDate(selectedStartDate, "YYYY-MM-DD"),
      getFormattedDate(selectedEndDate, "YYYY-MM-DD"),
      user,
      dispatch,
      "firebase/analysis/sendxlsx"
    );

    if (exportResponse.status) {
      dispatch(
        notify({
          message: "Information will be sent to you e-mail address!",
          type: "success",
        })
      );
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setFilterStatus(false);
    } else {
      setFilterStatus(true);
    }
  };

  const createClickGraph = (clickSummary) => {
    var dataArray = [];
    const acceptedValues = clickSummary["response"][clickGraph];
    if (acceptedValues != null) {
      const keys = acceptedValues?.map((value) => value.day);
      const clicksValue = acceptedValues?.map((value) => value.click);
      const redirectsValue = acceptedValues?.map((value) => value.redirect);
      const installsValue = acceptedValues?.map((value) => value.install);
      const opensValue = acceptedValues?.map((value) => value.open);
      const reopensValue = acceptedValues?.map((value) => value.reopen);
      var clicks = { name: "Clicks", data: [] };
      var redirect = { name: "redirect", data: [] };
      var installs = { name: "Installs", data: [] };
      var opens = { name: "open", data: [] };
      var reopens = { name: "reopen", data: [] };

      if (Array.isArray(keys) && keys?.length > 0) {
        for (let i = 0; i < keys?.length; i++) {
          const clickValue = clicksValue[i];
          const redirectValue = redirectsValue[i];
          const installValue = installsValue[i];
          const openValue = opensValue[i];
          const reopenValue = reopensValue[i];

          clicks.data.push(clickValue);
          redirect.data.push(redirectValue);
          installs.data.push(installValue);
          opens.data.push(openValue);
          reopens.data.push(reopenValue);
        }
      }
      dataArray.push(clicks);
      dataArray.push(redirect);
      dataArray.push(installs);
      dataArray.push(opens);
      dataArray.push(reopens);
      setAppInstallX(keys);
      setAppInstallY(installs.data);
      setLinkClicksX(keys);
      setLinkClicksY(dataArray);
    }
  };

  const createConversionsGraph = (conversionSummary) => {
    if (conversionSummary["response"] != null) {
      const keys = Object.keys(conversionSummary["response"][0]);
      const convValuesAreZero = Object.values(conversionSummary["response"][0]).every((value) => value === 0);
      const conversions = [];
      if (!convValuesAreZero) {
        keys.forEach((key) => {
          conversions.push({
            value: conversionSummary["response"][0][key],
            name: key,
          });
        });
      }
      setConversionsData(conversions);
    }
  };

  const updateWidth = () => {
    const width = elementRef.current ? elementRef.current.offsetWidth : 0;
    setWidth(width);
  };

  useEffect(() => {
    updateWidth();
    initialLoad();
  }, [+params.get("id")]);

  const initialLoad = async () => {
    const shorturlIds = await getLinksData(selectedDestinationIds?.map((value) => value.urlId));
    createGraphs(
      shorturlIds?.map((value) => value.id),
      selectedStartDate,
      selectedEndDate
    );
  };

  const getLinksData = async (selectedGroups, selectedPlatforms, selectedDestinationIds, selectedInfluencers) => {
    const shorturls = await getDynamicLinkFilters(
      +params.get("id"),
      selectedGroups,
      selectedPlatforms,
      selectedDestinationIds,
      selectedInfluencers,
      user,
      dispatch
    );

    if (shorturls.status === true) {
      if (shorturls.response == null) {
        return [];
      }
      setDataFound(true);
      setShorturls(shorturls.response || []);
      setSelectedShorturls(shorturls.response || []);
      return shorturls.response || [];
    }
  };

  const createGraphs = async (ids, sDate, eDate) => {
    setLoading(true);
    const startDate = getFormattedDate(sDate, "YYYY-MM-DD");
    const endDate = getFormattedDate(eDate, "YYYY-MM-DD");
    const firebaseSummary = await getDynmaicLinkSummary(ids, startDate, endDate, user, dispatch, "firebase/analysis/summary");
    const conversionSummary = await getDynmaicLinkSummary(
      ids,
      startDate,
      endDate,
      user,
      dispatch,
      "firebase/analysis/conversions"
    );
    setLoading(false);
    if (firebaseSummary.status === true) {
      createClickGraph(firebaseSummary);
      createConversionsGraph(conversionSummary);
    }
  };

  const sendFilterChange = (event) => {
    setSelectedStartDate(event.startDate);
    setSelectedEndDate(event.endDate);
    setSelectedDestinationIds(event.selectedDestinationIds);
    createGraphs(
      event.selectedShorturls?.map((url) => url.id),
      event.startDate,
      event.endDate
    );
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  return (
    <div>
      <DummyCampaign universal={universal} />

      <Grid container spacing={2} ref={elementRef}>
        <Grid item xs={12}>
          <CampaignDynamicLinksFilter
            allSelectedPlatforms={selectedPlatforms}
            allSelectedGroups={selectedGroups}
            allSelectedDestinationIds={selectedDestinationIds}
            allSelectedInfluencers={selectedInfluencers}
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            sendFilterChange={sendFilterChange}
            allurls={shorturls}
            getLinksData={getLinksData}
            selectedShorturls={selectedShorturls}
            setSelectedShorturls={setSelectedShorturls}
            filterStatus={filterStatus}
          />
          <br />
        </Grid>{" "}
      </Grid>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ bgcolor: "background.paper" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              return (
              <Tab
                icon={otherIcons["insights"]}
                sx={{
                  ...textColor["twitter"],
                  "&.Mui-selected": {
                    color: textColor["twitter"], // Change to desired color for selected state
                  },
                }}
                label={"Metrics"}
                value={0}
              />
              <Tab
                icon={otherIcons["addLink"]}
                sx={{
                  ...textColor["facebook"],
                  "&.Mui-selected": {
                    color: textColor["facebook"], // Change to desired color for selected state
                  },
                }}
                label={"Manage Dynamic Links"}
                value={1}
              />
              );
            </TabList>
          </Box>
        </TabContext>
      </Box>
      <br />
      {!openShortUrlCreate && (
        <Grid item xs={12}>
          {value === 0 ? (
            <div>
              {loading ? (
                <Loader />
              ) : dataFound ? (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CampaignDynamicLinkGraphs
                      linkClicksY={linkClicksY}
                      linkClicksX={linkClicksX}
                      conversionsData={conversionsData}
                      appInstallX={appInstallX}
                      appInstallY={appInstallY}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <h4 style={{ paddingLeft: "20px" }}>
                      <b className={styles.title}>Link Details</b>
                    </h4>

                    <Button
                      variant="contained"
                      style={{
                        background: "linear-gradient(180deg, #51a6ff 0%, #007dff 100%)",
                        borderRadius: "8px",
                        float: "right",
                      }}
                      onClick={exportXlsx}
                      disabled={selectedShorturls?.length === 0}
                    >
                      Export XLSX
                    </Button>

                    <CampaignDynamicLinksTable
                      selectedStartDate={selectedStartDate}
                      selectedEndDate={selectedEndDate}
                      shorturlIds={selectedShorturls?.map((value) => value.id)}
                    />
                  </Grid>
                </Grid>
              ) : (
                <>
                  <div className={styles.container}>
                    <div className={styles.child}> No links added on this campaign</div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <ManageDynamicLinks
              BlockLoginAccess={BlockLoginAccess}
              width={width}
              user={user}
              cid={params.get("id")}
              openShortUrlCreate={openShortUrlCreate}
              setOpenShortUrlCreate={setOpenShortUrlCreate}
              setselectedUrl={setselectedUrl}
              setSetselectedUrl={setSetselectedUrl}
            />
          )}
        </Grid>
      )}
      {openShortUrlCreate && (
        <CampaignDynamicLinksCreateShortUrl
          urlId={setselectedUrl?.id}
          lid={setselectedUrl?.lid}
          open={openShortUrlCreate}
          setOpen={setOpenShortUrlCreate}
          group={setselectedUrl?.listName}
        />
      )}
    </div>
  );
};

export default CampaignDynamicLinksSnapshot;
