const TwitterRetweet = () => (
  <svg width="18" height="12" viewBox="0 0 18 12" fill="#1DA1F2" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.50288 0.135405C3.30625 -0.0451352 2.98746 -0.045135 2.79083 0.135405L0.184338 2.52864C-0.0614461 2.75432 -0.0614461 3.12021 0.184338 3.34589C0.430123 3.57156 0.828618 3.57156 1.0744 3.34589L2.51748 2.02087V8.9472C2.51748 10.543 3.92638 11.8366 5.66434 11.8366H9.69231C10.0399 11.8366 10.3217 11.5779 10.3217 11.2587C10.3217 10.9396 10.0399 10.6808 9.69231 10.6808H5.66434C4.62156 10.6808 3.77622 9.90466 3.77622 8.9472V2.02087L5.2193 3.34589C5.46509 3.57156 5.86358 3.57156 6.10937 3.34589C6.35515 3.12021 6.35515 2.75432 6.10937 2.52864L3.50288 0.135405Z"
      fill="#1DA1F2"
    />
    <path
      d="M14.4971 11.8646C14.6937 12.0451 15.0125 12.0451 15.2092 11.8646L17.8157 9.47136C18.0614 9.24568 18.0614 8.87979 17.8157 8.65411C17.5699 8.42844 17.1714 8.42844 16.9256 8.65411L15.4825 9.97913V3.0528C15.4825 1.45704 14.0736 0.163414 12.3357 0.163414H8.30769C7.9601 0.163414 7.67832 0.422139 7.67832 0.741292C7.67832 1.06044 7.9601 1.31917 8.30769 1.31917H12.3357C13.3784 1.31917 14.2238 2.09534 14.2238 3.0528V9.97913L12.7807 8.65411C12.5349 8.42844 12.1364 8.42844 11.8906 8.65411C11.6448 8.87979 11.6448 9.24568 11.8906 9.47136L14.4971 11.8646Z"
      fill="#1DA1F2"
    />
  </svg>
);

export default TwitterRetweet;
