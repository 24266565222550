import { Box, Button, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/internal/MainFooter.module.css";
import upperPattern from "../../assets/images/FooterUpperPattern.svg";
import empowerLogoBeta from "../../assets/images/EmpowrLogo.svg";
import blueTick from "../../assets/images/blueTick.svg";
import slack from "../../assets/images/Slack.svg";
import { LinkedIn, MailOutline, Twitter, Facebook } from "@mui/icons-material";
import config from "../../config/main.json";

const { SUPPORT_EMAIL } = config;

const regex =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

function MainFooter() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [isError, setIsError] = useState(false);

  const validateEmail = () => regex.test(email);

  return (
    <footer>
      <div className={styles.mainBackground}>
        <Grid container className={styles.firstPart}>
          <Grid item md={6}>
            <div className={styles.firstPartContent}>
              <h2 className={styles.signUpTitle}>Sign-up for free trial</h2>
              <div className={styles.signUpSubText}>
                The fastest and simple way to generate growing business solutions with our products
              </div>
              <TextField
                variant="outlined"
                className={styles.getStartedField}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setIsError(false);
                }}
                required
                sx={{
                  "& .MuiOutlinedInput-input:focus": {
                    border: "none",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <Button
                      variant="contained"
                      size="large"
                      className={styles.getStartedButton}
                      sx={{ mr: { xs: "-5px", md: 0 } }}
                      onClick={() => {
                        if (email?.length > 0 && validateEmail(email)) {
                          navigate(`/pricing?email=${email}`);
                          setEmail("");
                          setIsError(false);
                        } else setIsError(true);
                      }}
                    >
                      Get Started
                    </Button>
                  ),
                }}
              />
              {isError && <Box sx={{ fontSize: "1rem", color: "white", my: "0.5rem" }}>Please enter correct email.</Box>}
              <div className={styles.getStartedFieldSubText}>
                <img src={blueTick} alt="tick" /> No Credit Card Required
              </div>
            </div>
          </Grid>
          <Grid item md={6} style={{ textAlign: "center" }}>
            <img src={upperPattern} alt="pattern" style={{ width: "100%" }} />
          </Grid>
        </Grid>
        <hr className={styles.hrStyle} />
        <Grid container className={styles.secondPart}>
          <Grid item md={5}>
            <div className={styles.secondPartLeftContent}>
              <img src={empowerLogoBeta} alt="logo" className={styles.logo} />
              <div className={styles.logoSubText}>Drive Word-of-Mouth at Scale</div>
              <div style={{ color: "white", fontSize: "16px", marginTop: "70px" }}>
                <MailOutline />
                &nbsp;&nbsp;
                <a href={`mailto:${SUPPORT_EMAIL}`} style={{ color: "white" }}>
                  {SUPPORT_EMAIL}
                </a>
              </div>
              {/* <div style={{ color: "white", fontSize: "16px", marginTop: "12px" }}>
                <img src={slack} alt="Slack" />
                &nbsp;&nbsp;Join our slack community
              </div> */}
            </div>
          </Grid>
          <Grid item md={7}>
            <div style={{ width: "80%", margin: "auto", marginTop: "100px" }}>
              <Grid container>
                <Grid item md={4}>
                  <div style={{ color: "white", fontSize: "16px" }}>
                    <div style={{ fontWeight: "600" }}>Features</div>
                    <div style={{ marginTop: "12px" }}>
                      <a href="/discoverAndAnalyze" style={{ color: "white" }}>
                        Discover & Analyze
                      </a>
                    </div>
                    <div style={{ marginTop: "12px" }}>
                      <a href="/contractsAndActivate" style={{ color: "white" }}>
                        Contract & On-board
                      </a>
                    </div>
                    <div style={{ marginTop: "12px" }}>
                      <a href="/trackAndManageCampaigns" style={{ color: "white" }}>
                        Track & Manage Campaigns
                      </a>
                    </div>
                    <div style={{ marginTop: "12px" }}>
                      <a href="/monitorAndPromoteUGC" style={{ color: "white" }}>
                        Monitor UGC
                      </a>
                    </div>
                    <div style={{ marginTop: "12px" }}>
                      <a href="/attribution" style={{ color: "white" }}>
                        Influencer Attribution
                      </a>
                    </div>
                    <div></div>
                  </div>
                </Grid>
                <Grid item md={4}>
                  <div style={{ color: "white", fontSize: "16px" }}>
                    <div style={{ fontWeight: "600" }}>Company</div>
                    <div style={{ marginTop: "12px" }}>
                      <a href="/about" style={{ color: "white" }}>
                        About
                      </a>
                    </div>
                    <div style={{ marginTop: "12px" }}>
                      <a href="/pricing" style={{ color: "white" }}>
                        Pricing
                      </a>
                    </div>
                    <div style={{ marginTop: "12px" }}>
                      <a href="/contact" style={{ color: "white" }}>
                        Contact Us
                      </a>
                    </div>
                    <div style={{ marginTop: "12px" }}>
                      <a href="https://blog.empowr.ai" target="_blank" style={{ color: "white" }}>
                        Blog
                      </a>
                    </div>
                    <div style={{ marginTop: "12px" }}>
                      <a href="https://help.empowr.ai" target="_blank" style={{ color: "white" }}>
                        Help
                      </a>
                    </div>
                    <div></div>
                  </div>
                </Grid>
                <Grid item md={4}>
                  <div style={{ color: "white", fontSize: "16px" }}>
                    <div style={{ fontWeight: "600" }}>
                      <a href="/login" style={{ color: "white" }}>
                        Get Started
                      </a>
                    </div>
                    <div style={{ marginTop: "12px" }}>
                      <a href="https://www.ai-fluence.com/" style={{ color: "white" }}>
                        Aifluence
                      </a>
                    </div>
                    <div style={{ marginTop: "12px" }}>
                      <a href="/campaignBrief" style={{ color: "white" }}>
                        Submit a Campaign Brief
                      </a>
                    </div>
                    <div style={{ marginTop: "12px" }}>
                      <a href="/requestDemo" style={{ color: "white" }}>
                        Book a Demo
                      </a>
                    </div>
                    {/* <div style={{ marginTop: "12px" }}>Help</div> */}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <hr className={styles.hrStyle} />
        <div className={styles.thirdPart}>
          <Grid container className={styles.thirdPartContent} direction="row" justifyContent="space-between" alignItems="center">
            <Grid item md={3} style={{ color: "white" }}>
              © 2024 Aifluence Inc. All Right Reserved
            </Grid>
            <Grid item md={4} style={{ color: "white" }}>
              <Grid container>
                <Grid item md={4}>
                  <a href="/page/privacyPolicy" style={{ color: "white" }}>
                    Privacy
                  </a>
                </Grid>
                <Grid item md={4}>
                  <a href="/page/termsOfService" style={{ color: "white" }}>
                    Terms of Service
                  </a>
                </Grid>
                {/* <Grid item md={2}>
                  Status
                </Grid> */}
              </Grid>
            </Grid>
            <Grid item md={4} style={{ color: "white" }}>
              <div style={{ textAlign: "right" }}>
                <a
                  href="https://twitter.com/EmpowrAI"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "white", marginRight: "32px" }}
                >
                  <Twitter />
                </a>

                <a
                  href="https://www.linkedin.com/company/empowr-ai/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "white", marginRight: "32px" }}
                >
                  <LinkedIn />
                </a>
                <a href="https://www.facebook.com/empowrai/" target="_blank" rel="noreferrer" style={{ color: "white" }}>
                  <Facebook />
                </a>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </footer>
  );
}

export default MainFooter;
