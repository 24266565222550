import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Grid, Tooltip, Modal, Box } from "@mui/material";
import PropTypes from "prop-types";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import DateRangeCustomPicker from "../../../common/daterangepicker";
import ExportFiles from "../../../common/exportfiles";
import PlatformSelectionBox from "../../../common/platformSelectionBox";
import SearchItem from "../../../common/searchitem";
import SelectColumns from "../../../common/selectColumns";
import { StyledStaticButton, StyledStaticButtonGroup } from "../../../common/StyledComponents";
import { UpgradePlanToolTipText } from "../../../common/toolTip";
import AddCampaignGroup from "./add/AddCampaignGroup";
import { useLocation } from "react-router-dom";
import { getFormattedDate } from "../../../../utility/momentManipulations";

const CampaignGroupFilter = ({
  changeHeaders,
  tableHeaders,
  filter,
  selectedStartDate,
  selectedEndDate,
  selectedPlatform,
  getSearchValue,
  sendInformation,
  sendRefresh,
  fileName,
  sendPlatformUpdate,
  updateSelectedDates,
  allAllowedPlatforms,
  pageNo,
  rowsPerPageNo,
  sortOrderBy,
  sortOrder,
  handleModalClose
}) => {
  const menu = useSelector((state) => state.menu); // get loggedIn menu state
  const groupPermissions = menu.permissions?.Campaigns?.Fields?.Influencers?.Fields?.Groups;

  const [platform, setPlatform] = useState(selectedPlatform);
  const [filterValue, setFilterValue] = useState(filter);
  const [startDate, setStartDate] = useState(selectedStartDate);
  const [endDate, setEndDate] = useState(selectedEndDate);
  const [selectedPlatforms, setSelectedPlatforms] = useState(selectedPlatform);
  const [isModalOpen, setIsModalOpen] = useState(false); 

  const location = useLocation(); 
  const search = location.search;
  const params = new URLSearchParams(search);
  const campaignId = params.get("id");
  const universal = params.get("universal");

  React.useEffect(() => {
    setFilterValue(filter);
    setPlatform(selectedPlatform);
  }, [platform, selectedPlatform]);

  const updateHeaders = (event) => {
    changeHeaders(event);
  };

  const sendSelectedPlatforms = (event) => {
    setSelectedPlatforms(event);
    sendPlatformUpdate(event);
  };

  const sendExportSelected = (event) => {
    sendInformation({
      export: 1,
      filter: "",
      mimeType: event,
      fileName: fileName,
      page: pageNo,
      rowsPerPage: rowsPerPageNo,
      orderBy: sortOrderBy,
      order: sortOrder
    });
  };

  const sendSearchedValue = (event) => {
    setFilterValue(event);
    getSearchValue(event);
  };

  const refreshData = async () => {
    sendRefresh(true);
  };

  const sendSelectedDates = (event) => {
    const startDate = getFormattedDate(event.startDate, "YYYY-MM-DD");
    const endDate = getFormattedDate(event.endDate, "YYYY-MM-DD");
    setStartDate(startDate);
    setEndDate(endDate);
    updateSelectedDates({ startDate, endDate });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    handleModalClose();
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item md={7} xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <SearchItem sendSearchedValue={sendSearchedValue} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={4} position={{ md: "absolute" }} right={{ md: 40, xs: 0, sm: 0 }} paddingBottom={{ md: 0, sm: 5, xs: 5 }}>
          <Grid container spacing={1}>
            {groupPermissions.AddGroup.Visible && (
              <Grid item md={5}>
                <Tooltip title={!groupPermissions.AddGroup.Editable && UpgradePlanToolTipText()}>
                  <StyledStaticButtonGroup color="primary" exclusive aria-label="Campaign" size="small">
                    <StyledStaticButton
                      value="active"
                      className={styles.staticButton}
                      disabled={!groupPermissions.AddGroup.Editable}
                      onClick={openModal} // Open the modal on button click
                    >
                      <b>+ Add Group</b>
                    </StyledStaticButton>
                  </StyledStaticButtonGroup>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={1}>
        <Grid item md={7} xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item md={5} xs={12} sm={6}>
              <PlatformSelectionBox sendSelectedPlatforms={sendSelectedPlatforms} parentPlatforms={selectedPlatforms} />
            </Grid>
            {/* <Grid item xs={4}>
              <DateRangeCustomPicker sendSelectedDates={sendSelectedDates} parentStartDate={startDate} parentEndDate={endDate} needRange={true} />
            </Grid> */}
          </Grid>
        </Grid>
        <Grid item md={5} xs={12} sm={6}>
          <Grid container spacing={2} justifyContent={{ xs: "flex-start", md: "flex-end" }}>
            <Grid item>
              <SelectColumns tableHeaders={tableHeaders} updateHeaders={updateHeaders} />
            </Grid>
            <Grid item>              
              <ExportFiles sendExportSelected={sendExportSelected} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Modal for Add Group */}
      <Modal open={isModalOpen} onClose={closeModal}>
        <Box>
          <AddCampaignGroup closeModal={closeModal} campaignId={campaignId} universal={universal} />
        </Box>
      </Modal>
    </>
  );
};

CampaignGroupFilter.propTypes = {
  changeHeaders: PropTypes.func.isRequired,
  tableHeaders: PropTypes.array.isRequired,
  selectedStartDate: PropTypes.string.isRequired,
  selectedEndDate: PropTypes.string.isRequired,
  getSearchValue: PropTypes.func.isRequired,
  sendInformation: PropTypes.func.isRequired,
  fileName: PropTypes.string.isRequired,
  sendRefresh: PropTypes.func.isRequired,
  selectedPlatform: PropTypes.array.isRequired,
  sendPlatformUpdate: PropTypes.func.isRequired,
  updateSelectedDates: PropTypes.func.isRequired,
  allAllowedPlatforms: PropTypes.array.isRequired,
  handleModalClose: PropTypes.func.isRequired, // Add handleModalClose prop type
};

export default CampaignGroupFilter;
