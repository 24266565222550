import { Box, Typography } from "@mui/material";
import React from "react";
import compsvg from "../../assets/images/comp.svg";

const IconWithText = (props) => {
  const { icon, text } = props;
  return (
    <Box display={"flex"} alignItems={"center"}>
      <Box
        bgcolor={"#E9ECF1"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        p="2rem"
        height={"80px"}
        width={"80px"}
        borderRadius={"12px"}
        fontSize={"2rem"}
        color="black"
      >
        {icon}
      </Box>
      <Typography
        ml="20px"
        variant="h2"
        color="#63607D"
        fontSize={"2rem"}
        textAlign={"left"}
        fontWeight="bold"
        lineHeight={"3rem"}
      >
        {text}
      </Typography>
    </Box>
  );
};

const Highlights = () => {
  return (
    <>
      <Box component={"img"} src={compsvg} alt="Empowr" mx="auto" pl={2} />
    </>
  );
};

export default Highlights;
