import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import daa_1 from "../../assets/images/services_1.svg";
import daa_2 from "../../assets/images/services_2.jpg";
import daa_3 from "../../assets/images/services_3.jpg";
import daa_4 from "../../assets/images/services_4.svg";
import daa_5 from "../../assets/images/services_5.svg";
import {
  commonStyleLeftImageHeader,
  commonStyleLeftTextPara,
  commonStyleRightImageHeader,
  commonStyleRightTextPara,
} from "./LandingConstants";
import styles from "../../styles/internal/Landing/BaseStyling.module.css";
import topBrands from "../../assets/images/services_used.png";
import Testimonials from "./Testimonials";

function Services() {
  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      {/* Header */}
      <div className={styles.headerBox}>
        <Box className={styles.topText}>Leverage Influencer Marketing without breaking a Sweat</Box>
        <Box className={styles.title} sx={{ fontSize: { xs: "30px !important", md: "40px !important", lg: "60px !important" } }}>
          <p>
            Achieve Your Goals <br />
            with our Managed Campaign Services
          </p>
        </Box>
        <div className={styles.subTitle}>We will manage every aspect of your Influencer Campaigns</div>
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <Button
            variant="contained"
            sx={{
              borderRadius: "56px",
              height: "60px",
              width: "190px",
              marginInline: "auto",
              fontSize: "20px",
              color: "#FFFFFF",
              backgroundColor: "#464E5F",
              "&:hover": {
                backgroundColor: "#2a2f39",
                color: "#FFFFFF",
              },
            }}
            onClick={() => navigate("/pricing")}
          >
            Let's Connect
          </Button>
        </div>
      </div>
      <br />
      <div className={styles.footer}>
        <div className={styles.footerTitle}>Brands that have trusted Empowr.ai</div>
        <img src={topBrands} alt="trusted orgaizations" style={{ padding: "45px" }} />
      </div>
      {/* Left Text and Right Image */}
      <div className={styles.leftTextDiv}>
        <Grid container spacing={2}>
          <Grid
            item
            md={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={10}
                style={{
                  ...commonStyleLeftImageHeader,
                  // paddingLeft: "10vw",
                }}
                sx={{ fontSize: { xs: "30px !important", md: "40px !important" }, pl: { xs: 0, md: "10vw" } }}
              >
                Tell us your campaign goals and decide on deliverables{" "}
              </Grid>
              <Grid
                item
                xs={9}
                style={{
                  ...commonStyleLeftTextPara,
                  // paddingLeft: "10vw",
                  marginTop: "16px",
                }}
                sx={{ fontSize: { xs: "12px !important", md: "16px !important" }, pl: { xs: 0, md: "10vw" } }}
              >
                Our team deep-dives wih you to explore your campaign objectives and concepts. Discover first-hand how our AI
                powered technology streamlines the influencer marketing process, ensuring a seamless experience for you. Based on
                the discussions, we'll craft a proposal for your approval before initiating the project. Every organization has
                different influencer marketing budgets and strategies, so we offer a flexible pricing model tailored to your
                needs.
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} style={{ textAlign: "center" }}>
            <img src={daa_1} alt="logo" height="90%" />
          </Grid>
        </Grid>
      </div>
      {/* Left Image and Right text */}
      <div className={styles.rightTextDiv}>
        <Grid container spacing={2}>
          <Grid item md={6} style={{ textAlign: "center" }}>
            <img src={daa_2} alt="logo" style={{ width: "400px", height: "350px" }} />
          </Grid>
          <Grid
            item
            md={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={10}
                style={{
                  ...commonStyleRightImageHeader,
                }}
                sx={{ fontSize: { xs: "30px !important", md: "40px !important" }, pl: { xs: "0 !important", md: "18px" } }}
              >
                AI takes the first step with our team chugging along
              </Grid>
              <Grid
                item
                xs={9}
                style={{
                  ...commonStyleRightTextPara,
                  marginTop: "16px",
                }}
                sx={{ fontSize: { xs: "12px !important", md: "16px !important" }, pl: { xs: "0 !important", md: "20px" } }}
              >
                Based on your campaign brief and objectives, we leverage AI to zero in on the most suitable influencers that will
                help achieve the objective.
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {/* Left Text and Right Image */}
      <div className={styles.leftTextDiv}>
        <Grid container spacing={2}>
          <Grid
            item
            md={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={10}
                style={{
                  ...commonStyleLeftImageHeader,
                  // paddingLeft: "10vw",
                }}
                sx={{ fontSize: { xs: "30px !important", md: "40px !important" }, pl: { xs: 0, md: "10vw" } }}
              >
                Influencers on-boarded and briefed
              </Grid>
              <Grid
                item
                xs={9}
                style={{
                  ...commonStyleLeftTextPara,
                  // paddingLeft: "10vw",
                  marginTop: "16px",
                }}
                sx={{ fontSize: { xs: "12px !important", md: "16px !important" }, pl: { xs: 0, md: "10vw" } }}
              >
                We digitally on-boarding and contract influencers from any part of the world. After on-line briefings and brand
                immersion, they are ready to execute.
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} style={{ textAlign: "center" }}>
            <img src={daa_3} alt="logo" style={{ width: "400px", height: "350px" }} />
          </Grid>
        </Grid>
      </div>
      {/* Left Image and Right text */}
      <div className={styles.rightTextDiv}>
        <Grid container spacing={2}>
          <Grid item md={6} style={{ textAlign: "center" }}>
            <img src={daa_4} alt="logo" height="90%" />
          </Grid>
          <Grid
            item
            md={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={10}
                style={{
                  ...commonStyleRightImageHeader,
                }}
                sx={{ fontSize: { xs: "30px !important", md: "40px !important" }, pl: { xs: "0 !important", md: "18px" } }}
              >
                Our team moderates Publications, you can too
              </Grid>
              <Grid
                item
                xs={9}
                style={{
                  ...commonStyleRightTextPara,
                  marginTop: "16px",
                }}
                sx={{ fontSize: { xs: "12px !important", md: "16px !important" }, pl: { xs: "0 !important", md: "20px" } }}
              >
                The influencer content review and publication is streamlined process. You as the client can review drafts, suggest
                changes. We also leverage AI driven auto alerts which speeds up the process.{" "}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {/* Left Text and Right Image */}
      <div className={styles.leftTextDiv}>
        <Grid container spacing={2}>
          <Grid
            item
            md={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={10}
                style={{
                  ...commonStyleLeftImageHeader,
                  // paddingLeft: "10vw",
                }}
                sx={{ fontSize: { xs: "30px !important", md: "40px !important" }, pl: { xs: 0, md: "10vw" } }}
              >
                See Real-Time Campaign Analytics
              </Grid>
              <Grid
                item
                xs={9}
                style={{
                  ...commonStyleLeftTextPara,
                  // paddingLeft: "10vw",
                  marginTop: "16px",
                }}
                sx={{ fontSize: { xs: "12px !important", md: "16px !important" }, pl: { xs: 0, md: "10vw" } }}
              >
                You have complete control over Campaign date and metrics. Slide & Dice the way you want, export, create reports
                on-demand. Our team also shares add-on weekly and EOC report.
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} style={{ textAlign: "center" }}>
            <img src={daa_5} alt="logo" height="90%" />
          </Grid>
        </Grid>
      </div>
      <Testimonials />
    </div>
  );
}

export default Services;
