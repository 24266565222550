import React, { useEffect } from "react";
import { Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getCreditInfo, getPlansInfo } from "../../services/credit";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
  },
  tableContainer: {
    border: "none", // Remove the border from the table container
  },
  table: {
    border: "none", // Remove the border from the table
  },
  tableCell: {
    border: "none", // Remove the bottom border from the table cells
    fontSize: "14px",
    fontWeight: 500,
  },
  tableCellTitle: {
    border: "none", // Remove the bottom border from the table cells
    fontSize: "14px",
    fontWeight: "bold",
  },
  tableRowReports: {
    "& .MuiTableCell-root": {
      fontWeight: "bold",
      fontSize: "16px",
    },
  },
}));
const CreditLedger = () => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const classes = useStyles();
  const [freePlanInfo, setFreePlanInfo] = React.useState({});
  const [starterPlanInfo, setStarterPlanInfo] = React.useState({});
  const [professionalPlanInfo, setProfessionalPlanInfo] = React.useState({});
  const [businessPlanInfo, setBusinesslanInfo] = React.useState({});
  const [creditInfo, setCreditInfo] = React.useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    getAllPlans();
    getCreditInfoForPlans();
  }, []);

  const getAllPlans = async () => {
    const plans = [2, 3, 6, 12];
    const data = {
      planIds: plans,
    };
    const planInfo = await getPlansInfo(data, user, dispatch);
    if (planInfo.status) {
      setFreePlanInfo(planInfo.response[12]);
      setStarterPlanInfo(planInfo.response[6]);
      setProfessionalPlanInfo(planInfo.response[2]);
      setBusinesslanInfo(planInfo.response[3]);
    }
  };
  const getCreditInfoForPlans = async () => {
    const data = {
      accountId: user.agencyId,
      clientId: user.clientId,
    };
    const creditInfo = await getCreditInfo(data, user, dispatch);
    if (creditInfo.status) {
      setCreditInfo(creditInfo.response);
    }
  };
  return (
    <Paper elevation={3} className={classes.root}>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.tableRowReports}>
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell className={classes.tableCell}>
                Free {creditInfo["planid"] === 12 && <span style={{ textAlign: "left", color: "red" }}>( Current Plan )</span>}
              </TableCell>
              <TableCell className={classes.tableCell}>
                Starter {creditInfo["planid"] === 6 && <span style={{ textAlign: "left", color: "red" }}>( Current Plan )</span>}
              </TableCell>
              <TableCell className={classes.tableCell}>
                Professional{" "}
                {creditInfo["planid"] === 2 && <span style={{ textAlign: "left", color: "red" }}>( Current Plan )</span>}
              </TableCell>
              <TableCell className={classes.tableCell}>
                Business {creditInfo["planid"] === 3 && <span style={{ textAlign: "left", color: "red" }}>( Current Plan )</span>}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              <TableRow
                className={classes.tableRowReports}
                style={{ backgroundColor: "rgb(54,123,245,0.2)", fontWeight: "bold" }}
              >
                <TableCell className={classes.tableCell}>Users</TableCell>
                <TableCell className={classes.tableCell}></TableCell>
                <TableCell className={classes.tableCell}></TableCell>
                <TableCell className={classes.tableCell}></TableCell>
                <TableCell className={classes.tableCell}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCellTitle}>Invite an user</TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    Up to {freePlanInfo["Account"]?.["Invite New Users"]?.["CreditsAvailable"]} users
                  </p>
                  {creditInfo["planid"] === 12 && (
                    <p style={{ textAlign: "left", color: "blue" }}>
                      {creditInfo["Account"]?.["Invite New Users"]?.["CreditsAvailable"]} remaining
                    </p>
                  )}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    Up to {starterPlanInfo["Account"]?.["Invite New Users"]?.["CreditsAvailable"]} users
                  </p>
                  {creditInfo["planid"] === 6 && (
                    <p style={{ textAlign: "left", color: "blue" }}>
                      {creditInfo["Account"]?.["Invite New Users"]?.["CreditsAvailable"]} remaining
                    </p>
                  )}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    Up to {professionalPlanInfo["Account"]?.["Invite New Users"]?.["CreditsAvailable"]} users
                  </p>
                  {creditInfo["planid"] === 2 && (
                    <p style={{ textAlign: "left", color: "blue" }}>
                      {creditInfo["Account"]?.["Invite New Users"]?.["CreditsAvailable"]} remaining
                    </p>
                  )}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {businessPlanInfo["Account"]?.["Invite New Users"]?.["CreditsAvailable"]} users
                  </p>
                  {creditInfo["planid"] === 3 && (
                    <p style={{ textAlign: "left", color: "blue" }}>
                      {creditInfo["Account"]?.["Invite New Users"]?.["CreditsAvailable"]} remaining
                    </p>
                  )}
                </TableCell>
              </TableRow>
            </>
            <>
              <TableRow
                className={classes.tableRowReports}
                style={{ backgroundColor: "rgb(54,123,245,0.2)", fontWeight: "bold" }}
              >
                <TableCell className={classes.tableCell}>Discover Creators</TableCell>
                <TableCell className={classes.tableCell}></TableCell>
                <TableCell className={classes.tableCell}></TableCell>
                <TableCell className={classes.tableCell}></TableCell>
                <TableCell className={classes.tableCell}></TableCell>

              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCellTitle}>Search results (profile)</TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {freePlanInfo["Discovery"]?.["Discover"]?.["CreditsAvailable"]} users
                  </p>
                  {creditInfo["planid"] === 12 && (
                    <p style={{ textAlign: "left", color: "blue" }}>
                      {creditInfo["Discovery"]?.["Discover"]?.["CreditsAvailable"]} remaining
                    </p>
                  )}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {" "}
                    <p style={{ textAlign: "left", color: "gray" }}>
                      {starterPlanInfo["Discovery"]?.["Discover"]?.["CreditsAvailable"]} users
                    </p>
                  </p>
                  {creditInfo["planid"] === 6 && (
                    <p style={{ textAlign: "left", color: "blue" }}>
                      {creditInfo["Discovery"]?.["Discover"]?.["CreditsAvailable"]} remaining
                    </p>
                  )}{" "}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {" "}
                    <p style={{ textAlign: "left", color: "gray" }}>
                      {professionalPlanInfo["Discovery"]?.["Discover"]?.["CreditsAvailable"]} users
                    </p>
                  </p>
                  {creditInfo["planid"] === 2 && (
                    <p style={{ textAlign: "left", color: "blue" }}>
                      {creditInfo["Discovery"]?.["Discover"]?.["CreditsAvailable"]} remaining
                    </p>
                  )}{" "}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {" "}
                    <p style={{ textAlign: "left", color: "gray" }}>
                      {businessPlanInfo["Discovery"]?.["Discover"]?.["CreditsAvailable"]} users
                    </p>
                  </p>
                  {creditInfo["planid"] === 3 && (
                    <p style={{ textAlign: "left", color: "blue" }}>
                      {creditInfo["Discovery"]?.["Discover"]?.["CreditsAvailable"]} remaining
                    </p>
                  )}{" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCellTitle}>Profile Analyses</TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {freePlanInfo["Discovery"]?.["Analyze"]?.["CreditsAvailable"]} users
                  </p>
                  {creditInfo["planid"] === 12 && (
                    <p style={{ textAlign: "left", color: "blue" }}>
                      {creditInfo["Discovery"]?.["Analyze"]?.["CreditsAvailable"]} remaining
                    </p>
                  )}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {" "}
                    <p style={{ textAlign: "left", color: "gray" }}>
                      {starterPlanInfo["Discovery"]?.["Analyze"]?.["CreditsAvailable"]} users
                    </p>
                  </p>
                  {creditInfo["planid"] === 6 && (
                    <p style={{ textAlign: "left", color: "blue" }}>
                      {creditInfo["Discovery"]?.["Analyze"]?.["CreditsAvailable"]} remaining
                    </p>
                  )}{" "}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {" "}
                    <p style={{ textAlign: "left", color: "gray" }}>
                      {professionalPlanInfo["Discovery"]?.["Analyze"]?.["CreditsAvailable"]} users
                    </p>
                  </p>
                  {creditInfo["planid"] === 2 && (
                    <p style={{ textAlign: "left", color: "blue" }}>
                      {creditInfo["Discovery"]?.["Analyze"]?.["CreditsAvailable"]} remaining
                    </p>
                  )}{" "}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {" "}
                    <p style={{ textAlign: "left", color: "gray" }}>
                      {businessPlanInfo["Discovery"]?.["Analyze"]?.["CreditsAvailable"]} users
                    </p>
                  </p>
                  {creditInfo["planid"] === 3 && (
                    <p style={{ textAlign: "left", color: "blue" }}>
                      {creditInfo["Discovery"]?.["Analyze"]?.["CreditsAvailable"]} remaining
                    </p>
                  )}{" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCellTitle}>Export report to Excel/PDF</TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {freePlanInfo["Discovery"]?.["Analyze"]?.["CreditsAvailable"]} users
                  </p>
                  {creditInfo["planid"] === 12 && (
                    <p style={{ textAlign: "left", color: "blue" }}>
                      {creditInfo["Discovery"]?.["Analyze"]?.["CreditsAvailable"]} remaining
                    </p>
                  )}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {" "}
                    <p style={{ textAlign: "left", color: "gray" }}>
                      {starterPlanInfo["Discovery"]?.["Analyze"]?.["CreditsAvailable"]} users
                    </p>
                  </p>
                  {creditInfo["planid"] === 6 && (
                    <p style={{ textAlign: "left", color: "blue" }}>
                      {creditInfo["Discovery"]?.["Analyze"]?.["CreditsAvailable"]} remaining
                    </p>
                  )}{" "}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {" "}
                    <p style={{ textAlign: "left", color: "gray" }}>
                      {professionalPlanInfo["Discovery"]?.["Analyze"]?.["CreditsAvailable"]} users
                    </p>
                  </p>
                  {creditInfo["planid"] === 2 && (
                    <p style={{ textAlign: "left", color: "blue" }}>
                      {creditInfo["Discovery"]?.["Analyze"]?.["CreditsAvailable"]} remaining
                    </p>
                  )}{" "}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {" "}
                    <p style={{ textAlign: "left", color: "gray" }}>
                      {businessPlanInfo["Discovery"]?.["Analyze"]?.["CreditsAvailable"]} users
                    </p>
                  </p>
                  {creditInfo["planid"] === 3 && (
                    <p style={{ textAlign: "left", color: "blue" }}>
                      {creditInfo["Discovery"]?.["Analyze"]?.["CreditsAvailable"]} remaining
                    </p>
                  )}{" "}
                </TableCell>
              </TableRow>
            </>
            <>
              <TableRow className={classes.tableRowReports} style={{ backgroundColor: "rgb(54,123,245,0.2)" }}>
                <TableCell className={classes.tableCell}>Campaign</TableCell>
                <TableCell className={classes.tableCell}></TableCell>
                <TableCell className={classes.tableCell}></TableCell>
                <TableCell className={classes.tableCell}></TableCell>
                <TableCell className={classes.tableCell}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCellTitle}>Active Campaigns</TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>{freePlanInfo["Campaigns"]?.["CreditsAvailable"]} campaign</p>
                  {creditInfo["planid"] === 12 && (
                    <p style={{ textAlign: "left", color: "blue" }}>{creditInfo["Campaigns"]?.["CreditsAvailable"]} remaining</p>
                  )}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {" "}
                    <p style={{ textAlign: "left", color: "gray" }}>
                      {starterPlanInfo["Campaigns"]?.["CreditsAvailable"]} campaigns
                    </p>
                  </p>
                  {creditInfo["planid"] === 6 && (
                    <p style={{ textAlign: "left", color: "blue" }}>{creditInfo["Campaigns"]?.["CreditsAvailable"]} remaining</p>
                  )}{" "}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {" "}
                    <p style={{ textAlign: "left", color: "gray" }}>
                      {professionalPlanInfo["Campaigns"]?.["CreditsAvailable"]} campaigns
                    </p>
                  </p>
                  {creditInfo["planid"] === 2 && (
                    <p style={{ textAlign: "left", color: "blue" }}>{creditInfo["Campaigns"]?.["CreditsAvailable"]} remaining</p>
                  )}{" "}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {" "}
                    <p style={{ textAlign: "left", color: "gray" }}>
                      {businessPlanInfo["Campaigns"]?.["CreditsAvailable"]} campaigns
                    </p>
                  </p>
                  {creditInfo["planid"] === 3 && (
                    <p style={{ textAlign: "left", color: "blue" }}>{creditInfo["Campaigns"]?.["CreditsAvailable"]} remaining</p>
                  )}{" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCellTitle}>Total Publications</TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {freePlanInfo["Campaigns"]?.["Fields"]?.["Publications"]?.["CreditsAvailable"]} posts
                  </p>
                  {creditInfo["planid"] === 12 && (
                    <p style={{ textAlign: "left", color: "blue" }}>
                      {creditInfo["Campaigns"]?.["Fields"]?.["Publications"]?.["CreditsAvailable"]} remaining
                    </p>
                  )}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {" "}
                    <p style={{ textAlign: "left", color: "gray" }}>
                      {starterPlanInfo["Campaigns"]?.["Fields"]?.["Publications"]?.["CreditsAvailable"]} publications
                    </p>
                  </p>
                  {creditInfo["planid"] === 6 && (
                    <p style={{ textAlign: "left", color: "blue" }}>
                      {creditInfo["Campaigns"]?.["Fields"]?.["Publications"]?.["CreditsAvailable"]} remaining
                    </p>
                  )}{" "}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {" "}
                    <p style={{ textAlign: "left", color: "gray" }}>
                      {professionalPlanInfo["Campaigns"]?.["Fields"]?.["Publications"]?.["CreditsAvailable"]} publications
                    </p>
                  </p>
                  {creditInfo["planid"] === 2 && (
                    <p style={{ textAlign: "left", color: "blue" }}>
                      {creditInfo["Campaigns"]?.["Fields"]?.["Publications"]?.["CreditsAvailable"]} remaining
                    </p>
                  )}{" "}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {" "}
                    <p style={{ textAlign: "left", color: "gray" }}>
                      {businessPlanInfo["Campaigns"]?.["Fields"]?.["Publications"]?.["CreditsAvailable"]} publications
                    </p>
                  </p>
                  {creditInfo["planid"] === 3 && (
                    <p style={{ textAlign: "left", color: "blue" }}>
                      {creditInfo["Campaigns"]?.["Fields"]?.["Publications"]?.["CreditsAvailable"]} remaining
                    </p>
                  )}{" "}
                </TableCell>
              </TableRow>
            </>
            <>
              <TableRow className={classes.tableRowReports} style={{ backgroundColor: "rgb(54,123,245,0.2)" }}>
                <TableCell className={classes.tableCell}>Manage Creators</TableCell>
                <TableCell className={classes.tableCell}></TableCell>
                <TableCell className={classes.tableCell}></TableCell>
                <TableCell className={classes.tableCell}></TableCell>
                <TableCell className={classes.tableCell}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCellTitle}>Total Influencers</TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {freePlanInfo["Campaigns"]?.["Fields"]?.["Influencers"]?.["CreditsAvailable"]} influencers
                  </p>
                  {creditInfo["planid"] === 12 && (
                    <p style={{ textAlign: "left", color: "blue" }}>
                      {creditInfo["Campaigns"]?.["Fields"]?.["Influencers"]?.["CreditsAvailable"]} remaining
                    </p>
                  )}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {" "}
                    <p style={{ textAlign: "left", color: "gray" }}>
                      {starterPlanInfo["Campaigns"]?.["Fields"]?.["Influencers"]?.["CreditsAvailable"]} influencers
                    </p>
                  </p>
                  {creditInfo["planid"] === 6 && (
                    <p style={{ textAlign: "left", color: "blue" }}>
                      {creditInfo["Campaigns"]?.["Fields"]?.["Influencers"]?.["CreditsAvailable"]} remaining
                    </p>
                  )}{" "}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {" "}
                    <p style={{ textAlign: "left", color: "gray" }}>
                      {professionalPlanInfo["Campaigns"]?.["Fields"]?.["Influencers"]?.["CreditsAvailable"]} influencers
                    </p>
                  </p>
                  {creditInfo["planid"] === 2 && (
                    <p style={{ textAlign: "left", color: "blue" }}>
                      {creditInfo["Campaigns"]?.["Fields"]?.["Influencers"]?.["CreditsAvailable"]} remaining
                    </p>
                  )}{" "}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {" "}
                    <p style={{ textAlign: "left", color: "gray" }}>
                      {businessPlanInfo["Campaigns"]?.["Fields"]?.["Influencers"]?.["CreditsAvailable"]} influencers
                    </p>
                  </p>
                  {creditInfo["planid"] === 3 && (
                    <p style={{ textAlign: "left", color: "blue" }}>
                      {creditInfo["Campaigns"]?.["Fields"]?.["Influencers"]?.["CreditsAvailable"]} remaining
                    </p>
                  )}{" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCellTitle}>Total Groups</TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {freePlanInfo["Campaigns"]?.["Fields"]?.["Groups"]?.["CreditsAvailable"]} groups
                  </p>
                  {creditInfo["planid"] === 12 && (
                    <p style={{ textAlign: "left", color: "blue" }}>
                      {creditInfo["Campaigns"]?.["Fields"]?.["Groups"]?.["CreditsAvailable"]} remaining
                    </p>
                  )}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {" "}
                    <p style={{ textAlign: "left", color: "gray" }}>
                      {starterPlanInfo["Campaigns"]?.["Fields"]?.["Groups"]?.["CreditsAvailable"]} groups
                    </p>
                  </p>
                  {creditInfo["planid"] === 6 && (
                    <p style={{ textAlign: "left", color: "blue" }}>
                      {creditInfo["Campaigns"]?.["Fields"]?.["Groups"]?.["CreditsAvailable"]} remaining
                    </p>
                  )}{" "}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {" "}
                    <p style={{ textAlign: "left", color: "gray" }}>
                      {professionalPlanInfo["Campaigns"]?.["Fields"]?.["Groups"]?.["CreditsAvailable"]} groups
                    </p>
                  </p>
                  {creditInfo["planid"] === 2 && (
                    <p style={{ textAlign: "left", color: "blue" }}>
                      {creditInfo["Campaigns"]?.["Fields"]?.["Groups"]?.["CreditsAvailable"]} remaining
                    </p>
                  )}{" "}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {" "}
                    <p style={{ textAlign: "left", color: "gray" }}>
                      {businessPlanInfo["Campaigns"]?.["Fields"]?.["Groups"]?.["CreditsAvailable"]} groups
                    </p>
                  </p>
                  {creditInfo["planid"] === 3 && (
                    <p style={{ textAlign: "left", color: "blue" }}>
                      {creditInfo["Campaigns"]?.["Fields"]?.["Groups"]?.["CreditsAvailable"]} remaining
                    </p>
                  )}{" "}
                </TableCell>
              </TableRow>
            </>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default CreditLedger;
