import React, { useState, useMemo } from "react";
import { Grid, Card, CardContent, Typography, Box, Select, MenuItem, Button, Link, Tooltip } from "@mui/material";
import { Favorite, VideogameAsset, People, PieChart, ThumbUp, Comment, Share } from '@mui/icons-material';
import { convertToInternationalNumberSystem } from "../../utility/stringOperations";
import { snapshotHeaderIcons, textColor } from '../../utility/plaformIcons';
import { HomeSplitChart } from "../common/chart";
import DateRangeDropdown from "./common/HomeDateRangeDropdown";
import ReactEcharts from "echarts-for-react";
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import MetricsCard from "./metricCard";
import CampaignList from "./RecentList";
import ReportList from "./RecentReports";

const DashboardMetrics = React.memo(({
    basicDetails = {},
    influencerDetails = {},
    publicationDetails = {},
    analytics = {},
    campaignList = [],
    demoCampaign = null,
    topPosts = [],
    reportHistory = [],
    demoReport = null,
    selectedInfluencerDateRange,
    onInfluencerDateChange,
    selectedPublicationDateRange,
    onPublicationDateChange,
    selectedAnalyticsDateRange = "Last 6 Months",
    onAnalyticsDateChange,
}) => {
    const [showAllPosts, setShowAllPosts] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    const engagementSplit = useMemo(() => [
        { value: analytics?.conversion || 0, name: 'Conversions' },
        { value: analytics?.engagement || 0, name: 'Engagement' },
        { value: analytics?.reach || 0, name: 'Reach' }
    ], [analytics]);

    const engagementColorArray = ["#FFCC91", "#97A5EB", "#5570F1"];
    const handleInfluencerDateChange = (dateRange) => {
        setIsFirstLoad(false);
        onInfluencerDateChange(dateRange);
    };

    const handlePublicationDateChange = (dateRange) => {
        setIsFirstLoad(false);
        onPublicationDateChange(dateRange);
    };

    return (
        <Grid container spacing={2} mt={3}>
            <Grid item xs={12} sm={8}>
                {/* Basic Details */}
                <Grid container spacing={2} sx={{ paddingLeft: '16px' }}>
                    <Grid item xs={12} sm={4}>
                        <Card sx={{ borderRadius: '10px' }}>
                            <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{
                                    backgroundColor: '#EEF0FA',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '64px',
                                    height: '64px',
                                    marginRight: '16px'
                                }}>
                                    <Favorite sx={{ color: '#5570F1', fontSize: 30 }} />
                                </Box>
                                <Box>
                                    <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#45464E' }}>
                                        {basicDetails.liveCampaigns || 0}
                                    </Typography>
                                    <Typography variant="subtitle1">Live Campaigns</Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Card sx={{ borderRadius: '10px' }}>
                            <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{
                                    backgroundColor: '#FFF7E5',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '64px',
                                    height: '64px',
                                    marginRight: '16px'
                                }}>
                                    <VideogameAsset sx={{ color: '#F5B041', fontSize: 30 }} />
                                </Box>
                                <Box>
                                    <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#45464E' }}>
                                        {basicDetails.activeCreators || 0}
                                    </Typography>
                                    <Typography variant="subtitle1">Active Creators</Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Card sx={{ borderRadius: '10px' }}>
                            <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{
                                    backgroundColor: '#EEF0FA',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '64px',
                                    height: '64px',
                                    marginRight: '16px'
                                }}>
                                    <BusinessCenterIcon sx={{ color: '#5570F1', fontSize: 30 }} />
                                </Box>
                                <Box>
                                    <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#45464E' }}>
                                        {basicDetails.activePublications || 0}
                                    </Typography>
                                    <Typography variant="subtitle1">Total Publications</Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Influencer Details */}
                    <Grid item xs={12} sm={6}>
                        {(isFirstLoad && influencerDetails?.totalCreators) || !isFirstLoad ? (
                            <MetricsCard
                                icon={<People sx={{ color: '#FF6F61', fontSize: 24 }} />}
                                title="Influencers"
                                data={{
                                    total: influencerDetails?.totalCreators || 0,
                                    totalChange: '',
                                    active: influencerDetails?.activeCreators || 0,
                                    activePercentage: '',
                                    contracted: influencerDetails?.contractedCreators || 0,
                                    contractedPercentage: '',
                                }}
                                selectedDateRange={selectedInfluencerDateRange}
                                onDateChange={handleInfluencerDateChange}
                                showDateRange
                            />
                        ) : null}
                    </Grid>

                    {/* Publication Details */}
                    <Grid item xs={12} sm={6}>
                        {((isFirstLoad && publicationDetails?.draftsSubmitted) || !isFirstLoad || influencerDetails?.totalCreators > 0) ? (
                            <MetricsCard
                                icon={<PieChart sx={{ color: '#4C88FF', fontSize: 24 }} />}
                                title="Publications"
                                data={{
                                    total: publicationDetails?.draftsSubmitted || 0,
                                    totalChange: '',
                                    active: publicationDetails?.draftsApproved || 0,
                                    activePercentage: '',
                                    contracted: publicationDetails?.draftsPublished || 0,
                                    contractedPercentage: '',
                                }}
                                selectedDateRange={selectedPublicationDateRange}
                                onDateChange={handlePublicationDateChange}
                                showDateRange
                                labels={{
                                    total: 'Submitted',
                                    active: 'Approved',
                                    contracted: 'Published'
                                }}
                            />
                        ) : null}
                    </Grid>
                </Grid>

                {/* Recent or Demo Campaigns */}
                <Grid item xs={12} sx={{ paddingLeft: '16px' }}>
                    <CampaignList
                        title={campaignList.length > 0 ? "Recent Campaigns" : "Demo Campaigns"}
                        data={campaignList.length > 0 ? campaignList : [demoCampaign]}
                    />
                </Grid>

                {/* Reports */}
                <Grid item xs={12} sx={{ paddingLeft: '16px' }}>
                    {reportHistory.length > 0 || demoReport ? (
                        <ReportList
                            title={reportHistory.length > 0 ? "Recent Reports" : "Demo Reports"}
                            data={reportHistory.length > 0 ? reportHistory : [demoReport]}
                        />
                    ) : null}
                </Grid>
            </Grid>

            <Grid item xs={12} sm={4}>
                {/* Analytics */}
                <Card sx={{ borderRadius: '10px', marginBottom: '16px' }}>
                    <CardContent sx={{ paddingBottom: 0 }}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Analytics</Typography>
                            <DateRangeDropdown
                                defaultDateRange={selectedAnalyticsDateRange}
                                onDateChange={onAnalyticsDateChange}
                            />
                        </Box>
                        <ReactEcharts
                            option={HomeSplitChart(
                                "Engagement Split",
                                engagementColorArray,
                                "horizontal",
                                "center",
                                10,
                                engagementSplit,
                                ["30%", "60%"],
                                true
                            )}
                            style={{ height: "400px", width: "100%", borderRadius: 10 }}
                        />
                    </CardContent>
                </Card>

                {/* Top Posts */}
                {topPosts && topPosts.length > 0 && (
                    <Card sx={{ borderRadius: '10px' }}>
                        <CardContent>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>Top Posts</Typography>
                            {topPosts.map((post) => {
                                const maxNameLength = 18;

                                // Display influencer if available, otherwise display URL truncated
                                const displayName = post.influencer && post.influencer.length > 0
                                    ? (post.influencer.length > maxNameLength
                                        ? `${post.influencer.substring(0, maxNameLength)}...`
                                        : post.influencer)
                                    : (post.posturl.length > maxNameLength
                                        ? `${post.posturl.substring(0, maxNameLength)}...`
                                        : post.posturl);

                                return (
                                    <Box key={post.postId} mb={2} display="flex" alignItems="center" justifyContent="space-between">
                                        {/* Display influencer name, platform icon, or truncated URL */}
                                        <Box display="flex" flexDirection="row" alignItems="center" sx={{ minWidth: '150px' }}>
                                            {post.platform && (
                                                <Box sx={{ mr: 1, display: 'flex', alignItems: 'center', color: textColor[post.platform.toLowerCase()] }}>
                                                    {snapshotHeaderIcons[post.platform.toLowerCase()]
                                                        ? React.cloneElement(snapshotHeaderIcons[post.platform.toLowerCase()], { style: { fontSize: '16px' } })
                                                        : null}
                                                </Box>
                                            )}
                                            <Tooltip title={post.posturl} arrow>
                                                <Link
                                                    href={post.posturl}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    sx={{
                                                        fontWeight: 'bold',
                                                        fontSize: '14px',
                                                        textDecoration: 'none',
                                                        maxWidth: '150px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        color: 'inherit'
                                                    }}
                                                >
                                                    {displayName}
                                                </Link>
                                            </Tooltip>
                                        </Box>

                                        {/* Icons with counts on the right, aligned horizontally */}
                                        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ flexGrow: 1, ml: 2 }}>
                                            <Box display="flex" alignItems="center" sx={{ minWidth: '80px' }}>
                                                <ThumbUp sx={{ fontSize: '18px', color: '#555' }} />
                                                <Typography variant="body2" sx={{ ml: 1 }}>{convertToInternationalNumberSystem(post.likes)}</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center" sx={{ minWidth: '80px' }}>
                                                <Comment sx={{ fontSize: '18px', color: '#555' }} />
                                                <Typography variant="body2" sx={{ ml: 1 }}>{convertToInternationalNumberSystem(post.comments || post.reply)}</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center" sx={{ minWidth: '80px' }}>
                                                <Share sx={{ fontSize: '18px', color: '#555' }} />
                                                <Typography variant="body2" sx={{ ml: 1 }}>{convertToInternationalNumberSystem(post.shares || post.retweet)}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                );
                            })}
                        </CardContent>
                    </Card>
                )}
            </Grid>
        </Grid>
    );
});

export default DashboardMetrics;
