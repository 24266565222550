import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import about1 from "../../assets/images/about_1.svg";
import about2 from "../../assets/images/about_2.svg";
import {
  commonStyleLeftImageHeader,
  commonStyleLeftTextPara,
  commonStyleRightImageHeader,
  commonStyleRightTextPara,
} from "./LandingConstants";
import styles from "../../styles/internal/Landing/BaseStyling.module.css";
import topBrands from "../../assets/images/services_used.png";
import Testimonials from "./Testimonials";

function About() {
  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      {/* Header */}
      <div className={styles.headerBox}>
        <Box className={styles.topText}>About Empowr.ai</Box>
        <Box className={styles.title} sx={{ fontSize: { xs: "30px !important", md: "40px !important", lg: "60px !important" } }}>
          <p>
            We trigger authentic conversations <br />
            transforming your messaging & results
          </p>
        </Box>
        <div className={styles.subTitle}>#1 CREATOR MARKETING PLATFORM</div>
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <Button
            variant="contained"
            sx={{
              borderRadius: "56px",
              height: "60px",
              width: "190px",
              marginInline: "auto",
              fontSize: "20px",
              color: "#FFFFFF",
              backgroundColor: "#464E5F",
              "&:hover": {
                backgroundColor: "#2a2f39",
                color: "#FFFFFF",
              },
            }}
            onClick={() => navigate("/pricing")}
          >
            Let's Connect
          </Button>
        </div>
      </div>
      <br />
      <div className={styles.footer}>
        <div className={styles.footerTitle}>Our Story!</div>
        <div className={styles.subTitle}>
          Believe it ot not, Aifluence and our platform Empwor.ai was born in bar. The strategies and approach we use today were
          tried first hand in elevating a new whiskey brand from zero to top-selling whiskey brand in Kenya in just 3 months. Fast
          forward a few months and we launched Aifluence with the idea of enabling any brand to benefit from same approach through
          our products.{" "}
        </div>
      </div>
      {/* Left Text and Right Image */}
      <div className={styles.leftTextDiv}>
        <Grid container spacing={2}>
          <Grid
            item
            md={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={10}
                style={{
                  ...commonStyleLeftImageHeader,
                  // paddingLeft: "10vw",
                }}
                sx={{ fontSize: { xs: "30px !important", md: "40px !important" }, pl: { xs: 0, md: "10vw" } }}
              >
                Our Philosophy
              </Grid>
              <Grid
                item
                xs={9}
                style={{
                  ...commonStyleLeftTextPara,
                  // paddingLeft: "10vw",
                  marginTop: "16px",
                }}
                sx={{ fontSize: { xs: "12px !important", md: "16px !important" }, pl: { xs: 0, md: "10vw" } }}
              >
                We believe its time for brands to get more authentic and real in the way we promote ourselves. “Word of Mouth” - a
                better form of marketing never existed and creator marketing is as close as we can get to orchestrating it.
                Exactly what Empowr.ai has been built to do.
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} style={{ textAlign: "center" }}>
            <img src={about1} alt="logo" height="90%" />
          </Grid>
        </Grid>
      </div>
      {/* Left Image and Right text */}
      <div className={styles.rightTextDiv}>
        <Grid container spacing={2}>
          <Grid item md={6} style={{ textAlign: "center" }}>
            <img src={about2} alt="logo" height="90%" />
          </Grid>
          <Grid
            item
            md={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={10}
                style={{
                  ...commonStyleRightImageHeader,
                }}
                sx={{ fontSize: { xs: "30px !important", md: "40px !important" }, pl: { xs: "0 !important", md: "18px" } }}
              >
                Why Choose Empowr.ai
              </Grid>
              <Grid
                item
                xs={9}
                style={{
                  ...commonStyleRightTextPara,
                  marginTop: "16px",
                }}
                sx={{ fontSize: { xs: "12px !important", md: "16px !important" }, pl: { xs: "0 !important", md: "20px" } }}
              >
                We believe Creator marketing or engaging with influencers should be as smooth, automated and hassle free as any
                other form of digital marketing. We have built our platform to help you exactly achieve that on 6 different social
                networks.
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Testimonials />
    </div>
  );
}

export default About;
