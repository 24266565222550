import { Clear, MoreHorizOutlined, Refresh, Remove } from "@mui/icons-material";
import { Box, Divider, IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import { PropTypes } from "prop-types";
import { refreshSinglePost, removePublicationFromCampaign } from "../../../../services/publication";
import { PlatformIdMapping } from "../../../../utility/plaformIcons";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "../../../../redux/features/system/systemAlert";

const CampaignPublicationsActionButton = ({ influencerAccount, setDataRefreshed }) => {
  const user = useSelector((state) => state.user); //get loggedIn user state

  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(anchorEl === null ? event.currentTarget : null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const arrayOfOptions = [
    {
      id: 1,
      icon: <Refresh htmlColor="#B5B5C3" />,
      label: "Update Publication",
      name: "updatepublication",
    },
    {
      id: 2,
      icon: <Clear htmlColor="#B5B5C3" />,
      label: "Remove Publication",
      name: "removepublication",
    },
    // {
    //   id: 3,
    //   icon: <LabelTwoTone htmlColor="#B5B5C3" />,
    //   label: "Add/View Tag",
    //   name: "addviewtag",
    // },
    // {
    //   id: 4,
    //   icon: <PermContactCalendarOutlined htmlColor="#B5B5C3" />,
    //   label: "View Profile",
    //   name: "viewprofile",
    // },
  ];

  const invokeAction = (name) => {
    switch (name) {
      case "updatepublication": {
        updatePublication();
        break;
      }
      case "removepublication": {
        removePublication();
        break;
      }
    }
    handleClose();
  };

  const updatePublication = async () => {
    dispatch(
      notify({
        message: "We have started refreshing your post. Please check again in sometime",
        type: "info",
      })
    );
    const response = await refreshSinglePost(
      +params.get("id"),
      influencerAccount.groupId,
      parseInt(influencerAccount.infId),
      PlatformIdMapping[influencerAccount.platform],
      "post",
      influencerAccount.postId,
      influencerAccount.postUrl,
      user,
      dispatch
    );
    setDataRefreshed(true);

    influencerAccount.status = "Processing";
  };

  const removePublication = async () => {
    dispatch(
      notify({
        message: "We are removing your publication. Please check again in sometime",
        type: "info",
      })
    );
    const data = {
      cid: +params.get("id"),
      postIds: [influencerAccount.postId],
      isSaas: true,
      agencyId: user.agencyId,
    };
    const response = await removePublicationFromCampaign(data, user, dispatch);
    if (response.status === true) {
      setDataRefreshed(true);
      dispatch(
        notify({
          message: "Publication removed successfully",
          type: "success",
        })
      );
    }
  };
  return (
    <>
      <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
        <MoreHorizOutlined />
      </IconButton>
      <Box style={{ borderRadius: "30px" }}>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose} className={styles.menuitem}>
          {arrayOfOptions?.map((value) => (
            <div>
              <MenuItem key={value.id} onClick={() => invokeAction(value.name)}>
                {value.icon}&nbsp;&nbsp;&nbsp;&nbsp;{value.label}
              </MenuItem>
              {value.id < arrayOfOptions?.length && <Divider key={value.id} sx={{ my: 0.5 }} />}
            </div>
          ))}
        </Menu>
      </Box>
    </>
  );
};

CampaignPublicationsActionButton.propTypes = {
  influencerAccount: PropTypes.object.isRequired,
  setDataRefreshed: PropTypes.func,
};

export default CampaignPublicationsActionButton;
