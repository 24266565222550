import { CheckCircle, CloudUpload, Info } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
// import { API_HOST, UNAUTH_API_HOST } from "../../../config/main.json";
import { useDispatch, useSelector } from "react-redux";
import utils from "../../../utility/oryxUtils/utils";
import { useEffect } from "react";
import moment from "moment";
import slides from "../../../assets/images/googleSlides.png";
import FileSaver from "file-saver";
import {
  checkListCountries,
  createPptReport,
  downloadPptReport,
  getAllReportFields,
  getAllReportHistory,
} from "../../../services/pptReport";
import configs from "../../../config/main.json";

const reportThemeOptions = [
  {
    primary: "#e64b3c",
    primaryDark: "#c0382a",
    secondary: "#7f7f7f",
    secondaryDark: "#606060",
  },
  {
    primary: "#f77d0a",
    primaryDark: "#ba5d06",
    secondary: "#7f7f7f",
    secondaryDark: "#606060",
  },
  {
    primary: "#4057ed",
    primaryDark: "#202f8c",
    secondary: "#7f7f7f",
    secondaryDark: "#606060",
  },
];

const GeneratePptReport = ({ pptReportId, setPptReportId }) => {
  const [openModal, setOpenModal] = useState(false);
  const [openMissingCountriesModal, setOpenMissingCountriesModal] =
    useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [reportTitle, setReportTitle] = useState("Title");
  const [reportBrand, setReportBrand] = useState("Brand");
  const [reportType, setReportType] = useState("");
  const [dataRefreshedOn, setDataRefreshedOn] = useState("");
  const [reportTheme, setReportTheme] = useState(reportThemeOptions[0]);
  const [reportLogo, setReportLogo] = useState(null);
  const [reportHistory, setReportHistory] = useState([]);
  const [generating, setGenerating] = useState(false);
  const [statusES, setStatusES] = useState();
  const [progress, setProgress] = useState(0);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [status, setStatus] = useState("Started");
  const [markNew, setMarkNew] = useState(false);
  const [notification, setNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (pptReportId > 0) {
      openPPTGenerationModal(pptReportId);
    }
  }, [pptReportId]);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= currentProgress) {
          clearInterval(interval);
        }
        if (prev === 100) {
          setMarkNew(true);
          getReportHistory(selectedId);
          setGenerating(false);
          statusES.close();
          setNotification(true);
          setSeverity("success");
          setMessage("Presentation Created!");
        }
        return prev < currentProgress ? prev + 1 : prev;
      });
    }, 100);
  }, [currentProgress]);

  const openPPTGenerationModal = async (id) => {
    const res = await checkListCountries(id, user, dispatch);
    if (res.response) {
      getReportFields(id);
      getReportHistory(id);
      setSelectedId(id);
      setOpenModal(true);
      setMarkNew(false);
    } else {
      setOpenMissingCountriesModal(true);
    }
  };

  const getReportFields = async (id) => {
    const data = await getAllReportFields(id, user, dispatch);
    setReportTitle(data.response.title);
    setReportBrand(data.response.account);
    setDataRefreshedOn(
      utils.convertDateMoment(data.lastRefreshedTime, "onlydate")
    );
    if (data.response.status === 4) {
      setReportType("End of Campaign Report");
    } else if (data.response.status === 1) {
      const start = moment(data.response.startDate.split(" ")[0], "YYYY-MM-DD");
      const end = moment();
      const diff = Math.floor(end.diff(start, "days") / 7);
      setReportType(`Week ${diff} Report`);
    }
  };

  const closeModal = () => {
    setReportTitle("");
    setReportBrand("");
    setReportType("");
    setReportTheme(reportThemeOptions[0]);
    setReportLogo(null);
    setReportHistory([]);
    setGenerating(false);
    setOpenModal(false);
    setOpenMissingCountriesModal(false);
    setPptReportId(0);
  };

  const getReportHistory = async (id) => {
    const reportHistory = await getAllReportHistory(id, user, dispatch);
    setReportHistory(reportHistory.response || []);
  };

  const handleUpload = (event) => {
    if (event.target.files?.length !== 0) {
      const file = event.target.files[0];
      setReportLogo(file || "");
    } else {
      setReportLogo(null);
    }
  };

  const handleGenerateReport = async (event) => {
    event.preventDefault();

    setGenerating(true);
    setProgress(0);
    setCurrentProgress(0);
    setStatus("Started");

    const email = user.email;

    const formData = new FormData();
    formData.append("logo", reportLogo);
    formData.append("cid", selectedId);
    formData.append("account", reportBrand);
    formData.append("title", reportTitle);
    formData.append("reportType", reportType);
    formData.append("colors", JSON.stringify(reportTheme));
    formData.append("owner", email);

    const { UNAUTH_API_HOST } = configs;

    const res = await createPptReport(formData);
    const reportId = res.reportId;
    if (window.EventSource) {
      const source = new EventSource(
        UNAUTH_API_HOST + "/report/status/" + reportId
      );
      source.addEventListener(
        "open",
        (e) => {
          source.onmessage = (event) => {
            let data = JSON.parse(event.data);
            setStatus(data.status);
            setCurrentProgress(data.progress);
            if (data.status === "error") {
              setNotification(true);
              setSeverity("error");
              setMessage("An error occurred while creating the presentation!");
              setGenerating(false);
              setMarkNew(false);
              getReportHistory(selectedId);
            }
          };
        },
        false
      );
      setStatusES(source);
    }
  };

  const downloadPpt = async (title, id) => {
    const data = await downloadPptReport(id, user, dispatch);
    FileSaver(data.response, title + ".pptx");
  };

  return (
    <>
      <Dialog
        open={openModal}
        onClose={!generating && closeModal}
        PaperProps={{
          style: {
            height: "auto",
          },
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <h4 style={{ paddingBottom: "10px", marginBottom: "20px" }}>
            Generate Report
          </h4>
          <form onSubmit={handleGenerateReport}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <TextField
                    onChange={(e) => setReportTitle(e.target.value)}
                    variant="outlined"
                    label="Report Title"
                    required={true}
                    value={reportTitle}
                    disabled={generating}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <TextField
                    onChange={(e) => setReportBrand(e.target.value)}
                    variant="outlined"
                    label="Customer/Account Name"
                    required={true}
                    value={reportBrand}
                    disabled={generating}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <TextField
                    onChange={(e) => setReportType(e.target.value)}
                    variant="outlined"
                    label="Report Type"
                    required={true}
                    value={reportType}
                    disabled={generating}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <InputLabel>Report Theme</InputLabel>
                  <Select
                    variant="outlined"
                    label="Report Theme"
                    required={true}
                    value={reportTheme}
                    renderValue={(theme) => {
                      return (
                        <div
                          style={{
                            height: "20px",
                          }}
                        >
                          <span
                            style={{
                              backgroundColor: theme.primary,
                              height: "20px",
                              display: "inline-block",
                              width: "25%",
                            }}
                          />
                          <span
                            style={{
                              backgroundColor: theme.primaryDark,
                              height: "20px",
                              display: "inline-block",
                              width: "25%",
                            }}
                          />
                          <span
                            style={{
                              backgroundColor: theme.secondary,
                              height: "20px",
                              display: "inline-block",
                              width: "25%",
                            }}
                          />
                          <span
                            style={{
                              backgroundColor: theme.secondaryDark,
                              height: "20px",
                              display: "inline-block",
                              width: "25%",
                            }}
                          />
                        </div>
                      );
                    }}
                    onChange={(e) => setReportTheme(e.target.value)}
                    disabled={generating}
                  >
                    {reportThemeOptions?.map((theme) => (
                      <MenuItem value={theme} key={theme}>
                        <div
                          style={{
                            height: "20px",
                            width: "100%",
                          }}
                        >
                          <span
                            style={{
                              backgroundColor: theme.primary,
                              height: "20px",
                              display: "inline-block",
                              width: "25%",
                            }}
                          />
                          <span
                            style={{
                              backgroundColor: theme.primaryDark,
                              height: "20px",
                              display: "inline-block",
                              width: "25%",
                            }}
                          />
                          <span
                            style={{
                              backgroundColor: theme.secondary,
                              height: "20px",
                              display: "inline-block",
                              width: "25%",
                            }}
                          />
                          <span
                            style={{
                              backgroundColor: theme.secondaryDark,
                              height: "20px",
                              display: "inline-block",
                              width: "25%",
                            }}
                          />
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12}>
                <div
                  style={{
                    backgroundColor: "#f9f9f9",
                    padding: "5px 10px",
                  }}
                >
                  <Grid container>
                    <Grid item md={5}>
                      <div style={{ textAlign: "center" }}>
                        <Tooltip
                          title={reportLogo === null ? "" : reportLogo.name}
                        >
                          <Button
                            variant="outlined"
                            startIcon={<CloudUpload />}
                            endIcon={reportLogo && <CheckCircle />}
                            style={{ margin: "10px", width: "200px" }}
                            component="label"
                            disabled={generating}
                          >
                            Upload Logo
                            <input
                              style={{
                                position: "absolute",
                                width: "1px",
                                height: "1px",
                                overflow: "hidden",
                                opacity: 0,
                              }}
                              accept="image/png, image/jpeg"
                              type="file"
                              onChange={(event) => handleUpload(event)}
                              required
                            />
                          </Button>
                        </Tooltip>
                        <div
                          style={{
                            fontSize: "12px",
                            color: "gray",
                            fontStyle: "italic",
                          }}
                        >
                          JPEG, PNG only. Under 2MB File.
                        </div>
                      </div>
                    </Grid>
                    <Grid item md={7}>
                      <div
                        style={{
                          fontStyle: "italic",
                          marginTop: "20px",
                          color: "gray",
                        }}
                      >
                        <Info fontSize="small" />
                        &nbsp;&nbsp;Should be 500x160 pixels in size
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <div style={{ textAlign: "center" }}>
              {!generating && (
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ m: "15px 15px 0px 15px" }}
                  startIcon={
                    <img
                      src={slides}
                      alt="slides"
                      style={{
                        color: "white",
                        height: "20px",
                        width: "20px",
                      }}
                    />
                  }
                >
                  Generate Report
                </Button>
              )}
              {generating && (
                <>
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{ height: "10px" }}
                    style={{ marginTop: "20px" }}
                  />
                  <div style={{ color: "gray", margin: "5px 5px 20px 5px" }}>
                    <CircularProgress size={16} />
                    &nbsp;&nbsp;&nbsp;
                    {status}
                  </div>
                </>
              )}
            </div>
            {!generating && (
              <div
                style={{
                  textAlign: "right",
                  fontSize: "14px",
                  color: "gray",
                }}
              >
                Data up-to-date till {dataRefreshedOn}{" "}
                <Tooltip title="Report generated will be built on data snapshot time mentioned and not the time of report generation">
                  <Info fontSize="small" />
                </Tooltip>
              </div>
            )}
          </form>
          <hr style={{ marginTop: "4px" }} />
          <h4>View Past Reports</h4>
          <div style={{ maxHeight: "18vh", overflowY: "auto" }}>
            {reportHistory?.map((report, index) => (
              <div style={{ padding: "8px" }}>
                <Box
                  sx={
                    report.link === ""
                      ? {
                          bgcolor: "#f9f9f9",
                          padding: "10px 20px",
                          height: "68px",
                        }
                      : {
                          bgcolor: "#f9f9f9",
                          "&:hover": {
                            bgcolor: "#eeeeee",
                          },
                          padding: "10px 20px",
                          cursor: "pointer",
                          height: "68px",
                        }
                  }
                  onClick={() => {
                    const sepLink = report.link.split("/");
                    const id = sepLink[sepLink?.length - 1];
                    downloadPpt(report.title, id);
                  }}
                >
                  <div>
                    <span style={{ fontSize: "18px" }}>{report.title}</span>
                    {markNew && index === 0 && (
                      <Chip
                        sx={{
                          ml: "6px",
                          transform: "scale(0.8) translateY(-4px)",
                          bgcolor: "#5682f0",
                          borderRadius: "12px",
                          fontSize: "16px",
                        }}
                        color="primary"
                        label="New"
                      />
                    )}
                  </div>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <div style={{ fontSize: "14px", color: "gray" }}>
                        {"Built on " +
                          utils.convertDateMoment(report.date, "datetime") +
                          " GMT "}
                      </div>
                    </Grid>
                    <Grid item>
                      <div style={{ fontSize: "14px", color: "gray" }}>
                        {"Using " +
                          utils.convertDateMoment(
                            report.dataUpdateTime,
                            "onlydate"
                          ) +
                          " data"}
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            ))}
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openMissingCountriesModal}
        onClose={closeModal}
        PaperProps={{
          style: {
            height: "auto",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Countries Missing</DialogTitle>
        <DialogContent>
          Some groups do not have their countries defined. In order to proceed,
          make sure every group has their country selected.
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={closeModal}
            style={{ marginRight: "15px" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GeneratePptReport;
