import { bool, func, object, string } from "prop-types";
import { useEffect, useRef, useState } from "react";
import { Avatar, Box, Card, CardContent, Grid, Typography, Modal, IconButton } from "@mui/material";
import { LocationOn, OpenInNew } from "@mui/icons-material";
import { getDetailedProfileInformation, getDetailedInfluencerInformation } from "../../../../../services/influencer";
import { snapshotHeaderIcons2, textColor } from "../../../../../utility/plaformIcons";
import { stringAvatar } from "../../campaignDrafts/CommonAssets";
import configs from "../../../../../config/main.json"
import phone from "../../../../../assets/images/Vector.svg"
import Verified from "../../../../../assets/images/Verified User.svg"
import mail from "../../../../../assets/images/email.svg"
import location from "../../../../../assets/images/location.svg"
import warning from "../../../../../assets/images/Warning.svg"
import { Close, Person } from "@mui/icons-material";
import verified from "../../../../../assets/images/verified.svg"
import { useDispatch } from "react-redux";
import age from "../../../../../assets/images/age.svg"
import gender from "../../../../../assets/images/Gender.svg"

const ViewDetailedProfileDialog = ({ influencerAccount, viewProfile, closeViewProfileDialog, user }) => {
  const [loading, setLoading] = useState(true);
  const [influencerInformation, setInfluencerInformation] = useState({})
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [filteredLocation, setFilteredLocation] = useState([]);
  const [filteredGender, setFilteredGenger] = useState([]);
  const [filteredAge, setFilteredAge] = useState([]);
  const dispatch = useDispatch();

  const handleClose = () => {
    closeViewProfileDialog(false);
  };
  useEffect(() => {
    getInfluencerInformation()
  }, [influencerAccount]);

  const removeEmptyStrings = (arr) => {
    return arr.filter(item => item !== '');
  }

  const getInfluencerInformation = () => {
    setLoading(true);
    getDetailedInfluencerInformation(influencerAccount.iid, user, dispatch).then((data) => {
      console.log(data)
      setInfluencerInformation(data)
      setFilteredContacts(removeEmptyStrings(data.contacts));
      setFilteredLocation(removeEmptyStrings(data.locations));
      setFilteredGenger(removeEmptyStrings(data.genders));
      setFilteredAge(removeEmptyStrings(data.ageRanges))
      setLoading(false);
    })
  }

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  return (
    !loading && (
      <Modal
        open={viewProfile}
        onClose={handleClose}
        aria-labelledby="profile-modal-title"
        aria-describedby="profile-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: "55%",
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 0,
            borderRadius: 3,
          }}
        >
          <Grid container sx={{ height: "74px", background: "linear-gradient(to right, #C4E4FF 0%, #3699FF 100%)", borderTopLeftRadius: "10px", borderTopRightRadius: "10px", display: "flex", justifyContent: "flex-end", textAlign: "center" }}>
            <IconButton
              onClick={handleClose}
              sx={{ marginRight: '3%', marginTop: "18px", height: "40px" }}
            >
              <Close />
            </IconButton>
          </Grid>
          <Grid container sx={{ margin: "0 4% 5% 4%", width: "96%", display: "flex", gap: "5%" }}>

            <Grid item >
              <Avatar
                src={influencerAccount.photoUrl || ""}
                {...stringAvatar(influencerAccount.name)}
                onError={(e) => {
                  e.target.src = configs.PROFILE_IMG;
                }}
                sx={{
                  width: 178,
                  height: 160,
                  borderRadius: 5,
                  position: "relative",
                  top: "-40px",
                }}
              />
              <Grid>
                {influencerInformation.platforms && influencerInformation.platforms?.map((platform) => (
                  platform.platform !== 'google' && (
                    <Grid item style={{ marginTop: "8px", color: "#000000", fontSize: "14px", }}>
                      <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                        <Typography sx={textColor[platform.platform]} style={{ paddingTop: "10px" }}>
                          {snapshotHeaderIcons2[platform.platform]}
                        </Typography>
                        <div>
                          <div style={{ display: "flex", alignItems: "center", marginBottom: "-7px" }}>
                            {platform.username === '' ? <span style={{ fontSize: "20px" }}>-</span> : <span>@{platform.username}</span>}
                            {platform.profileurl !== '' && (
                              <IconButton onClick={() => window.open(platform.profileurl, "_blank")}>
                                <OpenInNew style={{ fontSize: "14px" }} />
                              </IconButton>
                            )}
                          </div>
                          <span>{platform.followers} followers</span>
                        </div>
                      </div>
                    </Grid>
                  )
                ))}
              </Grid>
            </Grid>

            <Grid item sx={{ width: "60%" }}>
              <Grid item style={{ marginTop: "20px" }}>
                <div style={{ fontSize: "40px", fontWeight: "bold", color: "#111111", lineHeight: "40px", }}>
                  {influencerAccount.name}
                </div>
                <span style={{ fontSize: "14px", color: "#94A2B3" }}>
                  <LocationOn style={{ fontSize: "19px" }} />
                  {influencerAccount.location === "" ? "Location not found" : influencerAccount.location}
                </span>
              </Grid>
              <Grid sx={{

                marginTop: "20px",
                display: "flex",
                gap: "2%",
                flexWrap: "wrap",
                color: "#1BC5BD",
                fontSize: "12px",
                width: "38vw"
              }}>
                <div style={{ width: "20%" }}>Member since
                  <div style={{ color: "#111111", fontSize: "14px" }}>
                    {influencerInformation.createdAt !== "" ? formatDate(influencerInformation.createdAt) : "-"}
                  </div>
                </div>
                <div style={{ width: "20%" }}>Active campaigns
                  <div style={{ color: "#111111", fontSize: "14px" }}>{influencerInformation.campaignData.activeCampaigns}</div>
                </div>
                <div style={{ width: "20%" }}>Past campaigns
                  <div style={{ color: "#111111", fontSize: "14px" }}>{influencerInformation.campaignData.pastCampaigns}</div>
                </div>
                <div style={{ width: "20%" }}>Contracts signed
                  <div style={{ color: "#111111", fontSize: "14px" }}>{influencerInformation.campaignData.contractsSigned}</div>
                </div>
              </Grid>

              <Grid sx={{
                display: "flex",
                fontSize: "12px",
                color: "#262222",
              }}>
                <div style={{ width: "60%" }}>
                  <div style={{ marginTop: "20px", }}>
                    <img src={phone} alt="Icon" style={{ paddingBottom: "5px" }} /><span style={{ color: "#111111", fontWeight: "bold", fontSize: "14px" }}> Tel :</span><br />
                    {/* +999999999 <img src={verified} style={{ height: "15px", paddingBottom: "3px" }} alt="Icon" /> <span style={{ color: "#757575" }}>Verified</span><br /> */}
                    {filteredContacts.length > 0 ? (
                      filteredContacts.map((phone, index) => (
                        <div key={index} style={{ display: "flex", alignItems: "center" }}>
                          {phone}
                        </div>
                      ))
                    ) : (
                      <span style={{ fontSize: "20px", paddingLeft: "20px" }}>-</span>
                    )}
                  </div>
                  <div style={{ marginTop: "20px", }}>
                    <img src={mail} alt="Icon" style={{ paddingBottom: "5px" }} /><span style={{ color: "#111111", fontWeight: "bold", fontSize: "14px" }}> Email :</span><br />
                    {/* anasdesign0@gmail.com <img src={verified} style={{ height: "15px", paddingBottom: "3px" }} alt="Icon" /> <span style={{ color: "#757575" }}>Verified</span><br /> */}
                    {influencerInformation.email !== "" ? influencerInformation.email : <span style={{ fontSize: "20px", paddingLeft: "20px" }}>-</span>}
                  </div>
                  <div style={{ marginTop: "20px", }}>
                    <img src={location} alt="Icon" style={{ paddingBottom: "5px" }} /><span style={{ color: "#111111", fontWeight: "bold", fontSize: "14px" }}> Other Known Locations :</span><br />
                    {filteredLocation.length > 1 ? (
                      filteredLocation.slice(1).map((location, index) => (
                        <div key={index} style={{ display: "flex", alignItems: "center" }}>
                          {location}
                        </div>
                      ))
                    ) : (
                      <span style={{ fontSize: "20px", paddingLeft: "20px" }}>-</span>
                    )}
                  </div>
                  <div style={{ marginTop: "20px", }}>
                    <img src={gender} alt="Icon" style={{ paddingBottom: "5px" }} /><span style={{ color: "#111111", fontWeight: "bold", fontSize: "14px" }}> Gender :</span>
                    {filteredGender.length > 1 && new Set(filteredGender).size > 1 && (
                      <img src={warning} alt="Icon" style={{ paddingBottom: "5px", paddingLeft: "5px" }} />
                    )}
                    <br />
                    {filteredGender.length > 0 ? (
                      filteredGender.map((gender, index) => (
                        <div key={index} style={{ display: "flex", alignItems: "center", paddingLeft: "3px" }}>
                          {gender}
                        </div>
                      ))
                    ) : (
                      <span style={{ fontSize: "20px", paddingLeft: "20px" }}>-</span>
                    )}
                  </div>
                </div>
                <div>
                  <div style={{ marginTop: "20px", }}>
                    <img src={age} alt="Icon" style={{ paddingBottom: "5px" }} /><span style={{ color: "#111111", fontWeight: "bold", fontSize: "14px" }}> Age :</span><br />
                    {filteredAge.length > 0 ? (
                      filteredAge.map((gender, index) => (
                        <div key={index} style={{ display: "flex", alignItems: "center" }}>
                          {gender}
                        </div>
                      ))
                    ) : (
                      <span style={{ fontSize: "20px", paddingLeft: "20px" }}>-</span>
                    )}
                  </div>
                  {influencerInformation.names.length > 1 && (
                    <div style={{ marginTop: "20px", }}>
                      <Person style={{ fontSize: "14px", marginBottom: "5px" }} />
                      <span style={{ color: "#111111", fontWeight: "bold", fontSize: "14px" }}> Other Known Names :</span><br />
                      {influencerInformation.names.slice(1).map((name, index) => (
                        <div key={index} style={{ display: "flex", alignItems: "center", paddingLeft: "3px" }}>
                          <span style={{ marginRight: "8px" }}>•</span>
                          {name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>

    )
  );
};
ViewDetailedProfileDialog.propTypes = {
  influencerAccount: object.isRequired,
  viewProfile: bool.isRequired,
  closeViewProfileDialog: func.isRequired,
};
export default ViewDetailedProfileDialog;
