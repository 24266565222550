import {
  ApartmentOutlined,
  BusinessOutlined,
  HomeOutlined,
  PaymentOutlined,
  PinOutlined,
} from "@mui/icons-material";
import {
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  FormControl,
  Box,
  Dialog,
  DialogContent,
} from "@mui/material";
import styles from "../../styles/internal/Subscribe.module.css";
import { RoundedTextField } from "../../components/common/StyledComponents";
import React, { useEffect, useState } from "react";
import { getCountries } from "../../services/settings";
import { Button } from "react-bootstrap";
import logo from "../../assets/images/pay-logo-strip.png";
import { addBillingDetailsToAccount, addcardDetailsToAccount } from "../../services/billing";
import { monthArray } from "../../components/common/utils";
import { useDispatch } from "react-redux";
import { notify } from "../../redux/features/system/systemAlert";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const EditCardInformation = ({
  email,
  user,
  openCard,
  cardDetails,
  handleClose,
}) => {
  const [countries, setCountries] = useState([]);
  const scroll = "paper";
  const [yearsArray, setYearsArray] = React.useState([]);
  const dispatch = useDispatch();
  const displayNames = new Intl.DisplayNames(["en"], { type: "region" });
  const [state, setState] = useState({
    cardNumber: "",
    selectedDate: new Date(),
    month: cardDetails.payment_method?.exp_month
      ? monthArray[cardDetails.payment_method?.exp_month - 1]
      : "",
    year: cardDetails.payment_method?.exp_year,
    country: cardDetails.address?.country
      ? displayNames.of(cardDetails.address?.country)
      : "",
    cvv: "",
    streetAddress1: cardDetails.address?.street1,
    streetAddress2: cardDetails.address?.street2,
    city: cardDetails.address?.city,
    region: cardDetails.address?.region,
    postalcode: cardDetails.address?.postal_code,
  });
  useEffect(() => {
    getYearsArray();
    getAllCountries();
  }, []);

  const getYearsArray = () => {
    const yearArray = [];
    for (
      let year = new Date().getFullYear();
      year <= new Date().getFullYear() + 50;
      year++
    ) {
      yearArray.push(year);
    }
    setYearsArray(yearArray);
  };

  const getAllCountries = async () => {
    const result = await getCountries();
    setCountries(result.response);
  };

  const addBillingDetails = async () => {
    dispatch(
      notify({
        message: "Please wait... we are updating your billing details",
        type: "info",
      })
    );
    const {
      cardNumber,
      month,
      year,
      cvv,
      streetAddress1,
      streetAddress2,
      city,
      region,
      country,
      postalcode,
    } = state;
    let response;
    const billingDetails = {
      cardNumber: cardNumber.replaceAll("  ", ""),      
      month: (monthArray.indexOf(month) + 1).toString(),
      year: year.toString(),
      cvv,
      city,
      country,
      postalcode,
      street1: streetAddress1,
      email: user.email,
    };
    response = await addcardDetailsToAccount(billingDetails, user, dispatch);
    if (response?.response === "success") {
      dispatch(
        notify({
          message: "Billing Details updated successfully!",
          type: "success",
        })
      );
    } else {
      dispatch(notify({ message: response?.response, type: "error" }));
    }
    handleClose({ informationUpdated: true });
  };

  const card = (
    <>
      <Dialog
        open={openCard}
        onClose={() => handleClose({ informationUpdated: false })}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent>
          <h4 className={styles.subTitle}>Billing Information</h4>
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                defaultValue="start"
              >
                <FormControlLabel
                  value="start"
                  control={<Radio />}
                  label="Pay with Credit Card"
                  labelPlacement="end"
                  className={styles.formTitle}
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={7}>
              <Box
                component={"img"}
                src={logo}
                alt="logo"
                sx={{ paddingTop: "10px" }}
              />
            </Grid>
          </Grid>

          <div className={styles.formTitle}>
            <b>Card Number</b>
          </div>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                size="small"
                sx={RoundedTextField}
                placeholder="Card Number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PaymentOutlined />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "\\d{13,22}", // Matches between 13 and 19 digits (including spaces)
                  maxLength: 22, // Maximum length including spaces
                }}
                value={state.cardNumber}
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                  const formattedValue = inputValue
                    .slice(0, 16) // Limit to 16 digits
                    .replace(/(.{4})/g, "$1  "); // Add spaces every 4 digits

                  setState({ ...state, cardNumber: formattedValue.trim() }); // Update state
                }}
                required
              />
            </Grid>
          </Grid>
          <div className={styles.formTitle}>
            <b>Card Details</b>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-select-small-label">Month</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  label="<Month>"
                  onChange={(e) =>
                    setState({ ...state, month: e.target.value })
                  }
                  value={state.month}
                  sx={{
                    borderRadius: "36px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "36px",
                    },
                  }}
                >
                  {monthArray?.map((month) => (
                    <MenuItem key={month} value={month}>
                      {month}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-select-small-label">Year</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  label="<Year>"
                  onChange={(e) => setState({ ...state, year: e.target.value })}
                  value={state.year}
                  sx={{
                    borderRadius: "36px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "36px",
                    },
                  }}
                  MenuProps={MenuProps}
                >
                  {yearsArray?.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                type="text"
                sx={RoundedTextField}
                placeholder="<CVV>"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PinOutlined />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  maxLength: 3,
                }}
                value={state.cvv}
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setState({ ...state, cvv: inputValue.slice(0, 3) }); // Limit to 20 characters
                }}
                required
              />
            </Grid>
          </Grid>
          <div className={styles.formTitle}>
            <b>Billing Address*</b>
          </div>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-select-small-label">
                  Select a country
                </InputLabel>

                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  label="<Select a country>"
                  onChange={(e) =>
                    setState({ ...state, country: e.target.value })
                  }
                  value={state.country}
                  sx={{ borderRadius: "26px", width: "550px" }}
                  MenuProps={MenuProps}
                >
                  {countries?.map((country) => (
                    <MenuItem key={country.id} value={country.name}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                size="small"
                sx={RoundedTextField}
                placeholder="<Street Address 1>"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <HomeOutlined />
                    </InputAdornment>
                  ),
                }}
                value={`${state.streetAddress1}`}
                onChange={(e) =>
                  setState({ ...state, streetAddress1: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                sx={RoundedTextField}
                placeholder="<Street Address 2>"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <HomeOutlined />
                    </InputAdornment>
                  ),
                }}
                value={`${state.streetAddress2}`}
                onChange={(e) =>
                  setState({ ...state, streetAddress2: e.target.value })
                }
              />
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                size="small"
                sx={RoundedTextField}
                placeholder="<City>"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ApartmentOutlined />
                    </InputAdornment>
                  ),
                }}
                value={`${state.city}`}
                onChange={(e) => setState({ ...state, city: e.target.value })}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                sx={RoundedTextField}
                placeholder="<State/Province>"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <BusinessOutlined />
                    </InputAdornment>
                  ),
                }}
                value={`${state.region}`}
                onChange={(e) => setState({ ...state, region: e.target.value })}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                sx={RoundedTextField}
                placeholder="<Postal Code>"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PinOutlined />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  maxLength: 6,
                }}
                value={`${state.postalcode}`}
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setState({ ...state, postalcode: inputValue.slice(0, 6) }); // Limit to 20 characters
                }}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={10}>
            <Grid item xs={6}>
              <Button
                value="active"
                onClick={() => handleClose({ informationUpdated: false })}
                variant="outlined"
              >
                <b>Close</b>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <div style={{ textAlign: "right" }}>
                <Button
                  className={styles.staticButton}
                  onClick={addBillingDetails}
                  disabled={
                    !state.cardNumber ||
                    !state.selectedDate ||
                    !state.streetAddress1 ||
                    !state.cvv ||
                    !state.postalcode
                  }
                >
                  <b>Update Billing Information</b>
                </Button>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
  return <div>{card}</div>;
};

export default EditCardInformation;
