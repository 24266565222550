import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import a_1 from "../../assets/images/A_1.svg";
import a_2 from "../../assets/images/A_2.svg";
import a_3 from "../../assets/images/A_3.svg";
import {
  commonStyleLeftImageHeader,
  commonStyleLeftTextPara,
  commonStyleRightImageHeader,
  commonStyleRightTextPara,
} from "./LandingConstants";
import Testimonials from "./Testimonials";
import TopBrands from "./TopBrands";
import styles from "../../styles/internal/Landing/BaseStyling.module.css";
import "../../styles/internal/Landing/Landing.css";

function BaseAttribution() {
  const navigate = useNavigate();
  React.useEffect(() => {
    const allSections = document.querySelectorAll(".section");

    const revealSection = function (entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.remove("section--hidden");
          observer.unobserve(entry.target);
        }
      });
    };

    const sectionObserver = new IntersectionObserver(revealSection, {
      root: null, // Use the viewport as the root
      threshold: 0.2, // Adjust as needed
    });

    allSections.forEach(function (section) {
      sectionObserver.observe(section);
      section.classList.add("section--hidden");
    });
  }, []); // Empty dependency array means this effect runs once after the initial render
  return (
    <div className={styles.wrapper}>
      {/* Header */}
      <div className={styles.headerBox}>
        <div className={styles.topText}>insightful attribution analytics</div>
        <Box className={styles.title} sx={{ fontSize: { xs: "30px !important", md: "40px !important", lg: "60px !important" } }}>
          Give credit where it's due - Attribution that helps you make smarter decisions.
        </Box>
        <div className={styles.subTitle}>
          Connect the dots with advanced digital marketing attribution. From clicks to conversions: understand your customer
          journey with digital attribution.
        </div>
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <Button
            variant="contained"
            sx={{
              borderRadius: "56px",
              height: "60px",
              width: "190px",
              marginInline: "auto",
              fontSize: "20px",
              color: "#FFFFFF",
              backgroundColor: "#464E5F",
              "&:hover": {
                backgroundColor: "#2a2f39",
                color: "#FFFFFF",
              },
            }}
            onClick={() => navigate("/pricing")}
          >
            GET STARTED
          </Button>
        </div>
      </div>
      {/* Left Text and Right Image */}
      <div className={styles.rightTextDiv}>
        <section className="section section--hidden">
          <Grid container spacing={2}>
            <Grid
              item
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={10}
                  style={{
                    ...commonStyleLeftImageHeader,
                  }}
                  sx={{ fontSize: { xs: "30px !important", md: "40px !important" }, pl: { xs: 0, md: "10vw" } }}
                >
                  Measure Link Clicks
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{
                    ...commonStyleLeftTextPara,
                    marginTop: "16px",
                  }}
                  sx={{ fontSize: { xs: "12px !important", md: "16px !important" }, pl: { xs: 0, md: "10vw" } }}
                >
                  Create per Influencer unique link to capture the clicks driven by influencers to your website.
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} style={{ textAlign: "center" }}>
              <img src={a_1} alt="logo" height="90%" />
            </Grid>
          </Grid>
        </section>
      </div>
      {/* Left Image and Right text */}
      <div className={styles.leftTextDiv}>
        <section className="section section--hidden">
          <Grid container spacing={2}>
            <Grid item md={6} style={{ textAlign: "center" }}>
              <img src={a_2} alt="logo" height="90%" />
            </Grid>
            <Grid
              item
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={10}
                  style={{
                    ...commonStyleRightImageHeader,
                  }}
                  sx={{ fontSize: { xs: "30px !important", md: "40px !important" }, pl: { xs: "0 !important", md: "18px" } }}
                >
                  Track Referrals
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{
                    ...commonStyleRightTextPara,
                    marginTop: "16px",
                  }}
                  sx={{ fontSize: { xs: "12px !important", md: "16px !important" }, pl: { xs: "0 !important", md: "20px" } }}
                >
                  Tie influencer’s post directly to sales. Reinvest in top performers to double-down on effective channels.
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </section>
      </div>
      {/* Left Text and Right Image */}
      <div className={styles.rightTextDiv}>
        <section className="section section--hidden">
          <Grid container spacing={2}>
            <Grid
              item
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={10}
                  style={{
                    ...commonStyleLeftImageHeader,
                  }}
                  sx={{ fontSize: { xs: "30px !important", md: "40px !important" }, pl: { xs: 0, md: "10vw" } }}
                >
                  Integrate with Google Analytics
                </Grid>
                <Grid
                  item
                  xs={9}
                  style={{
                    ...commonStyleLeftTextPara,
                    marginTop: "16px",
                  }}
                  sx={{ fontSize: { xs: "12px !important", md: "16px !important" }, pl: { xs: 0, md: "10vw" } }}
                >
                  See all your campaign and website/app data in one place in a way that makes complete sense a cross your other
                  marketing channels.
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} style={{ textAlign: "center" }}>
              <img src={a_3} alt="logo" height="90%" />
            </Grid>
          </Grid>
        </section>
      </div>
      {/* Testimonials */}
      <Testimonials />
      {/* Top Brands */}
      <TopBrands />
    </div>
  );
}

export default BaseAttribution;
