import { useState, useEffect } from "react";
import * as React from "react";
import PropTypes from "prop-types";
import { FormControl, Grid, MenuItem, Tooltip, InputLabel, Select, Box } from "@mui/material";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import StyledMenu from "../StyledMenu";
import discoveryUtils from "../../../utility/oryxUtils/discoveryUtils";
import { FilterTooltips } from "./static/FiltersTooltips";
import utils from "../../../utility/oryxUtils/utils";
import Button from "@mui/material/Button";

const Age = ({ showFilter, payload, setPayload, allFiltersData, setAllFiltersData, isInfFilter, state, createCampaign }) => {
  const [audFromAge,setAudFromAge] = useState("");
  const [audToAge,setAudToAge] = useState("");
  const [infFromAgeSel,setInfFromAgeSel] = useState("");
  const [infToAgeSel,setInfToAgeSel] = useState("");
  const [audienceFromAge] = useState(["13", "18", "25", "35", "45", "65"]);
  const [audienceToAge] = useState(["18", "25", "35", "45", "65", "65+"]);
  const [infFromAge] = useState(["18", "25", "35", "45", "65"]);
  const [infToAge] = useState(["25", "35", "45", "65", "65+"]);
  const [range, setRange] = useState(0.01);
  const [showValue, setShowValue] = useState(false);

  useEffect(() => {
    if (!payload.aud_age_from) {
      setAudFromAge("");
    }
    if (!payload.aud_age_to) {
      setAudToAge("");
    }
    

    if (!payload.inf_age_from) {
      setInfFromAgeSel("");
    }
    if (!payload.inf_age_to) {
      setInfToAgeSel("");
    }
  }, [payload]);
  

  const handleChange = (event, ageType, isFrom) => {
    const newPayload = { ...payload };
    newPayload[ageType] = event.target.value;
 

    if (isInfFilter) {
      newPayload["inf_age"] = { right_number: "", left_number: "" };
      if (ageType === "inf_age_from") {
        newPayload["inf_age"]["left_number"] = event.target.value;
      } else {
        newPayload["inf_age"]["right_number"] = event.target.value === "65+" ? "" : event.target.value;
      }
    } else {
      newPayload["aud_age"] = { operator: "gte", weight: range, right_number: "", left_number: "" };
      if (ageType === "aud_age_from") {
        newPayload["aud_age"]["left_number"] = event.target.value;
      } else {
        newPayload["aud_age"]["right_number"] = event.target.value === "65+" ? "" : event.target.value;
      }
    }

    setPayload(newPayload);
    setShowValue(true);
    setAgeFilter(isInfFilter ? "inf" : "aud", newPayload);
  };

  const setAgeFilter = (type,newPayload) => {
    let fromAge = newPayload[type + "_age_from"];
    let toAge = newPayload[type + "_age_to"] || "";
    fromAge = fromAge || (type === "aud" ? "13" : "18");
    
    allFiltersData = utils.addObj(allFiltersData, type + "_age");
    allFiltersData[0][type + "_age"][0] = {
      left: fromAge,
      right: toAge,
      weight: newPayload[type + "_age_weight"],
    };
    setAllFiltersData(allFiltersData);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRange = (event) => {
    const newPayload = { ...payload };
    newPayload["aud_age"]["weight"] = event.target.value;
    setPayload(newPayload);
    setRange(event.target.value);
  };

  useEffect(() => {
    if (payload.inf_age) {
      setShowValue(true);
    }
  }, [payload]);

  const displayAgeText = () => {
    if (createCampaign && showValue) {
      return `${payload.inf_age_from ?? ""} to ${payload.inf_age_to ?? ""}`;
    } else {
      return isInfFilter ? "Influencer age-group" : "Audience Age";
    }
  };

  return showFilter ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tooltip title={isInfFilter ? FilterTooltips.age.infTooltipMsg : FilterTooltips.age.audTooltipMsg}>
            <Button
              sx={{
                color: "#6B778C",
                background: "unset !important",
                border: "1px solid lightgrey",
                p: "7px 12px",
                height: createCampaign ? "58px" : "40px", 
                width: '100%',
                justifyContent: "space-between",
                fontWeight: '400'
              }}
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
              fullWidth
              size="large"
              endIcon={open ? <ArrowDropUp fontSize="small" /> : <ArrowDropDown fontSize="small" />}
            >
              {displayAgeText()}
            </Button>
          </Tooltip>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{ "aria-labelledby": "demo-customized-button" }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <Grid container spacing={1} sx={{ height: "auto", mt: 0 }}>
              {!isInfFilter && (
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item>
                      <FormControl sx={{ m: 1 }}>Age</FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="demo-simple-select-helper-label">From</InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="audFromAge"
                          value={payload.aud_age_from || audFromAge}
                          label="From"
                          onChange={(e) => handleChange(e, "aud_age_from", true)}
                        >
                          {audienceFromAge?.map((name) => (
                            <MenuItem key={name} value={name}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="demo-simple-select-helper-label">To</InputLabel>
                        <Select
                          value={payload.aud_age_to || audToAge}
                          onChange={(e) => handleChange(e, "aud_age_to", false)}
                          label="To"
                        >
                          {audienceToAge?.map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              disabled={
                                payload.aud_age_from && parseInt(name) <= parseInt(payload.aud_age_from)
                              }
                            >
                              {name}
                            </MenuItem>
                          ))}
                          </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {/* commenting out below piece of code bcoz range is now given in filter chips */}
                    {/* <Box sx={{ margin: "8px" }}>{discoveryUtils.getRangesFilter(range, "", handleRange)}</Box> */}
                  </Grid>
                </Grid>
              )}
              {isInfFilter && (
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item>
                      <FormControl sx={{ m: 1 }}>Age</FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="demo-simple-select-helper-label">From</InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="infFromAge"
                          value={payload.inf_age_from || infFromAgeSel}
                          label="From"
                          onChange={(e) => handleChange(e, "inf_age_from", true)}
                        >
                          {infFromAge?.map((name) => (
                            <MenuItem key={name} value={name}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="demo-simple-select-helper-label">To</InputLabel>
                        <Select
                          value={payload.inf_age_to || infToAgeSel}
                          onChange={(e) => handleChange(e, "inf_age_to", false)}
                          label="To"
                        >
                          {infToAge?.map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              disabled={
                                payload.inf_age_from && parseInt(name) <= parseInt(payload.inf_age_from)
                              }
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </StyledMenu>
        </Grid>
      </Grid>
    </>
  ) : null;
};

Age.propTypes = {
  showFilter: PropTypes.bool.isRequired,
  payload: PropTypes.object.isRequired,
  setPayload: PropTypes.func.isRequired,
  allFiltersData: PropTypes.array.isRequired,
  setAllFiltersData: PropTypes.func.isRequired,
  isInfFilter: PropTypes.bool.isRequired,
  state: PropTypes.object,
  createCampaign: PropTypes.bool,
};

export default Age;
