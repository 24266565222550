import { Box, Button, Modal, TextField, Grid, Checkbox, FormControl, FormControlLabel, IconButton, Tooltip } from "@mui/material";
import { InfoOutlined, Close as CloseIcon } from "@mui/icons-material"; // Correct import
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "../../../newCampaign/staticStyling";
import DraftEditor from "../../../../common/DraftEditor";
import { editContractTemplate, getContractInput, getContractTemplateContent } from "../../../../../services/contracts";
import { notify } from "../../../../../redux/features/system/systemAlert";
import styles from "../../../../../styles/internal/Campaign/CreateNewCampaign.css";

const EditTemplate = ({ open, onClose, templateDetails, campaignId, tabId, reload }) => {
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const [state, setState] = useState({
    id: 0,
    templateName: "",
    templateDescription: "",
    templateContent: "",
    kycRequired: false,
    contractInputFields: "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (templateDetails) {
      getTemplateContent(templateDetails.id);
    }
  }, [templateDetails]);

  const getTemplateContent = async (id) => {
    const templateContent = await getContractTemplateContent(id, user, dispatch);
    const contractInput = await getContractInput(user, dispatch);

    if (templateContent.response && contractInput.response) {
      const { content, kycRequired, inputFields } = templateContent.response[0];
      setState({
        ...state,
        id: templateDetails.id,
        templateName: templateDetails.title,
        templateDescription: templateDetails.description,
        templateContent: content,
        kycRequired: kycRequired,
        contractInputFields: inputFields,
      });
    }
  };

  const onEditTemplate = async () => {
    const { id, templateName, templateDescription, templateContent, contractInputFields, kycRequired } = state;

    dispatch(
      notify({
        message: `Editing template  ${templateName}`,
        type: "info",
      })
    );
    if (templateContent === null || templateContent.search('<span style="background-color') !== -1) {
      return;
    }
    const body = {
      id: id.toString(),
      title: templateName,
      description: templateDescription,
      content: templateContent,
      inputFields: contractInputFields,
      kycRequired: kycRequired,
    };

    const response = await editContractTemplate(body, user, dispatch);
    if (response?.response) {
      dispatch(
        notify({
          message: `${templateName} template edited successfully!`,
          type: "success",
        })
      );
      onClose();
      reload(); // Reload the data after closing the modal
    }
  };

  const handleClose = () => {
    onClose();
    reload(); // Reload the data after closing the modal
  };

  return (
    <Modal open={open} onClose={handleClose} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box style={{ backgroundColor: "#FAFAFB", padding: "10px", maxWidth: "800px", width: '100%', maxHeight: '90%', overflowY: 'auto' }}>
        <Box m={3} position="relative">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <h4 className={styles.header} style={{ margin: 0, textAlign: 'center', flexGrow: 1 }}>Edit Template Information</h4>
            <IconButton onClick={handleClose} style={{ position: 'absolute', right: 0 }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Grid container spacing={4} style={{ paddingTop: 20 }}>
            <Grid item md={6} xs={12}>
              <TextField
                sx={{ width: "100%" }}
                variant="outlined"
                label="Template Name"
                placeholder="<Enter Template Name>"
                type="text"
                required
                helperText="Max 48 Chars"
                value={state.templateName}
                onChange={(e) => setState({ ...state, templateName: e.target.value })}
                InputProps={{
                  endAdornment: <div className={classes.counter}>{state.templateName?.length}/48</div>,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                sx={{ width: "100%" }}
                variant="outlined"
                label="Template Description"
                placeholder="<Enter Template Description>"
                type="text"
                required
                helperText="Max 256 Chars"
                value={state.templateDescription}
                onChange={(e) => setState({ ...state, templateDescription: e.target.value })}
                InputProps={{
                  endAdornment: <div className={classes.counter}>{state.templateDescription?.length}/256</div>,
                }}
              />
            </Grid>
            {state.templateContent && (
              <>
                <Grid item md={12} xs={12}>
                  <DraftEditor
                    value={state.templateContent}
                    setValue={(e) => setState({ ...state, templateContent: e })}
                    readOnly={false}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <FormControlLabel
                      label={
                        <>
                          <span>KYC is required before accepting the contract &nbsp;</span>
                          <IconButton>
                            <Tooltip
                              title={
                                <span style={{ whiteSpace: "pre-line" }}>
                                  Selecting this will ensure that the influencer has uploaded a KYC document before proceeding
                                </span>
                              }
                            >
                              <InfoOutlined fontSize="small" style={{ color: "#6b778c" }} />
                            </Tooltip>
                          </IconButton>
                        </>
                      }
                      control={
                        <Checkbox
                          checked={state.kycRequired}
                          onChange={(e) => setState({ ...state, kycRequired: !state.kycRequired })}
                        />
                      }
                    />
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>
          <Grid container item spacing={3} xs={12} sx={{ paddingTop: 4 }}>
            <Grid item md={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={onEditTemplate}
                disabled={
                  !state.templateContent ||
                  !state.templateDescription ||
                  !state.templateName ||
                  state.templateDescription?.length > 256 ||
                  state.templateName?.length > 48
                }
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditTemplate;