import React from 'react'
import { Box, Typography } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import empowrBetaLogo from "../../assets/images/EmpowrLogo.svg";

const UnderMaintenance = () => {
  return (
    <Box width={"100%"} height={"100vh"} display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={"column"} bgcolor={"white"} color={"#000000"}>
      <Box mb="2rem">
        <Box
          component={"img"}
          src={empowrBetaLogo}
          alt="Empowr"
          mx="auto"
          pl={2}
        />
      </Box>
      <Box width={"900px"} display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={"column"} mx="auto">
        <Box sx={{ color: 'black !important', placeItems: "center", fontSize: "8rem" }} display={"grid"} >
          <WarningAmberIcon fontSize='inherit' />
        </Box>
        <Typography variant='h1' fontSize={"5rem"} my="1rem">
          We'll be back soon!
        </Typography>
        <Box component={'p'} fontSize={"2rem"} my="0.5rem" textAlign={"center"}>
          Sorry for the inconvenience. We’re performing some maintenance at the moment.
          <br />
          We’ll be back up shortly!
        </Box>
        <Box component={'p'} fontSize={"2rem"} my="0.5rem">
          - The Empower Team
        </Box>
      </Box>
    </Box>
  )
}

export default UnderMaintenance
