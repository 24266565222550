import {
  styled,
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  Popper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { snapshotHeaderIcons, textColor } from "../../utility/plaformIcons";
import { capitalizeFirstLetter } from "../../utility/stringOperations";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const PopperStyledComponent = styled(Popper)(({ theme }) => ({
  border: `1px solid ${
    theme.palette.mode === "light" ? "rgba(149, 157, 165, 0.2)" : "rgb(1, 4, 9)"
  }`,
  position: 'absolute',
  zIndex: 1,
  width: '100%',
}));

const PlatformSelectionBox = ({
  sendSelectedPlatforms,
  parentPlatforms,
  disabled = false,
}) => {
  const [selectedPlatform, setSelectedPlatform] =
    React.useState(parentPlatforms);
  const [platforms] = React.useState(
    Object.keys(snapshotHeaderIcons)
  );
  const [checkAll, setCheckAll] = React.useState(selectedPlatform.toString() === platforms.toString());

  React.useEffect(() => {}, [parentPlatforms]);

  const selectPlatform = (value, event) => {
    const jsonObject = event?.map(JSON.stringify);
    const uniqueSet = new Set(jsonObject);
    const uniqueArray = Array.from(uniqueSet)?.map(JSON.parse);
    setSelectedPlatform(uniqueArray);
    sendSelectedPlatforms(uniqueArray);
    if (uniqueArray.sort().toString() === platforms.sort().toString() && !checkAll) {
      setCheckAll(true);
    }
  };

  const checkIfExists = (option) => {
    const ids = selectedPlatform?.map((group) => group);
    if (ids?.indexOf(option.id) > -1) return true;
    return false;
  };

  const onlyOptionHandler = (event, option) => {
    event.stopPropagation();
    selectPlatform(event, [option]);
    setCheckAll(false);
  };

  const checkAllChange = (event) => {
    event.preventDefault();

    setCheckAll(!checkAll);
    if (!checkAll) {
      selectPlatform(event, platforms);
    } else {
      selectPlatform(event, []);
    }
  };

  return (
    <Stack spacing={2}>
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableCloseOnSelect
        multiple
        limitTags={0}
        disableClearable
        options={platforms}
        selectAllLabel="Select all"
        onChange={(event, newValue, reason) => {
          if (reason === "selectOption") {
            selectPlatform(event, newValue);
          } else if (reason === "removeOption") {
            setCheckAll(false);
            selectPlatform(event, newValue);
          } else if (reason === "clear") {
            setCheckAll(false);
          }
        }}
        defaultValue={selectedPlatform}
        value={selectedPlatform}
        getOptionLabel={(option) => capitalizeFirstLetter(option)}
        disabled={disabled}
        PopperComponent={(param) => (
          <PopperStyledComponent {...param}>
            <Box {...param} />
            <Divider />
            <Box
              sx={{
                backgroundColor: "white",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                padding: "10px",
              }}
              onMouseDown={(e) => e.preventDefault()}
            >
              <label onClick={checkAllChange} style={{ padding: '15px' }}>
                <input type="checkbox" checked={checkAll} readOnly style={{ verticalAlign: 'middle' }} />
                <span style={{ marginLeft: '7px', display: 'inline-block', verticalAlign: 'middle' }}>
                  Select All
                </span>
              </label>
            </Box>
          </PopperStyledComponent>
        )}
        renderOption={(props, option, { selected }) => (
          <Box
            component="li"
            sx={{ display: 'flex', alignItems: 'center', padding: '5px 0px' }}
            {...props}
          >
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={checkIfExists(option) || selected}
            />
            <Typography sx={textColor[option]}>
              {snapshotHeaderIcons[option]}
              &nbsp;&nbsp;
            </Typography>
            <Typography variant="body2" noWrap>
              {capitalizeFirstLetter(option)}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Box
              component="span"
              display="inline-block"
              py="0.2rem"
              px="1rem"
              fontWeight="500"
              borderRadius="9999px"
              bgcolor="#f5f5f5"
              sx={{ boxShadow: 1, "&:hover": { boxShadow: 2 } }}
              onClick={(e) => onlyOptionHandler(e, option)}
            >
              only
            </Box>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Platform"
            placeholder="Platforms"
            sx={{
              "& .MuiOutlinedInput-root": {
                minWidth: "250px",
                borderRadius: "10px",
              },
            }}
            size="small"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
      />
    </Stack>
  );
};

PlatformSelectionBox.propTypes = {
  sendSelectedPlatforms: PropTypes.func.isRequired,
  parentPlatforms: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
};

export default PlatformSelectionBox;
