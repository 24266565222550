import { Grid, Paper, styled } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "../styles/internal/SignUp.module.css";
import image from "../assets/images/manWithSpeaker.svg";
import SignUpForm from "../components/account/SignUpForm";
import TopBrands from "../components/landing/TopBrands";

function SignUp() {
  const [width, setWidth] = useState(0);

  const updateWidth = () => {
    const width = elementRef.current ? elementRef.current.offsetWidth : 0;
    setWidth(width);
  };

  const elementRef = useRef(null);

  useEffect(() => {
    updateWidth();
    window.scrollTo(0, 0);
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const card = (
    <>
      <div className={styles.title}>Create your account</div>
      <div className={styles.subtitle}>
        <div className={styles.subtitle_text}>We won't charge you a thing until your trial ends. </div>
      </div>{" "}
      <div className={styles.card}>
        <Paper sx={{ width: 700, minHeight: 500, padding: 5, borderRadius: "8px" }}>
          <SignUpForm />
        </Paper>
      </div>
    </>
  );

  return (
    <>
      <div ref={elementRef}>
        {width >= 1300 && (
          <Grid container>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              {card}
            </Grid>
            <Grid item xs={3}>
              <div className={styles.image}>
                <img src={image} alt="img" />
              </div>
            </Grid>
          </Grid>
        )}
        {width < 1300 && card}
      </div>
      <TopBrands />
    </>
  );
}

export default SignUp;
