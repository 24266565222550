import moment from "moment";
import React, { useEffect, useState } from "react";
import { getUserActionLogs } from "../../services/account";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirstLetter } from "../../utility/stringOperations";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Preview } from "@mui/icons-material";
import CustomizedTable from "../../components/common/customizedTable";
import Loader from "../../components/common/loader";
import UserLogsFilter from "./UserLogsFilter";
import "../../styles/internal/DefaultTable.css"
import { getTimeZone } from "../../services/common";

const UserActionLogs = () => {
  const [isLoaded, setIsLoaded] = useState(true);
  const [logs, setLogs] = useState([]);
  const [visibleLogs, setVisibleLogs] = useState([]);
  const [next, setNext] = useState("");
  const [pagesLoaded, setPagesLoaded] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedActions, setSelectedActions] = useState([]);
  const [start, setStart] = useState(
    moment().subtract(6, "month").startOf("month").toDate()
  );
  const [end, setEnd] = useState(moment().toDate());
  const [order, setOrder] = useState("desc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedLog, setSelectedLog] = useState({});

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const timeZone = getTimeZone()
  const tableHeaders = [
    {
      id: 1,
      label: "Time",
      field: "time",
      headeralignment: "center",
      bodyalignment: "center",
      numeric: false,
      percentage: false,
      show: true,
      sortable: false,
    },
    {
      id: 2,
      label: "User Email",
      field: "email",
      headeralignment: "center",
      bodyalignment: "center",
      numeric: false,
      percentage: false,
      show: true,
      sortable: false,
    },
    {
      id: 3,
      label: "Entity",
      field: "entity",
      headeralignment: "center",
      bodyalignment: "center",
      numeric: false,
      percentage: false,
      show: true,
      sortable: false,
    },
    {
      id: 4,
      label: "Action",
      field: "action",
      headeralignment: "center",
      bodyalignment: "center",
      numeric: false,
      percentage: false,
      show: true,
      sortable: false,
    },
    {
      id: 5,
      label: "",
      field: "options",
      bodyalignment: "center",
      numeric: false,
      percentage: false,
      show: true,
      sortable: false,
    },
  ];

  useEffect(() => {
    getLogs(start, end, selectedUsers, selectedActions, rowsPerPage, order, "");
  }, [rowsPerPage, order]);

  useEffect(() => {
    if (logs?.length === 0 && next !== "") {
      getLogs(
        start,
        end,
        selectedUsers,
        selectedActions,
        rowsPerPage,
        order,
        next
      );
    }
  }, [next]);

  const getTagColors = (value) => {
    let hash = 0;
    for (let i = 0; i < value?.length; i++) {
      hash = value.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (let i = 0; i < 3; i++) {
      let v = (hash >> (i * 8)) & 0xff;
      color += ("00" + v.toString(16)).substr(-2);
    }

    const style = {
      backgroundColor: color,
      color: "white",
      borderRadius: "8px",
    };

    return style;
  };

  const getLogs = async (
    start,
    end,
    users,
    actions,
    rowsPerPage,
    order,
    next
  ) => {
    setIsLoaded(false);
    const optionAxios = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const data = {
      startDate: moment(start).format("YYYY-MM-DD"),
      endDate: moment(end).format("YYYY-MM-DD"),
      users: users,
      actions: actions,
      rows: rowsPerPage,
      order: order,
      nextToken: next,
      timezone: timeZone,
      account: user.agencyId,
    };

    const userLogs = await getUserActionLogs(data, user, dispatch);
    if (userLogs.status) {
      if (typeof userLogs.response !== "string") {
        let logs = userLogs.response.rows || [];
        logs = logs?.map((row) => {
          let log = { ...row };
          log.time = log.time.split(".")[0];
          const entity = capitalizeFirstLetter(log.entity.split("_").join(" "));
          log.entity = <Chip label={entity} style={getTagColors(entity)} />;
          log.action = capitalizeFirstLetter(
            log.action.split("-")[1].split("_").join(" ")
          );
          try {
            const payload = JSON.parse(log.payload);
            row.additionalRows = Object.keys(payload)
              ?.filter((key) => key !== "request")
              ?.map((key) => {
                return {
                  key: key,
                  payload: payload[key],
                };
              });
          } catch (error) {
            console.error(error);
          }

          log.options = (
            <IconButton>
              <Tooltip
                title={"Detailed Information"}
                onClick={() => handleView(row)}
              >
                <Preview />
              </Tooltip>
            </IconButton>
          );
          return log;
        });
        setVisibleLogs(logs);
        setLogs((prev) => [...prev, ...logs]);

        setNext(userLogs.response.next);

        if (next === "") {
          setTotal(userLogs.response.total);
        }

        if (
          logs?.length > 0 ||
          (logs?.length === 0 && userLogs.response.next === "")
        ) {
          setIsLoaded(true);
        }
      }
    }
  };

  const handleInformationChange = (event) => {
    if (page !== event.page) {
      setPage(event.page);
      if (pagesLoaded < event.page) {
        setPagesLoaded(event.page);
        getLogs(
          start,
          end,
          selectedUsers,
          selectedActions,
          rowsPerPage,
          order,
          next
        );
      } else {
        setVisibleLogs(
          logs.slice(rowsPerPage * event.page, rowsPerPage * (event.page + 1))
        );
      }
    } else if (order !== event.order) {
      setIsLoaded(false);
      setLogs([]);
      setNext("");
      setPage(0);
      setPagesLoaded(0);
      setOrder(event.order);
    } else if (rowsPerPage !== event.rowsPerPage) {
      setIsLoaded(false);
      setLogs([]);
      setNext("");
      setPage(0);
      setPagesLoaded(0);
      setRowsPerPage(event.rowsPerPage);
    }
  };

  const handleFilter = (start, end, selectedUsers, selectedActions) => {
    setIsLoaded(false);
    setLogs([]);
    setNext("");
    setPage(0);
    setPagesLoaded(0);
    setStart(start);
    setEnd(end);
    setSelectedUsers(selectedUsers);
    setSelectedActions(selectedActions);
    getLogs(start, end, selectedUsers, selectedActions, rowsPerPage, order, "");
  };

  const handleView = (row) => {
    setSelectedLog(row);
    setOpenDialog(true);
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <div id="filter">
        <UserLogsFilter
          start={start}
          end={end}
          selectedActions={selectedActions}
          selectedUsers={selectedUsers}
          handleFilter={handleFilter}
        />
      </div>
      <div id="table">
        {isLoaded ? (
          logs?.length > 0 ? (
            <>
              <CustomizedTable
                tableHeaders={tableHeaders}
                bodyColumns={visibleLogs}
                sendInformation={handleInformationChange}
                totalRows={total}
                pageNo={page}
                rowsPerPageNo={rowsPerPage}
                sortOrder={order}
                sortOrderBy={"time"}
              />
              <Dialog
                open={openDialog}
                onClose={closeDialog}
                PaperProps={{
                  style: {
                    height: "auto",
                  },
                }}
                fullWidth
              >
                <DialogTitle>Detailed Information</DialogTitle>
                <DialogContent>
                  {selectedLog?.entity && (
                    <table>
                      <tr style={{ width: "100%" }}>
                        <td style={{ fontWeight: "bold", padding: "5px" }}>
                          Entity
                        </td>
                        <td style={{ padding: "5px 20px" }}>
                          {capitalizeFirstLetter(selectedLog?.entity)}
                        </td>
                      </tr>
                      <tr style={{ width: "100%" }}>
                        <td style={{ fontWeight: "bold", padding: "5px" }}>
                          Action
                        </td>
                        <td style={{ padding: "5px 20px" }}>
                          {capitalizeFirstLetter(
                            selectedLog?.action
                              .split("-")[1]
                              .split("_")
                              .join(" ")
                          )}
                        </td>
                      </tr>
                      <tr style={{ width: "100%" }}>
                        <td style={{ fontWeight: "bold", padding: "5px" }}>
                          Time
                        </td>
                        <td style={{ padding: "5px 20px" }}>
                          {moment(selectedLog?.time).format(
                            "MMMM DD YYYY - hh:mm:ss [GMT]"
                          )}
                        </td>
                      </tr>
                      <tr style={{ width: "100%" }}>
                        <td style={{ fontWeight: "bold", padding: "5px" }}>
                          User
                        </td>
                        <td style={{ padding: "5px 20px" }}>
                          {selectedLog?.email}
                        </td>
                      </tr>
                      {selectedLog.additionalRows &&
                        selectedLog.additionalRows?.map((row) => (
                          <tr style={{ width: "100%" }}>
                            <td
                              style={{
                                fontWeight: "bold",
                                padding: "5px",
                              }}
                            >
                              {row.key}
                            </td>
                            <td style={{ padding: "5px 20px" }}>
                              {row.payload}
                            </td>
                          </tr>
                        ))}
                      <tr style={{ width: "100%" }}>
                        <td style={{ fontWeight: "bold", padding: "5px" }}>
                          Status
                        </td>
                        <td style={{ padding: "5px 20px" }}>
                          {capitalizeFirstLetter(selectedLog?.status)}
                        </td>
                      </tr>
                    </table>
                  )}
                  <div
                    style={{
                      float: "right",
                      marginTop: "15px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={closeDialog}
                    >
                      Close
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
            </>
          ) : (
            <Box
              sx={{
                p: "20px",
                transform: "translateY(10px)",
                textAlign: "center",
                bgcolor: "#fff",
              }}
            >
              Table is empty
            </Box>
          )
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default UserActionLogs;
