import { BusinessCenterOutlined, BusinessOutlined, EmailOutlined, PersonOutlineOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import styles from "../../styles/internal/Subscribe.module.css";
import { RoundedTextField } from "../common/StyledComponents";
import React from "react";
import MuiPhoneInput from "../../pages/Settings/MuiPhoneInput";
import { useLocation, useSearchParams } from "react-router-dom";
import { createAccount, createUser, getBusinessTypes } from "../../services/signup";
import { Button } from "react-bootstrap";
import { validatePhone } from "../../utility/regex";
import { notify } from "../../redux/features/system/systemAlert";
import { useDispatch } from "react-redux";
import { blockedEmails, getOrganizationNameFromEmail } from "../../utility/stringOperations";

const ContactForm = ({ goToNextStep, planDetails }) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [state, setState] = React.useState({
    dialCode: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    accountName: "",
    businessType: "",
    disabled: false,
    notified: "",
    sent: false,
  });
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const tenure = params.get("tenure");
  const [responseLoading, setResponseLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [businessTypes, setBusinessTypes] = React.useState([]);
  React.useEffect(() => {
    getBusinessTypesForOrganization();

    const email = searchParams.get("email");
    if (email) {
      setState((prevState) => ({ ...prevState, email }));
    }
  }, []);

  const getBusinessTypesForOrganization = async () => {
    const response = await getBusinessTypes();
    setBusinessTypes(response?.response);
  };
  const handlePhoneChange = (value, country) => {
    let phoneNumber = value.replace(/[^0-9]/g, "");
    phoneNumber = phoneNumber.slice(country.dialCode?.length);
    setState((prevState) => ({ ...prevState, phoneNumber, dialCode: country.dialCode }));
  };

  const processEmail = (e) => {
    const email = e.target.value;
    let organisationName = "";
    if (email.includes("ai-fluence")) {
      setState((prevState) => ({
        ...prevState,
        email,
        accountName: "Aifluence",
        businessType: { id: 0, type: "Universal" },
        disabled: true,
      }));
      setError("");
      return;
    } else if (blockedEmails.some((bemail) => email.includes(bemail))) {
      setError("Please enter a work email address");
    } else {
      setError("");
    }
    if (email.includes("@") && email.includes(".")) {
      organisationName = getOrganizationNameFromEmail(email);
    }
    setState((prevState) => ({
      ...prevState,
      email,
      accountName: organisationName,
      businessType: "",
      disabled: false,
    }));
  };

  const signupUser = async () => {
    if (!validatePhone(state.dialCode, state.phoneNumber)) {
      dispatch(
        notify({
          message: "Please enter a valid phone number",
          type: "error",
        })
      );
      return;
    }
    dispatch(
      notify({
        message: "Please wait... We are validating your profile",
        type: "info",
      })
    );
    setResponseLoading(true);
    let id = 0;
    let newAccount = false;
    if (state.accountName !== "Aifluence") {
      const accountPayload = {
        name: state.accountName,
        businessType: state.businessType.id,
        membershipPlan: planDetails.id,
        addedFrom: "empowr",
        membershipTenure: tenure === "monthly" ? 1 : 6
      };
      const accountResponse = await createAccount(accountPayload);
      id = accountResponse.response.id;
      newAccount = accountResponse.response.newAccount;
    }
    const users = [
      {
        name: `${state.firstName} ${state.lastName}`,
        email: state.email,
        role: "Owner",
        roleId: 1,
        phoneNumber: state.phoneNumber,
        dialCode: state.dialCode,
        notified: state.notified,
      },
    ];
    const userPayload = {
      users,
      companyName: state.accountName,
      agencyId: state.accountName === "Aifluence" ? "0" : id.toString(),
      clientId: "0",
      newAccount,
      billingAdded: false,
      addedBy: state.email,
    };
    const userResponse = await createUser(userPayload);
    setResponseLoading(false);
    if (userResponse.response?.statusCode === 200) {
      setState((prevState) => ({ ...prevState, sent: true }));
      goToNextStep({ go: true, email: state.email, firstName: state.firstName, lastName: state.lastName, agencyName: state.accountName, accountId: id });
      dispatch(
        notify({
          message: "User created successfully",
          type: "success",
        })
      );
      return;
    }
    //code of mysql for duplicate entry
    if (userResponse.response?.message?.includes("1062")) {
      dispatch(
        notify({
          message: "User already exists! Please try logging in with the same email",
          type: "error",
        })
      );
      return;
    } else {
      dispatch(
        notify({
          message: userResponse.response?.message,
          type: "error",
        })
      );
      return;
    }
  };

  const card = (
    <>
      <div className={styles.formTitle}>
        <b>Full Name*</b>
      </div>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
            size="small"
            sx={RoundedTextField}
            placeholder="<First Name>"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PersonOutlineOutlined />
                </InputAdornment>
              ),
            }}
            value={state.firstName}
            onChange={(e) => setState((prevState) => ({ ...prevState, firstName: e.target.value }))}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            sx={RoundedTextField}
            placeholder="<Last Name>"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PersonOutlineOutlined />
                </InputAdornment>
              ),
            }}
            value={state.lastName}
            onChange={(e) => setState((prevState) => ({ ...prevState, lastName: e.target.value }))}
            required
          />
        </Grid>
      </Grid>
      <div className={styles.formTitle}>
        <b>Work Email*</b>
      </div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            size="small"
            type="email"
            sx={RoundedTextField}
            placeholder="<Use an email with your company’s domain.>"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <EmailOutlined />
                </InputAdornment>
              ),
            }}
            value={state.email}
            onChange={(e) => processEmail(e)}
            required
            error={!!error} // Set the 'error' prop based on whether an error message exists
            helperText={error} // Display the error message as the helper text
          />
        </Grid>
      </Grid>
      <div className={styles.formTitle}>
        <b>Phone Number*</b>
      </div>
      <Grid container spacing={1}>
        <Grid item>
          <MuiPhoneInput
            required
            inputStyle={{
              borderRadius: "46px",
              border: "1px solid #d9d9dd",
              boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
              height: "45px",
              width: "620px",
            }}
            country="us"
            InputProps={{
              readOnly: false,
              endAdornment: null,
            }}
            specialLabel=""
            value={`+${state.dialCode}${state.phoneNumber}`}
            onChange={handlePhoneChange}
          />
        </Grid>
      </Grid>

      <div className={styles.formTitle}>
        <b>Organization Details*</b>
      </div>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
            size="small"
            disabled={state.disabled}
            sx={RoundedTextField}
            placeholder="<Organization's Name>"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <BusinessOutlined />
                </InputAdornment>
              ),
            }}
            value={state.accountName}
            onChange={(e) => setState((prevState) => ({ ...prevState, accountName: e.target.value }))}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            id="size-small-outlined"
            size="small"
            disabled={state.disabled}
            options={businessTypes}
            getOptionLabel={(option) => option.type || ""}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => <TextField {...params} placeholder="<Organization's Type>" sx={RoundedTextField} />}
            value={state.businessType}
            onChange={(event, newValue) => {
              setState((prevState) => ({ ...prevState, businessType: newValue }));
            }}
          />
        </Grid>
      </Grid>
      <br />
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={state.notified} onChange={(e) => setState((prevState) => ({ ...prevState, notified: e.target.checked }))} />}
          label="I want to be notified about new features and updates to Empowr."
        />
      </FormGroup>
      <br />
      <div
        style={{
          paddingBottom: "20px",
          margin: "auto",
          fontSize: "0.75rem",
        }}
      >
        By clicking the signup button, you agree to our{" "}
        <a href="/page/termsOfService" target="_blank" rel="noreferrer">
          Terms of Service
        </a>{" "}
        and have read and acknowledge our{" "}
        <a href="/page/privacyPolicy" target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
        .
      </div>
      <div style={{ textAlign: "right" }}>
        <Button
          value="active"
          className={styles.staticButton}
          onClick={signupUser}
          disabled={
            !state.firstName ||
            !state.lastName ||
            !state.phoneNumber ||
            !state.email ||
            !state.accountName ||
            !state.businessType ||
            !!error ||
            responseLoading
          }
        >
          {responseLoading ? ( // Display loading icon when loading is true
            <CircularProgress size={24} color="inherit" />
          ) : (
            <b>Sign up</b>
          )}
        </Button>
      </div>
    </>
  );
  return <div>{card}</div>;
};

export default ContactForm;
