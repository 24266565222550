import { Grid } from "@mui/material";
import { getFutureDate } from "../../utility/momentManipulations";
import { useLocation } from "react-router-dom";
import successIcon from "../../assets/images/success_icon.svg";

const SubscriptionSuccessful = ({ billingDetails, amount, planDetails }) => {
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const planName = params.get("planName");
  const tenure = params.get("tenure")
  const price = tenure === "monthly" ? planDetails?.planPrice : Math.round((planDetails?.planPrice * 6) * 0.8);
  const nextMonth = tenure === "monthly"  ? planName !== "Free" ? new Date(getFutureDate(new Date(), 37, "days")) : new Date(getFutureDate(new Date(), 7, "days")) : new Date(getFutureDate(new Date(), 187, "days"));

  const starterAmount = price - amount;

  return (
    <div style={styles.subscriptionSuccessContainer}>
      <Grid container spacing={3} justifyContent="center" alignItems="center" textAlign="center">
        <Grid item xs={12}>
          <img src={successIcon} alt="success icon" style={styles.successIcon} />
        </Grid>
        <Grid item xs={12}>
          <h1 style={styles.successMessage}>Thank you for subscribing!</h1>
        </Grid>
        {/* <Grid item xs={12}>
          <h2 style={styles.successSubtitle}>Your account has been successfully created.</h2>
        </Grid> */}
        <Grid item xs={12}>
          <p style={styles.successDetails}>Your payment has been successfully done.</p>
          <p style={styles.successDetails}>
            Your {planDetails?.planName} Plan is active and valid till {nextMonth.toDateString()}.<br />
            {billingDetails === undefined && planName !== "Free" ? (
              <>
                You will be billed ${starterAmount} on {new Date(getFutureDate(nextMonth, 1, "day")).toDateString()}.<br />
                Make sure you add a billing method before that.
              </>
            ) : planName === "Free" ? (
              <>
                Your plan will expire after that. To contnue using our services, please enroll to a Starter/Business or Professional plan
              </>
            ) : (
              <>
                Your {planName} monthly plan is now active. Your next billing will be ${starterAmount} on {nextMonth.toDateString()}.<br />
                You can downgrade or cancel anytime.
              </>
            )}
          </p>
        </Grid>
      </Grid>
    </div>
  );
};

const styles = {
  subscriptionSuccessContainer: {
    backgroundColor: '#f7f9fa',
    borderRadius: '8px',
    padding: '40px',
    maxWidth: '800px',
    margin: '0 auto',
  },
  successIcon: {
    height: '90px',
  },
  successMessage: {
    fontSize: '24px',
    color: '#4caf50',
  },
  successSubtitle: {
    fontSize: '20px',
    color: '#333',
  },
  successDetails: {
    fontSize: '16px',
    color: '#666',
    margin: '10px 0',
  },
};

export default SubscriptionSuccessful;
