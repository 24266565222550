import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Autocomplete,
  TextField,
  Checkbox,
  Tooltip,
} from "@mui/material";
import BlockLoginAccess from "../../components/common/BlockLoginAccess";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import { Check, Apple, Android, Error, RemoveRedEye, ContentCopy } from "@mui/icons-material";
import config from "../../config/main.json";
import CustomizedTable from "../../components/common/customizedTable";
import styles from "../../styles/internal/Account/AccountUsers.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  checkIntegration,
  updateConfig,
  getAppApiKey,
  handleAuthorizationResponse,
  getZappierToken,
} from "../../services/integrations";
import { useLocation } from "react-router-dom";
import firebaseIcon from "../../assets/images/firebase.png";
import zapierIcon from "../../assets/images/zapier.png";
import { getCampaignNameForBreadcrumbs } from "../../services/campaigns";
import { CheckBox, CheckBoxOutlineBlank, OpenInNew } from "@mui/icons-material";

function Integrations() {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);
  const [connected, setConnected] = useState(false);
  const [gaConnected, setGaConnected] = useState(false);
  const clientId = config.OUATHAPP_CLIENT_ID; // Replace with your actual client ID
  const fbscope = config.FBSCOPE;
  const redirectUrl = config.REDIRECT_URL;
  const [open, setOpen] = useState(false);
  const [bodyColumns, setBodyColumns] = useState([]);
  const [totalRows, setTotalRows] = useState(10);
  const [appOpen, setAppOpen] = useState(false);
  const [totalAppRows, setTotalAppRows] = useState(10);
  const [allowScreen, setAllowScreen] = useState(false);
  const [allowGaScreen, setAllowGaScreen] = useState(false);
  const [appType, setAppType] = useState("");
  const gascope = config.GASCOPE;
  const location = useLocation();
  const dispatch = useDispatch();
  const updateWidth = () => {
    const width = elementRef.current ? elementRef.current.offsetWidth : 0;
    setWidth(width);
  };
  const [selectedAndroidApp, setSelectedAndroidApp] = useState("");
  const [selectedIOSApp, setSelectedIOSApp] = useState("");
  const [androidApps, setAndroidApps] = useState([]);
  const [iosApps, setIosApps] = useState([]);
  const [pid, setPid] = useState();
  const [appBodyColumns, setAppBodyColumns] = useState([]);
  const [gaAppOpen, setGaAppOpen] = useState(false);
  const [totalGaAppRows, setTotalGaAppRows] = useState(10);
  const [expand, setExpand] = useState([]);
  const [sortCol, setSortCol] = useState("name");
  const [sortOrder, setSortOrder] = useState("desc");
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(10);
  const search = location.search;
  const params = new URLSearchParams(search);
  const [campaignList, setCampaignList] = useState([]);
  const [campaign, setCampaign] = useState(0);
  const [aid, setAid] = useState(0);
  const [selectedCampaign, setSelectedCampaign] = useState(null); // Define and initialize selectedCampaign state
  const [gaText, setGaText] = useState("Connect Google Analytics");
  const [fbText, setFbText] = useState("Connect Google Firebase");
  const [zapText, setZapText] = useState("Zappier API Key");
  const [copied, setCopied] = useState(false);
  const [apiKey, setApiKey] = useState(""); // Initially hidden
  const [showApiKey, setShowApiKey] = useState(false);
  const initialState = {
    selectedAndroidApps: [],
    selectedIosApps: [],
  };
  const [state, setState] = React.useState(initialState);

  const endIconStyle = {
    marginLeft: "8px", // Adjust spacing as needed
  };

  const handleCampaignChange = (event, value) => {
    setAid(value.aid);
    setCampaign(value.id);
    setSelectedCampaign(value); // Update selectedCampaign when the user selects a new value
    checkIntegrationStatus(value.aid, value.id);
    localStorage.setItem("googleCid", value.id);
    localStorage.setItem("googleAid", value.aid);
  };

  const getCampaigns = async () => {
    const campaigns = await getCampaignNameForBreadcrumbs(user, dispatch);
    const sortedCampaigns = {};
    const sortedAllCampaigns = {};

    campaigns.response.forEach((campaign) => {
      if (!Object.keys(sortedCampaigns).includes(campaign.status)) {
        sortedCampaigns[campaign.status] = [];
        sortedAllCampaigns[campaign.status] = [];
      }
      sortedAllCampaigns[campaign.status].push({
        id: campaign.id,
        name: campaign.name,
        aid: campaign.companyId,
        endDate: campaign.endDate,
      });
      sortedCampaigns[campaign.status].push({
        id: campaign.id,
        name: campaign.name,
        aid: campaign.companyId,
        endDate: campaign.endDate,
      });
    });
    setCampaignList(sortedCampaigns["Live"] || []);
  };
  const showProjectList = async (projectList, intType) => {
    if (projectList.results?.length > 0) {
      const columns = [];
      projectList.results?.forEach((row, i) => {
        let object = {
          projectId: (
            <Grid container spacing={0.5} style={{ display: "flex", justifyContent: "left" }}>
              <Grid item>
                <Grid item xs={12} className={styles.bodycell}>
                  <span>{row.projectId}</span>
                </Grid>
              </Grid>
            </Grid>
          ),
          name: (
            <Grid container spacing={0.5} style={{ display: "flex", justifyContent: "left" }}>
              <Grid item>
                <Grid item xs={12} className={styles.bodycell}>
                  <span>{row.displayName}</span>
                </Grid>
              </Grid>
            </Grid>
          ),
          action: (
            <div>
              <Button
                style={{
                  borderRadius: "10px",
                  display: "inline-block",
                  backgroundColor: "#26DE81",
                  transform: "translateY(5px)",
                  padding: "5px",
                }}
                onClick={() => handleOpenAppDialog(row.projectNumber, intType)}
              >
                Connect
              </Button>
            </div>
          ),
        };
        columns.push(object);
      });
      setTotalRows(columns?.length);
      setBodyColumns(columns);
      setOpen(true);
    }
  };

  const handleConnect = (event) => {
    if (state.selectedAndroidApps?.length == 0 && state.selectedIosApps?.length == 0) {
      alert("Please choose at least one app for connection.");
    } else {
      if (state.selectedAndroidApps?.length > 0) {
        handleConnectAppDialog(pid, state.selectedAndroidApps, appType, "android");
      }
      if (state.selectedIosApps?.length > 0) {
        handleConnectAppDialog(pid, state.selectedIosApps, appType, "iOS");
      }
    }
  };

  const showAppList = async (response, projectId, intType) => {
    setPid(projectId);
    const androidApps = [];
    const iosApps = [];
    response?.response.apps?.forEach((row, i) => {
      if (row.platform === "Android") {
        androidApps.push(row);
      } else if (row.platform === "iOS") {
        iosApps.push(row);
      }
    });
    setAndroidApps(androidApps);
    setIosApps(iosApps);
    let totalApps = androidApps?.length + iosApps?.length;
    setTotalAppRows(totalApps);
    if (intType == "FB") {
      setAppOpen(true);
    } else {
      setGaAppOpen(true);
    }
  };

  const showGAAppList = async (response, projectId, intType) => {
    //setAppData(combinedApps);
    const columns = [];
    response?.response.apps?.forEach((row, i) => {
      let object = {
        name: (
          <Grid container spacing={0.5} style={{ display: "flex", justifyContent: "left" }}>
            <Grid item>
              <Grid item xs={12} className={styles.bodycell}>
                <span>{row.displayName}</span>
              </Grid>
            </Grid>
          </Grid>
        ),
        packageName: (
          <Grid container spacing={0.5} style={{ display: "flex", justifyContent: "left" }}>
            <Grid item>
              <Grid item xs={12} className={styles.bodycell}>
                <span>
                  {row.platform === "Android" ? <Android /> : row.platform === "iOS" ? <Apple /> : null}{" "}
                  {row.platform === "Android" ? row.packageName : row.platform === "iOS" ? row.bundleId : null}
                </span>
              </Grid>
            </Grid>
          </Grid>
        ),
        action: (
          <div>
            <Button
              style={{
                borderRadius: "10px",
                display: "inline-block",
                backgroundColor: "#26DE81",
                transform: "translateY(5px)",
                padding: "5px",
              }}
              onClick={() => handleConnectAppDialog(projectId, row.appId, intType, row.packageName)}
            >
              Connect
            </Button>
          </div>
        ),
      };
      columns.push(object);
    });
    setTotalGaAppRows(columns?.length);
    setAppBodyColumns(columns);
    setGaAppOpen(true);
  };

  const handleConnectToFirebase = async () => {
    localStorage.setItem("allowScreen", true);
    setAppType("FB");
    if (!connected) {
      const authUrl = `${config.AUTH_URL}?client_id=${clientId}&response_type=code&scope=${fbscope}&redirect_uri=${redirectUrl}&access_type=offline&cid=${campaign}`;
      window.location.href = authUrl;
    }
  };

  const handleConnectToGA = async () => {
    localStorage.setItem("allowGaScreen", true);
    setAppType("GA");
    if (!gaConnected) {
      const authUrl = `${config.AUTH_URL}?client_id=${clientId}&response_type=code&scope=${gascope}&redirect_uri=${redirectUrl}&access_type=offline&cid=${campaign}`;
      window.location.href = authUrl;
    }
  };

  const getEndDate = async (dateStr, addDays) => {
    const parts = dateStr.split("T"); // Split date and time parts
    const dateParts = parts[0].split("-");
    const timeParts = parts[1].split(":");
    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]) - 1; // Months are 0-indexed (0 = January)
    const day = parseInt(dateParts[2]);
    const hour = parseInt(timeParts[0]);
    const minute = parseInt(timeParts[1]);
    const second = parseInt(timeParts[2]);
    const originalDate = new Date(year, month, day, hour, minute, second);
    originalDate.setDate(originalDate.getDate() + addDays); // Add 15 days
    return originalDate.toISOString();
  };

  const toggleApiKeyVisibility = async () => {
    if (selectedCampaign == null) {
      alert("Please choose campaign for api key.");
      return;
    }
    try {
      const data = {
        name: user.name,
        email: user.email,
        cid: selectedCampaign.id,
        endDate: await getEndDate(selectedCampaign.endDate, 15),
        agencyId: selectedCampaign.aid,
      };
      const response = await getZappierToken(data, user, dispatch);
      setApiKey(response?.response.accessToken);
    } catch (error) {
      console.error(error);
    }
    setShowApiKey(!showApiKey);
  };
  const copyTextToClipboard = () => {
    navigator.clipboard
      .writeText(apiKey)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
          setShowApiKey(false);
        }, 5000); // 20 seconds
      })
      .catch((err) => {
        console.error("Error copying text: ", err);
      });
  };

  const tableHeaders = [
    {
      id: 1,
      label: <span style={{ marginLeft: "0px" }}>Project Id</span>,
      field: "projectId",
      headeralignment: "left",
      bodyalignment: "left",
      show: true,
    },
    {
      id: 2,
      label: <span style={{ marginLeft: "0px" }}>Name</span>,
      field: "name",
      headeralignment: "left",
      bodyalignment: "left",
      show: true,
    },
    {
      id: 3,
      label: <span style={{ marginLeft: "0px" }}>Action</span>,
      field: "action",
      headeralignment: "left",
      bodyalignment: "left",
      show: true,
    },
  ];

  const tableAppHeaders = [
    {
      id: 1,
      label: <span style={{ marginLeft: "0px" }}>Name</span>,
      field: "name",
      headeralignment: "left",
      bodyalignment: "left",
      show: true,
    },
    {
      id: 3,
      label: <span style={{ marginLeft: "0px" }}>Package/Bundle</span>,
      field: "packageName",
      headeralignment: "left",
      bodyalignment: "left",
      show: true,
    },
    {
      id: 4,
      label: <span style={{ marginLeft: "0px" }}>Action</span>,
      field: "action",
      headeralignment: "left",
      bodyalignment: "left",
      show: true,
    },
  ];

  const handleCloseDialog = () => {
    localStorage.removeItem("allowScreen");
    setOpen(false);
    window.location = "/app/settings/integrations";
  };

  const handleOpenAppDialog = async (projectId, intType) => {
    setOpen(false);
    setAppType(intType);
    const cid = localStorage.getItem("googleCid");
    const aid = parseInt(localStorage.getItem("googleAid"));

    const payload = {
      accountId: aid,
      config: {
        firebasePid: projectId,
      },
      type: intType,
      cid: cid,
    };
    const response = await updateConfig(payload, user, dispatch);
    if (intType == "GA") {
      showGAAppList(response, projectId, intType);
    } else {
      showAppList(response, projectId, intType);
    }
  };

  const handleCloseAppDialog = (appType) => {
    if (appType == "FB") {
      localStorage.removeItem("allowScreen");
      setAppOpen(true);
    } else {
      localStorage.removeItem("allowGaScreen");
      setGaAppOpen(true);
    }
    window.location = "/app/settings/integrations";
  };

  const handleConnectAppDialog = async (projectId, appDetails, appType, storeType) => {
    var appMeta = [];
    if (appType == "FB") {
      localStorage.removeItem("allowScreen");
      setAppOpen(false);
      appMeta = appDetails;
    } else {
      localStorage.removeItem("allowGaScreen");
      appMeta[0] = { appId: appDetails };
      setGaAppOpen(false);
    }
    var payload = {
      accountId: parseInt(localStorage.getItem("googleAid")),
      projectId: projectId,
      appDetails: appMeta,
      type: appType,
      storeType: storeType,
      cid: localStorage.getItem("googleCid"),
    };
    await getAppApiKey(payload, user, dispatch);
    window.location = "/app/settings/integrations";
  };

  const sendInformation = (event) => {
    if (event) {
      setPage(event.page);
      setRows(event.rowsPerPage);
      setSortOrder(event.order);
      setSortCol(event.orderBy);
    }
  };

  useEffect(() => {
    // Parse query parameters from the URL
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    const grantedScopes = searchParams.get("scope");
    if (code) {
      if (grantedScopes && grantedScopes.includes("firebase")) {
        setAllowScreen(true);
      }
      if (grantedScopes && grantedScopes.includes("analytics")) {
        setAllowGaScreen(true);
      }
    }
  }, [location.search]);

  useEffect(() => {
    updateWidth();
    window.scrollTo(0, 0);
    getCampaigns();
  }, []);

  useEffect(() => {
    if (allowScreen) {
      const fetchData = async () => {
        try {
          const response = await handleAuthorizationResponse(user, dispatch, "FB");
          showProjectList(response?.response, "FB");
        } catch (error) {
          console.error(error);
        }
      };
      fetchData();
    }
    if (allowGaScreen) {
      const fetchGaData = async () => {
        try {
          const response = await handleAuthorizationResponse(user, dispatch, "GA");
          showProjectList(response?.response, "GA");
        } catch (error) {
          console.error(error);
        }
      };
      fetchGaData();
    }
  }, [allowScreen, allowGaScreen]);

  const checkIntegrationStatus = async (aid, cid) => {
    const data = {
      type: ["FB", "GA"],
      accountId: aid,
      cid: parseInt(cid),
    };
    const response = await checkIntegration(data, user, dispatch);
    if (response?.response.message != null) {
      const connections = response?.response.message;
      Object.keys(connections).forEach((key) => {
        if (key === "FB") {
          localStorage.setItem("connected", true);
          setConnected(true);
          setFbText("Google Firebase Connected");
        } else {
          localStorage.setItem("gaConnected", true); // Corrected the key name
          setGaConnected(true);
          setGaText("Google Analytics Connected");
        }
      });
    } else {
      localStorage.setItem("connected", false);
      localStorage.setItem("gaConnected", false);
      localStorage.removeItem("allowScreen");
      localStorage.removeItem("allowGaScreen");
      setAllowScreen(false);
      setAllowGaScreen(false);
      setConnected(false);
      setGaConnected(false);
    }
  };

  return (
    <div ref={elementRef}>
      {width > 768 && (
        <>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 500,
              color: "#757575",
              marginBottom: "12px",
            }}
          >
            Connect Google Accounts
          </Typography>
          <Grid
            container
            spacing={2}
            xs={12}
            style={{
              backgroundColor: "#FAFAFB",
              borderRadius: "10px",
              width: "calc(100% - 10px)",
              margin: "auto",
              paddingBottom: "12px",
            }}
          >
            <Grid container item spacing={3}>
              <Grid item sm={3}>
                <Autocomplete
                  disableClearable
                  options={campaignList}
                  noOptionsText="No live campaigns found" // Add your custom message here
                  getOptionLabel={(campaign) => {
                    return campaign ? campaign.name : ""; // Display the 'name' property as the label
                  }}
                  getOptionSelected={(option, value) => option.id === value.id} // Add getOptionSelected
                  isOptionEqualToValue={(option, value) => {
                    return option.id === (value ? value.id : ""); // Check if value is defined and compare the IDs
                  }}
                  value={selectedCampaign} // Use a specific selectedCampaign value
                  onChange={handleCampaignChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        "& .MuiAutocomplete-inputRoot": {
                          height: "40px", // Adjust the height as needed
                        },
                        fieldset: {
                          legend: {
                            width: "unset",
                          },
                        },
                      }}
                      label="Select Live Campaign" // This adds a label to the TextField
                    />
                  )}
                />
              </Grid>
              <Grid container item spacing={3} xs={2.5}>
                <Grid item>
                  <Button
                    variant="contained"
                    startIcon={<img src={firebaseIcon} alt="Firebase Icon" style={{ width: "24px", height: "24px" }} />}
                    style={{ backgroundColor: connected ? "#757575" : "#F9AB00", color: "white" }}
                    onClick={() => handleConnectToFirebase()}
                    endIcon={connected ? <Check style={endIconStyle} /> : null}
                  >
                    {fbText}
                  </Button>
                </Grid>
              </Grid>
              <Grid container item spacing={3} xs={2.5}>
                <Grid item>
                  <Button
                    variant="contained"
                    startIcon={<BarChartRoundedIcon />}
                    style={{ backgroundColor: gaConnected ? "#757575" : "#F9AB00", color: "white" }}
                    onClick={() => handleConnectToGA()}
                    endIcon={gaConnected ? <Check style={endIconStyle} /> : null}
                  >
                    {gaText}
                  </Button>
                </Grid>
              </Grid>
              <Grid container item spacing={3} xs={2.5}>
                <Grid item>
                  <Button
                    variant="contained"
                    startIcon={<img src={zapierIcon} alt="Zapier Icon" style={{ width: "24px", height: "24px" }} />}
                    style={{ backgroundColor: "#F9AB00", color: "white" }}
                    onClick={() => toggleApiKeyVisibility()}
                    endIcon={<RemoveRedEye style={endIconStyle} />}
                  >
                    {zapText}
                  </Button>
                  <a
                    href="https://zapier.com/apps/empowr-by-aifluence/integrations"
                    target="_blank"
                    rel="noreferrer"
                    style={{ marginLeft: "10px" }}
                  >
                    <OpenInNew fontSize="small" />
                  </a>
                  {showApiKey && (
                    <div>
                      <p style={{ paddingTop: "10px" }}>
                        <Tooltip title="Copy API Key" arrow>
                          <span onClick={copyTextToClipboard} style={{ cursor: "pointer" }}>
                            {apiKey?.length > 20 ? `${apiKey.slice(0, 20)}...` : apiKey} <ContentCopy style={endIconStyle} />
                            {copied && showApiKey && "Copied!"}
                          </span>
                        </Tooltip>
                      </p>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {width <= 768 && <BlockLoginAccess openDialog={true} />}
      <Dialog
        open={open}
        PaperProps={{
          style: {
            height: "auto",
          },
        }}
      >
        <DialogTitle>Connect Firebase Project</DialogTitle>
        <DialogContent>
          <div className={styles.background}>
            {totalRows > 0 ? (
              <CustomizedTable
                tableHeaders={tableHeaders}
                bodyColumns={bodyColumns}
                expand={expand}
                selectedRow={{}}
                totalRows={totalRows}
                pageNo={0}
                rowsPerPageNo={10}
                sortOrder={sortOrder}
                sortOrderBy={sortCol}
                sendInformation={sendInformation}
              />
            ) : (
              <Box
                style={{
                  borderRadius: "10px",
                  width: "calc(100% - 10px)",
                  margin: "auto",
                }}
              >
                <Error style={{ color: "red", margin: "10px" }} />
                <span
                  style={{
                    fontSize: "1rem",
                    fontWeight: 400,
                    color: "#757575",
                    marginLeft: "15px",
                  }}
                >
                  Firebase project not found. Please create it in your Google Firebase account and try again.
                </span>
              </Box>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDialog()}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={gaAppOpen}
        PaperProps={{
          style: {
            height: "auto",
            width: "auto",
            maxWidth: "min-content !important",
          },
        }}
      >
        <DialogTitle>Connect App</DialogTitle>
        <DialogContent>
          <div className={styles.background}>
            {totalGaAppRows > 0 ? (
              <CustomizedTable
                tableHeaders={tableAppHeaders}
                bodyColumns={appBodyColumns}
                expand={expand}
                selectedRow={{}}
                totalRows={totalGaAppRows}
                pageNo={page}
                rowsPerPageNo={rows}
                sortOrder={sortOrder}
                sortOrderBy={sortCol}
                sendInformation={sendInformation}
              />
            ) : (
              <Box
                style={{
                  borderRadius: "10px",
                  width: "calc(100% - 10px)",
                  margin: "auto",
                }}
              >
                <Error style={{ color: "red", margin: "10px" }} />
                <span
                  style={{
                    fontSize: "1rem",
                    fontWeight: 400,
                    color: "#757575",
                    marginLeft: "15px",
                  }}
                >
                  Firebase App not found in project. Please create it in your Google Firebase account and try again.
                </span>
              </Box>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseAppDialog(appType)}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={appOpen}
        PaperProps={{
          style: {
            height: "auto",
            width: "auto",
            maxWidth: "min-content !important",
          },
        }}
      >
        <DialogTitle>Connect App</DialogTitle>
        <DialogContent>
          <div className={styles.background}>
            {totalAppRows > 0 ? (
              <Grid container spacing={0.5} style={{ display: "flex", justifyContent: "left", height: "50%" }}>
                <Grid item>
                  <Grid item xs={12} className={styles.bodycell} style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                    <div style={{ height: "100px" }}>
                      <Grid container spacing={0.5} style={{ display: "flex", justifyContent: "left" }}>
                        <Grid item>
                          <Grid item xs={12} className={styles.bodycell}>
                            <Autocomplete
                              style={{ width: "500px" }}
                              multiple
                              limitTags={1}
                              disableCloseOnSelect
                              id="size-small-filled"
                              options={androidApps}
                              getOptionLabel={(option) => option.displayName || ""}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                                    checkedIcon={<CheckBox fontSize="small" />}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.displayName}
                                </li>
                              )}
                              renderInput={(params) => <TextField {...params} label="Android Apps" />}
                              value={state.selectedAndroidApps}
                              onChange={(event, newValue) => {
                                setState({ ...state, selectedAndroidApps: newValue });
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                    <div>
                      <Grid container spacing={0.5} style={{ display: "flex", justifyContent: "left" }}>
                        <Grid item>
                          <Grid item xs={12} className={styles.bodycell}></Grid>
                          <Autocomplete
                            style={{ width: "500px" }}
                            multiple
                            limitTags={1}
                            disableCloseOnSelect
                            id="size-small-filled"
                            options={iosApps}
                            getOptionLabel={(option) => option.displayName || ""}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                                  checkedIcon={<CheckBox fontSize="small" />}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.displayName}
                              </li>
                            )}
                            renderInput={(params) => <TextField {...params} label="Ios Apps" />}
                            value={state.selectedIosApps}
                            onChange={(event, newValue) => {
                              setState({ ...state, selectedIosApps: newValue });
                            }}
                          />
                        </Grid>
                      </Grid>{" "}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Box
                style={{
                  borderRadius: "10px",
                  width: "calc(100% - 10px)",
                  margin: "auto",
                }}
              >
                <Error style={{ color: "red", margin: "10px" }} />
                <span
                  style={{
                    fontSize: "1rem",
                    fontWeight: 400,
                    color: "#757575",
                    marginLeft: "15px",
                  }}
                >
                  Firebase App not found in project. Please create it in your Google Firebase account and try again.
                </span>
              </Box>
            )}
          </div>
        </DialogContent>
        <DialogActions style={{ paddingRight: "20px", paddingBottom: "20px" }}>
          <Button
            onClick={() => handleCloseAppDialog()}
            style={{
              borderRadius: "10px",
              display: "inline-block",
              backgroundColor: "#26DE81",
              transform: "translateY(5px)",
              padding: "5px",
              marginRight: "10px",
            }}
          >
            Cancel
          </Button>
          <Button
            style={{
              borderRadius: "10px",
              display: "inline-block",
              backgroundColor: "#26DE81",
              transform: "translateY(5px)",
              padding: "5px",
            }}
            onClick={() => handleConnect()}
          >
            Connect
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default Integrations;
