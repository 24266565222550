import { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "../styles/internal/Subscribe.module.css";
import { Card, Grid, Paper, Button } from "@mui/material";
import SubscribeForm from "../components/subscribe/SubscribeForm";
import PaymentForm from "../components/subscribe/PaymentForm";
import { useLocation } from "react-router-dom";
import { getDetailsForPlan } from "../services/billing";
import SubscriptionSuccessful from "../components/subscribe/SubscriptionSuccessful";
import PaymentSlip from "../components/subscribe/PaymentSlip";
import config from "../config/main.json";

function Subscribe() {
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const planName = params.get("planName");
  const tenure = params.get("tenure")
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);
  const [planDetails, setPlanDetails] = useState({});
  const [billingDetails, setBillingDetails] = useState({});
  const [couponCode, setCouponCode] = useState("");
  const [success, setSuccess] = useState(false);
  const [amount, setAmount] = useState(0);
  const [email, setEmail] = useState("");

  const updateWidth = () => {
    const width = elementRef.current ? elementRef.current.offsetWidth : 0;
    setWidth(width);
  };

  useEffect(() => {
    updateWidth();
    window.scrollTo(0, 0);
    if (planName) {
      getPlanDetails();
    }
  }, [planName]);

  const getPlanDetails = async () => {
    const planDetails = await getDetailsForPlan(planName);
    setPlanDetails(planDetails.response);
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const handleStepSkipped = (event) => {
    setSuccess(true);
    setBillingDetails(event.billingDetails);
    setEmail(event.email);
  };

  const getCouponCode = (event) => {
    setCouponCode(event);
  };

  const getDiscountedAmount = (event) => {
    setAmount(event);
  };

  const handleDashboardClick = () => {
    window.open(`${config.BASE_URL}/login`, '_blank');
  };
  

  const handleResendEmailClick = () => {
    alert("Verification email resent!");
  };

  const card = (
    <>
      {success ? (
        <Grid
          container
          style={{ marginLeft: "230px", paddingTop: "40px", paddingBottom: "40px" }}
          spacing={2}
        >
          <Grid item xs={12}>
            <div className={styles.card2}>
              <Card sx={{ width: 800, minHeight: 300, padding: 5, borderRadius: "8px", backgroundColor: "#F7F9FA" }}>
                <SubscriptionSuccessful billingDetails={billingDetails} couponCode={couponCode} amount={amount} planDetails={planDetails} tenure={tenure} />
              </Card>
            </div>
          </Grid>
          <Grid item xs={12}>
            {planName === "Free" && (
              <Grid item xs={12}>
                <p className={styles.verificationMessage}>
                  Please check your Inbox to verify your email and set login password.
                </p>
              </Grid>
            )}
            <div className={styles.card2}>
              <Card sx={{ width: 800, minHeight: 300, padding: 5, borderRadius: "8px", backgroundColor: "#F7F9FA" }}>
                <PaymentSlip billingDetails={billingDetails} email={email} amount={amount} planDetails={planDetails} tenure={tenure} />
              </Card>
            </div>
          </Grid>
          <Grid item xs={12} sx={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }} className={styles.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDashboardClick}
              className={styles.dashboardButton}
            >
              Take me to Dashboard
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleResendEmailClick}
              className={styles.resendEmailButton}
            >
              Resend Email
            </Button>
          </Grid>
        </Grid>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <div className={styles.title}>
              Subscribe to {planName} Plan - 7-day free Trial
            </div>
            <hr style={{ width: "58%", margin: "10px 0" }} />
          </div>
          <Grid container style={{ marginLeft: "185px" }} spacing={20}>
            <Grid item xs={6}>
              <div
                className={styles.card}
                style={{
                  maxWidth: "100%",
                  margin: "0 auto",
                  backgroundColor: "#f5f5f5",
                  paddingBottom: "50px",
                }}
              >
                <Paper
                  sx={{ width: 700, minHeight: 500, padding: 5, borderRadius: "8px" }}
                >
                  <SubscribeForm
                    handleStepSkipped={handleStepSkipped}
                    planDetails={planDetails}
                    couponCode={couponCode}
                  />
                </Paper>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                className={styles.card}
                style={{
                  maxWidth: "100%",
                  margin: "0 auto",
                  backgroundColor: "#f5f5f5",
                  paddingBottom: "50px",
                }}
              >
                <Paper
                  sx={{ width: 700, minHeight: 500, padding: 5, borderRadius: "8px" }}
                >
                  <PaymentForm
                    planDetails={planDetails}
                    getCouponCode={getCouponCode}
                    getDiscountedAmount={getDiscountedAmount}
                  />
                </Paper>
              </div>
            </Grid>
          </Grid>

        </>
      )}
    </>
  );
  return (
    <div ref={elementRef} style={{ backgroundColor: "#f5f5f5", paddingBottom: "50px" }}>
      {width >= 1300 && (
        <Grid container>
          <Grid item xs={8}>
            {card}
          </Grid>
        </Grid>
      )}
      {width < 1300 && card}
    </div>
  );
}

export default Subscribe;
