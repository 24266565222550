import { TaskAltRounded, Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, Grid, IconButton, Paper, TextField, Tooltip, Typography } from "@mui/material";
import styles from "../styles/internal/PasswordReset.module.css";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import image from "../assets/images/manWithSpeaker.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setNewPassword, resetNewPassword, verifyWorkEmail } from "../services/passwordReset";
import { logout } from "../redux/features/user/userSlice";
import { useDispatch } from "react-redux";
import { passwordRegex } from "../utility/regex";
import TopBrands from "../components/landing/TopBrands";

function PasswordReset() {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordSecond, setShowPasswordSecond] = useState(false);
  const [type, setType] = useState("");
  const [verify, setVerify] = useState("");

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [passwordSecond, setPasswordSecond] = useState("");
  const [error, setError] = useState(false);
  const [login, setLogin] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [width, setWidth] = useState(0);
  const [criteria, setCriteria] = useState({
    minLength: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumber: false,
    hasSpecialChar: false,
  });
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const updateWidth = () => {
    const width = elementRef.current ? elementRef.current.offsetWidth : 0;
    setWidth(width);
  };

  const elementRef = useRef(null);

  useEffect(() => {
    dispatch(logout());
    updateWidth();
    setType(searchParams.get("type"));
    setVerify(searchParams.get("verify"));
  }, [searchParams]);

  useLayoutEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const openTooltip = () => {
    if (
      !criteria.minLength ||
      !criteria.hasLowerCase ||
      !criteria.hasUpperCase ||
      !criteria.hasNumber ||
      !criteria.hasSpecialChar
    ) {
      setTooltipOpen(true);
    }
  };

  const closeTooltip = () => {
    setTooltipOpen(false);
  };

  const handlePassword = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setCriteria({
      minLength: newPassword?.length >= 8,
      hasUpperCase: /[A-Z]/.test(newPassword),
      hasLowerCase: /[a-z]/.test(newPassword),
      hasNumber: /\d/.test(newPassword),
      hasSpecialChar: passwordRegex.test(newPassword),
    });

    if (!passwordRegex.test(newPassword) && newPassword >= 8) {
      setPasswordValidation(true);
      setErrorMessage(
        "Invalid password, should contain one small letter, one capital letter, one numeric and one special character and of minimum 8 in length"
      );
    } else {
      if (criteria.minLength && criteria.hasLowerCase && criteria.hasUpperCase && criteria.hasNumber && criteria.hasSpecialChar) {
        closeTooltip();
      }
      setPasswordValidation(false);
      setErrorMessage("");
    }
  };

  const handlePasswordSecond = (event) => {
    if (password === event.target.value) {
      setError(false);
    }
    setPasswordSecond(event.target.value);
  };

  const handleContinue = async () => {
    const token = searchParams.get("token");

    let response;
    if (type === "set") {
      response = await setNewPassword(token, password);
    } else if (type === "reset") {
      response = await resetNewPassword(token, password);
    }

    if (response?.response.statusCode === 200) {
      setLogin(true);
      return;
    }

    setApiError(true);
    setErrorMessage(response?.response.message);
    return;
  };

  const verifyEmail = async () => {
    setError(false);

    const token = searchParams.get("token");

    const response = await verifyWorkEmail(token, email);

    if (response?.response.statusCode === 200) {
      setVerify(false);
      return;
    }

    setApiError(true);
    setErrorMessage(response?.response.message);
    return;
  };

  const handleBackToLogin = () => {
    navigate("/login", { state: { isNewUser: true } });
  };

  const verifyPassword = () => {
    if (!password || !passwordSecond) {
      setError(true);
    }
    setError(false);
    if (password !== passwordSecond) {
      setError(true);
    }
  };

  const card = (
    <div className={styles.card}>
      <Paper sx={{ width: 574, minHeight: 500 }}>
        {verify && (
          <>
            <Typography
              sx={{
                fontSize: "2rem",
                textAlign: "center",
                fontWeight: "500",
                color: "#1BC5BD",
                paddingTop: "30px",
              }}
              color="primary"
            >
              Please verify your email address
            </Typography>
            <div style={{ paddingTop: "100px" }}>
              <div style={{ textAlign: "center" }}>
                <TextField
                  id="filled-search"
                  label="Enter your work email"
                  error={errorMessage !== ""}
                  variant="filled"
                  color="secondary"
                  sx={{ width: "450px", borderBottom: "none", height: "75px" }}
                  style={{ height: "75px" }}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>
            </div>
            <div style={{ textAlign: "center", color: "red", paddingTop: "10px", paddingInline: "4rem" }}>{errorMessage}</div>

            <div style={{ textAlign: "center", marginTop: "3rem" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={verifyEmail}
                sx={{
                  width: "400px",
                  height: "75px",
                  borderRadius: "45px",
                  fontSize: "1.25rem",
                }}
              >
                Continue
              </Button>
            </div>
          </>
        )}
        {!verify && (
          <>
            <Typography
              sx={{
                fontSize: "3rem",
                textAlign: "center",
                fontWeight: "500",
                paddingTop: "25px",
              }}
              color="primary"
            >
              {type.charAt(0).toUpperCase()+ type.slice(1,)} You Password!
            </Typography>
            <Typography
              sx={{
                fontSize: "1.5rem",
                textAlign: "center",
                fontWeight: "500",
                color: "#1BC5BD",
              }}
            >
              Your email is verified.
            </Typography>
            <Typography
              sx={{
                fontSize: "1.5rem",
                textAlign: "center",
                fontWeight: "500",
                paddingTop: "60px",
                paddingBottom: "25px",
                width: "80%",
                margin: "auto",
              }}
              color="secondary"
            >
              Please set a password for your account and press continue to login.
            </Typography>
            <div style={{ textAlign: "center" }}>
              <Tooltip
                title={
                  <ul style={{ padding: "5px", paddingInline: "1rem", listStyle: "none", fontSize: "14px" }}>
                    <li>{criteria.minLength ? "✅" : "❌"} Minimum 8 characters</li>
                    <li>{criteria.hasUpperCase ? "✅" : "❌"} At least one uppercase letter</li>
                    <li>{criteria.hasLowerCase ? "✅" : "❌"} At least one lowercase letter</li>
                    <li>{criteria.hasNumber ? "✅" : "❌"} At least one number</li>
                    <li>{criteria.hasSpecialChar ? "✅" : "❌"} At least one special character</li>
                  </ul>
                }
                open={tooltipOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                placement="left"
                arrow
                PopperProps={{
                  style: {
                    fontSize: "14px",
                    borderRadius: "4px",
                    color: "#fff",
                  },
                }}
              >
                <TextField
                  id="filled-search"
                  label="Password"
                  error={errorMessage !== ""}
                  type={showPassword ? "text" : "password"}
                  variant="filled"
                  color="secondary"
                  sx={{ width: "400px", borderBottom: "none", height: "75px" }}
                  style={{ height: "75px" }}
                  onKeyUp={openTooltip}
                  onChange={handlePassword}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                />
              </Tooltip>
            </div>

            <div style={{ textAlign: "center", paddingBottom: "20px" }}>
              <TextField
                id="filled-search"
                label="Confirm Password"
                type={showPasswordSecond ? "text" : "password"}
                onKeyDown={verifyPassword}
                variant="filled"
                color="secondary"
                sx={{ width: "400px", borderBottom: "none", height: "75px" }}
                style={{ height: "75px" }}
                onChange={handlePasswordSecond}
                error={error}
                helperText={error ? "Password does not match" : ""}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => setShowPasswordSecond(!showPasswordSecond)} edge="end">
                      {showPasswordSecond ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ),
                }}
              />
            </div>
            {passwordValidation && (
              <div style={{ textAlign: "center", color: "red", paddingTop: "10px", paddingInline: "4rem" }}>{errorMessage}</div>
            )}

            <div style={{ textAlign: "center", marginTop: "3rem" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleContinue}
                disabled={
                  !criteria.minLength ||
                  !criteria.hasUpperCase ||
                  !criteria.hasLowerCase ||
                  !criteria.hasNumber ||
                  !criteria.hasSpecialChar ||
                  error ||
                  !passwordSecond
                }
                sx={{
                  width: "400px",
                  height: "75px",
                  borderRadius: "45px",
                  fontSize: "1.25rem",
                }}
              >
                Continue
              </Button>
            </div>
            {apiError && <div style={{ textAlign: "center", color: "red", paddingTop: "10px" }}>{errorMessage}</div>}
            <div
              style={{
                textAlign: "center",
                paddingTop: "75px",
                paddingBottom: "10px",
                width: "80%",
                margin: "auto",
                fontSize: "0.75rem",
              }}
            >
              By clicking the button, you agree to our{" "}
              <a href="/page/termsOfService" target="_blank" rel="noreferrer">
                Terms of Service
              </a>{" "}
              and have read and acknowledge our{" "}
              <a href="/page/privacyPolicy" target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
              .
            </div>
          </>
        )}
      </Paper>
    </div>
  );

  const successCard = (
    <div className={styles.card}>
      <Paper sx={{ width: 574, minHeight: 500 }}>
        <div
          style={{
            textAlign: "center",
            paddingTop: "70px",
            paddingBottom: "40px",
          }}
        >
          <TaskAltRounded style={{ color: "#1BC5BD", transform: "scale(5)" }} />
        </div>
        <Typography
          sx={{
            fontSize: "3rem",
            textAlign: "center",
            fontWeight: "500",
            paddingTop: "25px",
          }}
          color="primary"
        >
          {type === "set" && "Password Created!"}
          {type === "reset" && "Password Changed!"}
        </Typography>
        <Typography
          sx={{
            fontSize: "1.5rem",
            textAlign: "center",
            fontWeight: "500",
            paddingTop: "40px",
            paddingBottom: "25px",
            width: "80%",
            margin: "auto",
          }}
          color="secondary"
        >
          {type === "set" && "Your password has been created successfully."}
          {type === "reset" && "Your password has been changed successfully."}
        </Typography>
        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleBackToLogin}
            sx={{
              width: "400px",
              height: "75px",
              borderRadius: "45px",
              fontSize: "1.25rem",
            }}
          >
            Back to Login
          </Button>
        </div>
        <div
          style={{
            textAlign: "center",
            paddingTop: "75px",
            paddingBottom: "10px",
            width: "80%",
            margin: "auto",
            fontSize: "0.75rem",
          }}
        >
          By clicking the button, you agree to our{" "}
          <a href="/page/termsOfService" target="_blank" rel="noreferrer">
            Terms of Service
          </a>{" "}
          and have read and acknowledge our{" "}
          <a href="/page/privacyPolicy" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
          .
        </div>
      </Paper>
    </div>
  );

  return (
    <>
      <div
        ref={elementRef}
        style={{
          background: "linear-gradient(61deg, rgba(237, 234, 228, 1) 0%, rgba(56, 125, 154, 1) 61%, rgba(27, 39, 74, 1) 100%)",
        }}
      >
        <Grid container>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            {!login && card}
            {login && successCard}
          </Grid>
          <Grid item xs={3}>
            <div className={styles.image}>
              <img src={image} alt="img" />
            </div>
          </Grid>
        </Grid>
      </div>
      <TopBrands />
    </>
  );
}

export default PasswordReset;
