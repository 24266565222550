import { Email, Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Paper, TextField, Typography } from "@mui/material";
import styles from "../styles/internal/Login.module.css";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import image from "../assets/images/manWithSpeaker.svg";
import { getMenuItemsData, loginUser } from "../services/login";
import { useDispatch } from "react-redux";
import { login, logout } from "../redux/features/user/userSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { defineMenu, definePermissions } from "../redux/features/menu/menuSlice";
import { passwordRegex } from "../utility/regex";
import { closeAlert, notify } from "../redux/features/system/systemAlert";
import TopBrands from "../components/landing/TopBrands";

const nonBusinessEmails = ["gmail", "yahoo", "hotmail", "rediffmail"]
function Login(props) {
  const { isSignup } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [width, setWidth] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [incorrect, setIncorrect] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [apiErrorMessage, setApiErrorMessage] = useState("");

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const location = useLocation();

  // check location state is the user is newly created user
  const isNewUser = location?.state?.isNewUser;

  const updateWidth = () => {
    const width = elementRef.current ? elementRef.current.offsetWidth : 0;
    setWidth(width);
  };

  const elementRef = useRef(null);

  useEffect(() => {
    updateWidth();
    window.scrollTo(0, 0);
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
    if (!passwordRegex.test(event.target.value)) {
      setPasswordValidation(true);
      setErrorMessage("Invalid password");
    } else {
      setPasswordValidation(false);
      setErrorMessage("");
    }
  };

  const handleLogin = async () => {
    let emailTrimmed = email.toLowerCase()
    const domainName = emailTrimmed.slice(emailTrimmed.indexOf("@")+1, emailTrimmed.lastIndexOf("."))
    if(nonBusinessEmails.includes(domainName)){
      dispatch(
        notify({
          message: "Please use your business emails only",
          type: "error",
          top: "15%",
        })
      );
      return
    }
    setIncorrect(false);
    dispatch(
      notify({
        message: "Logging you in...",
        type: "info",
        top: "15%",
      })
    );
    const loginResponse = await loginUser(emailTrimmed, password);
    if (loginResponse.status === false || loginResponse.response.statusCode) {
      setApiErrorMessage(loginResponse.response.message);
      setIncorrect(true);
      dispatch(closeAlert());
    } else {
      setApiErrorMessage("");
      setIncorrect(false);
      const { role, email, agencyId, clientId } = loginResponse.response;
      const menuResponse = await getMenuItemsData(
        {
          role,
          email,
          agencyId: agencyId.toString(),
          clientId: clientId.toString(),
        },
        loginResponse.response
      );

      dispatch(login(loginResponse.response));
      if (menuResponse.response === null) {
        dispatch(logout());
      } else {
        dispatch(defineMenu(menuResponse.response.AccountMenu.Menu));
      }
      dispatch(definePermissions(menuResponse.response.AccountPermissions));
      dispatch(closeAlert());
      navigate("/app/dashboard");
    }
  };

  const card = (
    <div className={styles.card}>
      <Paper sx={{ width: { xs: "350px", lg: "574px" }, minHeight: 300, marginInline: { xs: "6px", md: "0" } }}>
        <Typography
          sx={{
            fontSize: { xs: "2rem", lg: "3rem" },
            textAlign: "center",
            fontWeight: "500",
            padding: "25px",
          }}
          color="primary"
        >
          Welcome Back!
        </Typography>
        <div style={{ textAlign: "center", paddingTop: "40px" }}>
          <TextField
            id="filled-search"
            label="Your Work Email"
            placeholder="name@company.com"
            type="email"
            variant="filled"
            color="secondary"
            sx={{ width: { xs: "300px", lg: "400px" }, borderBottom: "none", height: "75px" }}
            style={{ height: "75px" }}
            onChange={handleEmail}
            error={incorrect}
            InputProps={{
              endAdornment: <Email />,
            }}
          />
          <div style={{ textAlign: "center" }}>
            <TextField
              id="filled-search"
              label="Password"
              type={showPassword ? "text" : "password"}
              variant="filled"
              color="secondary"
              sx={{ width: { xs: "300px", lg: "400px" }, borderBottom: "none", height: "75px" }}
              style={{ height: "75px" }}
              onChange={handlePassword}
              error={errorMessage !== ""}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
            />
          </div>
          {!isNewUser && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "right",
                width: { xs: "300px", lg: "400px" },
                margin: "auto",
              }}
            >
              <a href="javascript:void(0);" onClick={() => navigate("/forgotPassword")}>
                Forgot Password
              </a>
            </Box>
          )}
        </div>
        {passwordValidation && (
          <div
            style={{
              textAlign: "center",
              color: "red",
              paddingTop: "10px",
              paddingInline: "4rem",
            }}
          >
            {errorMessage}
          </div>
        )}
        {incorrect && (
          <div
            style={{
              textAlign: "center",
              color: "red",
              paddingTop: "10px",
              paddingInline: "4rem",
            }}
          >
            {apiErrorMessage}
          </div>
        )}

        <div style={{ textAlign: "center", marginTop: "3.5rem" }}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              width: { xs: "300px", lg: "400px" },
              height: "75px",
              borderRadius: "45px",
              fontSize: { xs: "1.2rem", lg: "1.25rem" },
            }}
            disabled={passwordValidation || password?.length < 8}
            onClick={handleLogin}
          >
            {isNewUser ? "Get Started" : "Login"}
          </Button>
        </div>
        <div style={{ textAlign: "center", padding: "15px" }}>
          Don't have an account?{" "}
          <a href="javascript:void(0);" onClick={() => navigate("/pricing")}>
            Sign Up
          </a>
        </div>
        <div
          style={{
            textAlign: "center",
            paddingTop: "65px",
            paddingBottom: "10px",
            width: "80%",
            margin: "auto",
            fontSize: "0.75rem",
          }}
        >
          By clicking the button, you agree to our{" "}
          <a href="/page/termsOfService" target="_blank" rel="noreferrer">
            Terms of Service
          </a>{" "}
          and have read and acknowledge our{" "}
          <a href="/page/privacyPolicy" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
          .
        </div>
      </Paper>
    </div>
  );

  return (
    <>
      <div
        ref={elementRef}
        style={{
          background: "linear-gradient(61deg, rgba(237, 234, 228, 1) 0%, rgba(56, 125, 154, 1) 61%, rgba(27, 39, 74, 1) 100%)",
        }}
      >
        {width >= 1300 && (
          <Grid container>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              {card}
            </Grid>
            <Grid item xs={3}>
              <div className={styles.image}>
                <img src={image} alt="img" />
              </div>
            </Grid>
          </Grid>
        )}
        {width < 1300 && card}
      </div>
      <Box sx={{ "& img": { width: "100%" }}}>
      <TopBrands />
      </Box>
    </>
  );
}

export default Login;
