import ReactEcharts from "echarts-for-react";
import { Card, Grid } from "@mui/material";
import { BarChart, DoughnutChart } from "../../../common/chart";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import SummaryCard from "./SummaryCard";
import PropTypes from "prop-types";
import { InfoToolTip } from "../../../common/toolTip";
import constantMessages from "../../../../config/constantMessages.json";
import React from "react";

const CampaignAnalyticsGraphs = ({
  totalEngagement,
  totalEngagementTime,
  platforms,
  publicationEngagement,
  publicationEngagementTime,
  engagementSummary,
  reachColorArray,
  reachSplit,
  engagementSplit,
  engagementColorArray,
  listEngagement,
  listEngagementTime,
  lists,
}) => {
  React.useEffect(() => {
    console.log(totalEngagement);
  }, [totalEngagement]);
  return (
    <>
      <Card style={{ padding: "20px" }}>
        <Grid container>
          <Grid item xs={12}>
            {/* Total Engagement graph */}
            <h4>
              <b className={styles.title}>Total Engagement</b>
              &nbsp;&nbsp;
              <InfoToolTip text="Total engagements of all influencer's posts in this campaign as per filters applied" />
            </h4>
          </Grid>
        </Grid>
        {totalEngagementTime?.length > 0 && totalEngagement?.length > 0 ? (
          <>
            <ReactEcharts
              option={BarChart(totalEngagementTime, true, totalEngagement, platforms, ["line", "bar", "stack"])}
              style={{ height: "400px", width: "100%", borderRadius: 10 }}
            />
            <div style={{ position: "absolute", right: 100, display: "flex", alignItems: "center", fontSize: "0.675em" }}>
              <b className={styles.title}>*Engagement is cumulative</b>
              &nbsp;&nbsp;
              <InfoToolTip text={constantMessages.ENGAGEMENT_TOOLTIP} style={{ marginLeft: 4, marginBottom: "1rem" }} />
            </div>
            <br />
          </>
        ) : (
          <p style={{ height: "400px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            No Graph found
          </p>
        )}
      </Card>
      <br />
      <Card style={{ padding: "20px" }}>
        <Grid container>
          <Grid item xs={12}>
            {/* Total Engagement by publication Date graph */}
            <h4>
              <b className={styles.title}>Engagement By Publication Date </b>
              &nbsp;&nbsp;
              <InfoToolTip text="Displays the engagement of your publications for each Social Network over time by their Publication dates as per filters applied" />
            </h4>
          </Grid>
        </Grid>
        {publicationEngagementTime?.length > 0 && publicationEngagement?.length > 0 ? (
          <>
            <ReactEcharts
              option={BarChart(publicationEngagementTime, true, publicationEngagement, platforms, ["line", "bar", "stack"])}
              style={{ height: "400px", width: "100%", borderRadius: 10 }}
            />
            <div style={{ position: "absolute", right: 100, display: "flex", alignItems: "center", fontSize: "0.675em" }}>
              <b className={styles.title}>*Engagement is cumulative</b>
              &nbsp;&nbsp;
              <InfoToolTip text={constantMessages.ENGAGEMENT_TOOLTIP} style={{ marginLeft: 4, marginBottom: "1rem" }} />
            </div>
            <br />
          </>
        ) : (
          <p style={{ height: "400px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            No publications found within selected filters
          </p>
        )}
      </Card>
      <br />
      <h4 className={styles.title}>
        <b>Engagement Summary </b>
        &nbsp;&nbsp;
        <InfoToolTip text="Top audience metrics matching the applied filters." />
      </h4>
      <br />
      {/* Engagement summary cards based on platforms */}
      <Grid container spacing={4}>
        {platforms ? (
          platforms?.map((platform) => (
            <Grid item xs={3} key={platform}>
              <SummaryCard platformCount={engagementSummary[platform]} platform={platform} />
            </Grid>
          ))
        ) : (
          <p style={{ height: "400px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            No Graph found
          </p>
        )}
      </Grid>
      <br />
      {/* Engagement and Reach split cards */}
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <Card style={{ padding: "20px", height: "500px" }}>
            <h4>
              <b className={styles.title}>Reach Split</b>
              &nbsp;&nbsp;
              <InfoToolTip text="Displays the reach of your publications by social network (only for Instagram, Youtube, Twitter and TikTok)." />
            </h4>

            {reachColorArray?.length > 0 && reachSplit?.length > 0 ? (
              <ReactEcharts
                option={DoughnutChart("Reach Split", reachColorArray, "vertical", 10, 50, reachSplit, ["40%", "70%"], true)}
                style={{ height: "400px", width: "100%", borderRadius: 10 }}
              />
            ) : (
              <p style={{ height: "400px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                No Graph found
              </p>
            )}
            {(platforms.includes("facebook") || platforms.includes("linkedin")) && (
              <p className={styles.disclaimer}>*This stat is not supported for Facebook & Linkedin</p>
            )}
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card style={{ padding: "20px", height: "500px" }}>
            <h4>
              <b className={styles.title}>Engagement Split</b>
              &nbsp;&nbsp;
              <InfoToolTip text="Displays the engagement of your publications by social network." />
            </h4>

            {engagementColorArray?.length > 0 && engagementSplit?.length > 0 ? (
              <ReactEcharts
                option={DoughnutChart(
                  "Engagement Split",
                  engagementColorArray,
                  "vertical",
                  10,
                  50,
                  engagementSplit,
                  ["40%", "70%"],
                  true
                )}
                style={{ height: "400px", width: "100%", borderRadius: 10 }}
              />
            ) : (
              <p style={{ height: "400px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                No Graph found
              </p>
            )}
          </Card>
        </Grid>
      </Grid>
      <br />
      {/* Listwise split of analytics */}
      <Card style={{ padding: "20px" }}>
        <Grid container>
          <Grid item xs={12}>
            <h4>
              <b className={styles.title}>Groupwise Performance On Engagement </b>
              <span className={styles.subtitleText}> (Max 3)</span>
              &nbsp;&nbsp;
              <InfoToolTip text="Engagement Rate of the Top 3 performing groups as per filters selected on top." />
            </h4>
          </Grid>
        </Grid>

        {listEngagementTime?.length > 0 && listEngagement?.length > 0 ? (
          <>
            <ReactEcharts
              option={BarChart(listEngagementTime, true, listEngagement, lists, ["line", "bar", "stack"])}
              style={{ height: "400px", width: "100%" }}
            />
            <div style={{ position: "absolute", right: 100, display: "flex", alignItems: "center", fontSize: "0.675em" }}>
              <b className={styles.title}>*Engagement is cumulative</b>
              &nbsp;&nbsp;
              <InfoToolTip text={constantMessages.ENGAGEMENT_TOOLTIP} style={{ marginLeft: 4, marginBottom: "1rem" }} />
            </div>
            <br />
          </>
        ) : (
          <p style={{ height: "400px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            No Graph found
          </p>
        )}
      </Card>
    </>
  );
};
CampaignAnalyticsGraphs.propTypes = {
  totalEngagement: PropTypes.array.isRequired,
  totalEngagementTime: PropTypes.array.isRequired,
  platforms: PropTypes.array.isRequired,
  publicationEngagement: PropTypes.array.isRequired,
  publicationEngagementTime: PropTypes.array.isRequired,
  engagementSummary: PropTypes.array.isRequired,
  reachColorArray: PropTypes.array.isRequired,
  reachSplit: PropTypes.array.isRequired,
  engagementSplit: PropTypes.array.isRequired,
  engagementColorArray: PropTypes.array.isRequired,
  listEngagement: PropTypes.array.isRequired,
  listEngagementTime: PropTypes.array.isRequired,
  lists: PropTypes.array.isRequired,
};

export default CampaignAnalyticsGraphs;
