import { Autocomplete, Box, Grid, InputAdornment, Paper, TextField } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "../../styles/internal/RequestDemo/RequestDemoForm.module.css";
import image from "../../assets/images/manWithSpeaker.svg";
import { useLocation } from "react-router-dom";
import {
  RoundedTextField,
  StyledStaticButtonRoundedGroup,
  StyledStaticRoundedButton,
} from "../../components/common/StyledComponents";
import { BusinessOutlined, EmailOutlined, PersonOutlineOutlined } from "@mui/icons-material";
import { getBusinessTypes } from "../../services/signup";
import { getCountries } from "../../services/settings";
import MuiPhoneInput from "../Settings/MuiPhoneInput";
import { makeStyles } from "@mui/styles";
import { requestDemo } from "../../services/requestDemo";
import { blockedEmails, getOrganizationNameFromEmail } from "../../utility/stringOperations";
import submitImage from "../../assets/images/campaignBriefSubmit.svg";
import configs from "../../config/main.json";
const useStyles = makeStyles({
  root: {
    position: "relative",
    top: -7,
  },
  label: {
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "18px",
    color: "#464e5f",
    padding: 10,
  },
  counter: {
    fontSize: "0.75rem",
    color: "gray",
  },
});

function RequestDemoForm() {
  const classes = useStyles();
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const email = params.get("email");
  const [state, setState] = useState({
    fullName: "",
    accountName: "",
    email: email ? email : "",
    businessType: "",
    country: "",
    dialCode: "",
    phoneNumber: "",
    description: "",
    emailError: false,
  });
  const [businessTypes, setBusinessTypes] = useState([]);
  const [countries, setCountries] = useState([]);
  const descriptionCount = state.description?.length;

  const [width, setWidth] = useState(0);

  const [submit, setSubmit] = useState(false);
  const [load, setLoad] = useState(false);
  const [uuid, setUuid] = useState();

  const updateWidth = () => {
    const width = elementRef.current ? elementRef.current.offsetWidth : 0;
    setWidth(width);
  };

  const elementRef = useRef(null);

  useEffect(() => {
    updateWidth();
    const params = new URLSearchParams(search);
    const uuid = params.get("id");
    if (uuid === null) {
      setLoad(true);
    } else {
      setUuid(uuid);
    }
    getBusinessTypesForOrganization();
    getCountriesData();
  }, []);

  const getBusinessTypesForOrganization = async () => {
    const response = await getBusinessTypes();
    setBusinessTypes(response?.response);
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const submitRequest = async () => {
    const { fullName, email, businessType, accountName, country, dialCode, phoneNumber, description } = state;
    const notificationMetadata = {
      userId: fullName,
      sourcePlatform: configs.PLATFORM_NAME,
      notificationTypeId: "4",
      notificationActionId: "2",
      notificationCategoryId: "11",
      generatedBy: email,
      priority: "low",
      consumedBy: "oryx",
      navigateTo: "/bdTools/inboundLeads",
    };
    const body = {
      uuid: uuid ? uuid : "",
      fullName,
      email,
      businessType: businessType.type,
      accountName,
      country: country.name,
      dialCode,
      phoneNumber,
      description,
      notificationMetadata,
    };
    const response = await requestDemo(body);
    setSubmit(true);
  };

  const handlePhoneChange = (value, country) => {
    let phoneNumber = value.replace(/[^0-9]/g, "");
    phoneNumber = phoneNumber.slice(country.dialCode?.length);
    setState({ ...state, phoneNumber, dialCode: country.dialCode });
  };

  const getCountriesData = async () => {
    const result = await getCountries();
    setCountries(result.response);
  };

  const processEmail = (e) => {
    const email = e.target.value;
    let organisation = "";

    if (email.includes("@") && email.includes(".")) {
      organisation = getOrganizationNameFromEmail(email);
    }

    setState({ ...state, email, accountName: organisation, emailError: blockedEmails.some((bemail) => email.includes(bemail)) });
  };

  const content = (
    <Box sx={{ marginInline: { xs: "6px", md: "0" } }}>
      <div className={styles.title}>See Empowr in action!</div>
      <div className={styles.subtitle}>
        <div className={styles.subtitle_text}>
          Ready to run influencer marketing campaigns the right way?
          <br />
          Schedule a demo today!
        </div>
      </div>
      <div className={styles.card}>
        {!submit && (
          <Paper
            sx={{
              width: { xs: "350px", lg: "700px" },
              minHeight: 500,
              padding: 5,
              borderRadius: "8px",
            }}
          >
            {load && (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Box
                      component="p"
                      sx={{
                        mb: "1rem",
                        color: "black",
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      Full Name*
                    </Box>
                    <TextField
                      size="small"
                      sx={RoundedTextField}
                      placeholder="<Full Name>"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <PersonOutlineOutlined />
                          </InputAdornment>
                        ),
                      }}
                      value={state.fullName}
                      onChange={(e) => setState({ ...state, fullName: e.target.value })}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box
                      component="p"
                      sx={{
                        mb: "1rem",
                        color: "black",
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      Work Email*
                    </Box>
                    <TextField
                      size="small"
                      type="email"
                      sx={RoundedTextField}
                      placeholder="<Work Email>"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EmailOutlined />
                          </InputAdornment>
                        ),
                      }}
                      value={state.email}
                      onChange={(e) => processEmail(e)}
                      required
                    />
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Box
                      component="p"
                      sx={{
                        mb: "1rem",
                        color: "black",
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      Organization*
                    </Box>
                    <TextField
                      size="small"
                      sx={RoundedTextField}
                      placeholder="<Organization's Name>"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <BusinessOutlined />
                          </InputAdornment>
                        ),
                      }}
                      value={`${state.accountName}`}
                      onChange={(e) => setState({ ...state, accountName: e.target.value })}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box
                      component="p"
                      sx={{
                        mb: "1rem",
                        color: "black",
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      Which best describes you?*
                    </Box>
                    <Autocomplete
                      id="size-small-outlined"
                      size="small"
                      options={businessTypes}
                      getOptionLabel={(option) => option.type || ""}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="<Organization's Type>" sx={RoundedTextField} />
                      )}
                      value={state.businessType}
                      onChange={(event, newValue) => {
                        setState({ ...state, businessType: newValue });
                      }}
                    />
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Box
                      component="p"
                      sx={{
                        mb: "1rem",
                        color: "black",
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      Country*
                    </Box>
                    <Autocomplete
                      id="size-small-outlined"
                      size="small"
                      options={countries}
                      getOptionLabel={(option) => option.name || ""}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => <TextField {...params} placeholder="<Country>" sx={RoundedTextField} />}
                      value={state.country}
                      onChange={(event, newValue) => {
                        setState({ ...state, country: newValue });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box
                      component="p"
                      sx={{
                        mb: "1rem",
                        color: "black",
                        fontSize: "18px",
                        fontWeight: "bold",
                        width: { xs: "268px !important", lg: "310px" },
                      }}
                    >
                      Phone Number*
                    </Box>
                    <MuiPhoneInput
                      required
                      inputStyle={{
                        borderRadius: "46px",
                        border: "1px solid #d9d9dd",
                        boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                        height: "45px",
                        width: "100%",
                      }}
                      country="us"
                      InputProps={{
                        readOnly: false,
                        endAdornment: null,
                      }}
                      specialLabel=""
                      value={`+${state.dialCode}${state.phoneNumber}`}
                      onChange={handlePhoneChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} className={styles.formTitle}>
                    <b>Anything else we should know?*</b>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      sx={RoundedTextField}
                      multiline
                      rows={4}
                      helperText="Max 256 Chars"
                      value={state.description}
                      onChange={(e) => descriptionCount < 256 && setState({ ...state, description: e.target.value })}
                      InputProps={{
                        endAdornment: <div className={classes.counter}>{descriptionCount}/256</div>,
                      }}
                    />
                  </Grid>
                </Grid>
                {state.emailError && <p style={{ textAlign: "center", color: "red" }}>Please enter your business email</p>}
                <Grid container item spacing={3} xs={12} sx={{ paddingTop: 2, justifyContent: "center" }}>
                  <Grid item md={6}>
                    <StyledStaticButtonRoundedGroup
                      color="primary"
                      size="small"
                      exclusive
                      aria-label="Campaign"
                      disabled={
                        !state.fullName ||
                        !state.email ||
                        !state.accountName ||
                        !state.businessType ||
                        !state.country ||
                        !state.phoneNumber ||
                        state.emailError
                      }
                    >
                      <StyledStaticRoundedButton value="active" className={styles.staticButton} onClick={submitRequest}>
                        <b>Request Demo</b>
                      </StyledStaticRoundedButton>
                    </StyledStaticButtonRoundedGroup>
                  </Grid>
                </Grid>
              </>
            )}
          </Paper>
        )}
        {submit && (
          <Paper sx={{ width: "574px", minHeight: "750px" }}>
            <div className={styles.submit_card}>
              <img className={styles.submit_image} src={submitImage} alt="img" />
              <div className={styles.submit_title}>Thank You!</div>
              <div className={styles.submit_text} style={{ marginBottom: "120px" }}>
                We have received your details, someone from AIfluence will get back to you soon.
              </div>
              <div className={styles.submit_text} style={{ marginBottom: "30px" }}>
                For any other clarification or details feel free to email us at&nbsp;
                <a target="_blank" rel="noreferrer" href="mailto:contact@ai-fluence.com">
                  contact@ai-fluence.com
                </a>
              </div>
            </div>
          </Paper>
        )}
      </div>
    </Box>
  );

  return (
    <div ref={elementRef}>
      {width >= 1300 && (
        <Grid container>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            {content}
          </Grid>
          <Grid item xs={3}>
            <div className={styles.image}>
              <img src={image} alt="img" />
            </div>
          </Grid>
        </Grid>
      )}
      {width < 1300 && content}
    </div>
  );
}

export default RequestDemoForm;
