import { Button, Grid, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "../styles/internal/ForgotPassword.module.css";
import { ArrowBack, Email, TaskAltRounded } from "@mui/icons-material";
import image from "../assets/images/manWithSpeaker.svg";
import { forgotUserPassword } from "../services/forgotPassword";
import { useNavigate } from "react-router";
import TopBrands from "../components/landing/TopBrands";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [width, setWidth] = useState(0);

  const navigate = useNavigate();

  const updateWidth = () => {
    const width = elementRef.current ? elementRef.current.offsetWidth : 0;
    setWidth(width);
  };

  const elementRef = useRef(null);

  useEffect(() => {
    updateWidth();
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleRecovery = async () => {
    const response = await forgotUserPassword(email);

    if (response?.response.statusCode === 400) {
      setErrorMessage(response?.response.message);
      setError(true);
      return;
    } else {
      setSent(true);
      return;
    }
  };

  const card = (
    <div className={styles.card}>
      <Paper sx={{ width: 574, minHeight: 500 }}>
        <Button sx={{ margin: "10px 0px 0px 10px", fontSize: "20px" }} onClick={() => navigate("/login")}>
          <ArrowBack fontSize="small" />
          &nbsp;&nbsp;Login
        </Button>
        <Typography
          sx={{
            fontSize: "3rem",
            textAlign: "center",
            fontWeight: "500",
            padding: "25px",
          }}
          color="primary"
        >
          Having trouble signing in?
        </Typography>
        <div
          style={{
            textAlign: "center",
            paddingTop: "40px",
            paddingBottom: "25px",
          }}
        >
          <TextField
            id="filled-search"
            label="Your Work Email"
            placeholder="name@company.com"
            type="email"
            variant="filled"
            color="secondary"
            sx={{ width: "400px", borderBottom: "none", height: "75px" }}
            style={{ height: "75px" }}
            onChange={handleEmail}
            error={error}
            helperText={error ? errorMessage : ""}
            InputProps={{
              endAdornment: <Email />,
            }}
          />
        </div>
        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRecovery}
            sx={{
              width: "400px",
              height: "75px",
              borderRadius: "45px",
              fontSize: "1.25rem",
            }}
          >
            Start Recovery
          </Button>
        </div>
        <div
          style={{
            textAlign: "center",
            paddingTop: "40px",
            paddingBottom: "10px",
            width: "80%",
            margin: "auto",
            fontSize: "0.75rem",
          }}
        >
          By clicking the button, you agree to our{" "}
          <a href="/page/termsOfService" target="_blank" rel="noreferrer">
            Terms of Service
          </a>{" "}
          and have read and acknowledge our{" "}
          <a href="/page/privacyPolicy" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
          .
        </div>
      </Paper>
    </div>
  );

  const successCard = (
    <div className={styles.card}>
      <Paper sx={{ width: 574, minHeight: 500 }}>
        <Button sx={{ margin: "10px 0px 0px 10px", fontSize: "20px" }} onClick={() => navigate("/login")}>
          <ArrowBack fontSize="small" />
          &nbsp;&nbsp;Login
        </Button>
        <div
          style={{
            textAlign: "center",
            paddingTop: "70px",
            paddingBottom: "40px",
          }}
        >
          <TaskAltRounded style={{ color: "#1BC5BD", transform: "scale(5)" }} />
        </div>
        <Typography
          sx={{
            fontSize: "3rem",
            textAlign: "center",
            fontWeight: "500",
            paddingTop: "25px",
          }}
          color="primary"
        >
          Email Sent!
        </Typography>
        <Typography
          sx={{
            fontSize: "1.5rem",
            textAlign: "center",
            fontWeight: "500",
            paddingTop: "40px",
            paddingBottom: "25px",
            width: "80%",
            margin: "auto",
          }}
          color="secondary"
        >
          We have sent you an email, click on the link available there to reset the password.
        </Typography>
        <div
          style={{
            textAlign: "center",
            paddingTop: "75px",
            paddingBottom: "10px",
            width: "80%",
            margin: "auto",
            fontSize: "0.75rem",
          }}
        >
          By clicking the button, you agree to our{" "}
          <a href="/page/termsOfService" target="_blank" rel="noreferrer">
            Terms of Service
          </a>{" "}
          and have read and acknowledge our{" "}
          <a href="/page/privacyPolicy" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
          .
        </div>
      </Paper>
    </div>
  );

  return (
    <>
      <div ref={elementRef}>
        <Grid container>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            {!sent && card}
            {sent && successCard}
          </Grid>
          <Grid item xs={3}>
            <div className={styles.image}>
              <img src={image} alt="img" />
            </div>
          </Grid>
        </Grid>
      </div>
      <TopBrands />
    </>
  );
}

export default ForgotPassword;
