import { Delete, EmailOutlined, MoreHorizOutlined, Preview } from "@mui/icons-material";
import { Box, Divider, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import React, { useState } from "react";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import PropTypes from "prop-types";

import PreviewTemplate from "./contractActions/PreviewTemplate";
import DeleteTemplate from "./contractActions/DeleteTemplate";
import EditTemplate from "./contractActions/EditTemplate";

const CampaignContractsTemplateActionButton = ({ templateDetails, campaignId, campaignName, reload, tabId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [preview, setPreview] = useState(false);
  const [deleteTemplate, setDeleteTemplate] = useState(false);
  const [editTemplate, setEditTemplate] = useState(false);

  const [status, setStatus] = React.useState("");

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(anchorEl === null ? event.currentTarget : null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  React.useEffect(() => {
    if (templateDetails.isUse) {
      setStatus("used");
    } else {
      setStatus("not used");
    }
  }, [templateDetails]);

  const arrayOfOptions = [
    {
      id: 1,
      icon: <Preview htmlColor="#B5B5C3" />,
      label: "Preview Template",
      name: "preview",
      status: ["used", "not used"],
    },
    {
      id: 2,
      icon: <EmailOutlined htmlColor="#B5B5C3" />,
      label: "Edit Template",
      name: "edit",
      status: ["used", "not used"],
    },
    {
      id: 3,
      icon: <Delete htmlColor="#B5B5C3" />,
      label: "Delete Template",
      name: "delete",
      status: ["not used"],
    },
  ];

  const invokeAction = (name) => {
    switch (name) {
      case "preview": {
        setPreview(true);
        break;
      }
      case "edit": {
        setEditTemplate(true);
        break;
      }
      case "delete": {
        setDeleteTemplate(true);
        break;
      }
    }
    handleClose();
  };

  const closePreviewTemplateDialog = () => {
    setPreview(false);
  };
  const closeDeleteTemplateDialog = () => {
    setDeleteTemplate(false);
  };
  const closeEditTemplateDialog = () => {
    setEditTemplate(false);
  };

  return (
    <>
      {deleteTemplate && (
        <DeleteTemplate
          templateDetails={templateDetails}
          reload={reload}
          deleteTemplate={deleteTemplate}
          closeDeleteTemplateDialog={closeDeleteTemplateDialog}
        />
      )}
      {preview && (
        <PreviewTemplate
          templateDetails={templateDetails}
          campaignId={campaignId}
          campaignName={campaignName}
          preview={preview}
          closePreviewTemplateDialog={closePreviewTemplateDialog}
        />
      )}
      {editTemplate && (
        <EditTemplate
          open={editTemplate}
          onClose={closeEditTemplateDialog}
          templateDetails={templateDetails}
          campaignId={campaignId}
          tabId={tabId}
          reload={reload}
        />
      )}
      <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
        <MoreHorizOutlined />
      </IconButton>
      <Box style={{ borderRadius: "30px" }}>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose} className={styles.menuitem}>
          {arrayOfOptions?.map((value) => (
            <div key={value.id}>
              {value.status.includes(status) && (
                <>
                  <MenuItem key={value.id} onClick={() => invokeAction(value.name)}>
                    {value.icon}&nbsp;&nbsp;&nbsp;&nbsp;{value.label}
                  </MenuItem>
                  {value.id < arrayOfOptions?.length && <Divider key={value.id} sx={{ my: 0.5 }} />}
                </>
              )}
            </div>
          ))}
        </Menu>
      </Box>
    </>
  );
};

CampaignContractsTemplateActionButton.propTypes = {
  templateDetails: PropTypes.object.isRequired,
};

export default CampaignContractsTemplateActionButton;
